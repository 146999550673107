import layout from '../layout'
export default function makeMbRoutes({
  contentType,
  components: { MBListingContainer }
}) {
  console.log(MBListingContainer,contentType.name);
  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list`,
          component: MBListingContainer,
          props: {
            contentType
          }
        },
      ]
    }
  ];
}
