import layout from '../layout'
export default function makeContentRoutes({
  contentType,
  components: { ContentListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list/:ModuleId`,
          component: ContentListingContainer,
          props: {
            contentType
          }
        }
      ]
    }
  ];
}
