<template>
  <section class="app-sidebar fixed-left ">
    <nav class="sidebar sidebar-offcanvas mt-3" id="sidebar">
      <div class="position-fixed" style="z-index:100000000000;">
        <ul class="nav">
          <li class="nav-item nav-profile">
            <a href="javascript:void(0);" class="nav-link" v-on:click="profilePage(
              user_name || user_name2,
              user_image || user_image2
            )">
              <div class="nav-profile-image">
                <img :src="user_image || user_image2" alt="profile">
                <span class="login-status online"></span>
              </div>
              <div class="nav-profile-text d-flex flex-column">
                <span class="font-weight-bold mb-2">{{ user_name || user_name2 }}</span>
                <span class="text-secondary text-small">Admin</span>
              </div>
              <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <div class="sidebarScrollArea" style="overflow-x: auto; 
            -webkit-overflow-scrolling: touch;
            position: absolute;
            height: calc(100vh - 11rem) !important;
      
            top: 79px;
            left: 0;
            right: 0;
            bottom: 0;
            ">
            <li class="nav-item" v-on:click="collapseAll">
              <router-link class="nav-link" to="/">
                <span class="menu-title">Dashboard</span>
                <i class="mdi mdi-home menu-icon"></i>
              </router-link>
            </li>

            <!-- automate work -->
            <!-- <li class="nav-item" v-on:click="collapseAll">
              <router-link class="nav-link" to="/custom">
                <span class="menu-title">Custom Page</span>
                <i class="mdi mdi-home menu-icon"></i>
              </router-link>
            </li>

            <li class="nav-item" v-on:click="collapseAll">
              <router-link class="nav-link" to="/customGoals">
                <span class="menu-title">Custom Goals Page</span>
                <i class="mdi mdi-home menu-icon"></i>
              </router-link>
            </li>

            <li class="nav-item" v-on:click="collapseAll">
              <router-link class="nav-link" to="/customEbooks">
                <span class="menu-title">Custom Ebooks Page</span>
                <i class="mdi mdi-home menu-icon"></i>
              </router-link>
            </li> -->
            <!-- automate work -->

            <!-- :class="li_class" -->
            <li class="nav-item" v-for="contentType in config_notification" :key="contentType.name">
              <router-link class="nav-link" :to="`/${contentType.name}/list`">
                <span class="menu-title">{{ contentType.label }}</span>
                <i class="mdi mdi-send menu-icon"></i>
              </router-link>
            </li>


            <li class="nav-item">
              <span class="nav-link" v-b-toggle="'user'">
                <span class="menu-title">Users</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-account-outline menu-icon"></i>
              </span>
              <b-collapse accordion="sidebar-accordion" id="user">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item" v-for="contentType in config_user" :key="contentType.name">
                    <router-link class="nav-link" :to="`/users/list`">All Users</router-link>
                  </li>

                  <li class="nav-item" v-for="contentType in config_user_paid" :key="contentType.name">
                    <router-link class="nav-link" :to="`/subscribed-users/list`">All Subscribed Users</router-link>
                  </li>

                  <li class="nav-item" v-for="contentType in config_user_free" :key="contentType.name">
                    <router-link class="nav-link" :to="`/free-users/list`">Free Users</router-link>
                  </li>

                  <li class="nav-item">
                    <router-link class="nav-link" :to="`/send-promocodes`">Send Promocodes</router-link>
                  </li>
                </ul>
              </b-collapse>
            </li>





            <li class="nav-item">
              <span class="nav-link" v-b-toggle="'content'">
                <span class="menu-title">Content</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-content-copy menu-icon"></i>
              </span>
              <b-collapse accordion="sidebar-accordion" id="content">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item" v-for="contentType in contentTypes" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{
                      contentType.label
                    }}</router-link>
                  </li>

                  <!-- <li class="nav-item">
                <router-link class="nav-link" :to="`/all-course-modules`">
                  <span class="menu-title">Courses</span>
                </router-link>
              </li> -->

                  <li class="nav-item" v-for="contentType in config_course_categories" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">Course
                    </router-link>
                  </li>

                  <li class="nav-item" v-for="contentType in config_series_categories" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      <!-- <span class="menu-title">{{ contentType.label }}</span> -->
                      <!-- <i class="mdi mdi-home menu-icon"></i> -->
                    </router-link>
                  </li>


                  <!-- <li class="nav-item">
                <span class="nav-link" v-b-toggle="'course2'">
                  <span class="menu-title">Courses</span>
                  <i class="menu-arrow"></i>
                </span>
                <b-collapse accordion="sidebar-accordion2" id="course2">
                  <ul class="nav flex-column sub-menu mx-4">
                    <li class="nav-item" v-for="contentType in config_course_categories" :key="contentType.name">
                      <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      </router-link>
                    </li>
                    <li class="nav-item" v-for="contentType in config_course" :key="contentType.name">
                      <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      </router-link>
                    </li>
                    <li class="nav-item" v-for="contentType in config_module" :key="contentType.name">
                      <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      </router-link>
                    </li>
                    <li class="nav-item" v-for="contentType in config_content" :key="contentType.name">
                      <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      </router-link>
                    </li>
                  </ul>
                </b-collapse>
              </li> -->

                  <!-- v-on:click="collapseAll" -->
                  <li class="nav-item" v-for="contentType in config_script_categories" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      <!-- <span class="menu-title">{{ contentType.label }}</span> -->
                      <!-- <i class="mdi mdi-home menu-icon"></i> -->
                    </router-link>
                  </li>

                  

                  <li class="nav-item" v-for="contentType in config_video_categories" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      <!-- <span class="menu-title">{{ contentType.label }}</span> -->
                      <!-- <i class="mdi mdi-home menu-icon"></i> -->
                    </router-link>
                  </li>


                  <li class="nav-item" v-for="contentType in config_ebook" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}
                      <!-- <span class="menu-title">{{ contentType.label }}</span> -->
                      <!-- <i class="mdi mdi-home menu-icon"></i> -->
                    </router-link>
                  </li>

                </ul>
              </b-collapse>
            </li>


            <li class="nav-item">
              <span class="nav-link" v-b-toggle="'timer'">
                <span class="menu-title">Meditation Timer</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-timer menu-icon"></i>
              </span>
              <b-collapse accordion="sidebar-accordion" id="timer">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item" v-for="contentType in config_mb" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{
                      contentType.label
                    }}</router-link>
                  </li>
                  <li class="nav-item" v-for="contentType in config_mt" :key="contentType.name">
                    <router-link class="nav-link" :to="`/${contentType.name}/list`">{{
                      contentType.label
                    }}</router-link>
                  </li>
                </ul>
              </b-collapse>
            </li>

            <!-- <li class="nav-item" v-for="contentType in config_tag" :key="contentType.name">
          <router-link class="nav-link" :to="`/${contentType.name}/list`">
            <span class="menu-title">{{ contentType.label }}</span>
          </router-link>
        </li> -->

            <li class="nav-item" v-for="contentType in config_event" :key="contentType.name">
              <router-link class="nav-link" :to="`/${contentType.name}/list`">
                <span class="menu-title">{{ contentType.label }}</span>
                <i class="mdi mdi-calendar-blank-outline menu-icon"></i>
              </router-link>
            </li>


            <li class="nav-item" v-for="contentType in config_goal" :key="contentType.name">
              <router-link class="nav-link" :to="`/${contentType.name}/list`">
                <span class="menu-title">{{ contentType.label }}</span>
                <i class="mdi mdi-target menu-icon"></i>
              </router-link>
            </li>


            <li class="nav-item" v-for="contentType in config_feeling" :key="contentType.name">
              <router-link class="nav-link" :to="`/${contentType.name}/list`">
                <span class="menu-title">{{ contentType.label }}</span>
                <i class="fa fa-smile-o menu-icon"></i>
              </router-link>
            </li>


            <li class="nav-item" v-for="contentType in config_promo" :key="contentType.name">
              <router-link class="nav-link" :to="`/${contentType.name}/list`">
                <span class="menu-title">{{ contentType.label }}</span>
                <i class="mdi mdi-tag-outline menu-icon"></i>
              </router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" to="/all-search-terms">
                <span class="menu-title">Search Terms</span>
                <i class="mdi mdi-magnify menu-icon"></i>
              </router-link>
            </li>


            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/all-orders">
                <span class="menu-title">Orders</span>
                <i class="mdi mdi-format-list-bulleted menu-icon"></i>
              </router-link>
            </li> -->


            <!-- <li class="nav-item">
          <span class="nav-link" v-b-toggle="'courses'">
            <span class="menu-title">Courses</span>
            <i class="menu-arrow"></i> -->
            <!-- <i class="mdi mdi-database menu-icon"></i> -->
            <!-- </span>
          <b-collapse accordion="sidebar-accordion" id="courses">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item" v-for="contentType in config_course_categories" :key="contentType.name">
                <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}</router-link>
              </li>
              <li class="nav-item" v-for="contentType in config_course" :key="contentType.name">
                <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}</router-link>
              </li>
              <li class="nav-item" v-for="contentType in config_module" :key="contentType.name">
                <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}</router-link>
              </li>
              <li class="nav-item" v-for="contentType in config_content" :key="contentType.name">
                <router-link class="nav-link" :to="`/${contentType.name}/list`">{{ contentType.label }}</router-link>
              </li>
              
            </ul>
          </b-collapse>
        </li> -->









            <!-- <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/widgets/">
            <span class="menu-title">Widgets</span>
            <i class="mdi mdi-forum menu-icon"></i>
          </router-link>fixed
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'apps'">
            <span class="menu-title">Apps</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart-arrow-down menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="apps">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/calendar/">Calendar</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/email/">E-mail</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/todo/">To-do</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/gallery/">Gallery</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/tickets/">Tickets</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/kanban/">Kanban Board</router-link>
              </li>
               <li class="nav-item">
                <router-link class="nav-link" to="/apps/chat/">Chat</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-basic'">
            <span class="menu-title">Basic UI Elements</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-crosshairs-gps menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-basic">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/accordions/">Accordion</router-link>
              </li>
              <li class="nav-item">fixed
                <router-link class="nav-link" to="/basic-ui/alerts/">Alerts</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/badges/">Badges</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/breadcrumbs/">Breadcrumbs</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/buttons/">Buttons</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/dropdowns/">Dropdowns</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/modals/">Modals</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/paginations/">Paginations</router-link>
              </li>config_course
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/progress/">Progress</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/tabs/">Tabs</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/tooltips/">Tooltips & Popovers</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/typography/">Typography</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-advanced'">
            <span class="menu-title">Advanced UI</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cards-variant menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-advanced">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/dragula/">Dragula</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/carousel/">Carousel</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/loaders/">Loaders</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/slider/">Slider</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/clipboard/">Clipboard</router-link>
              <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/widgets/">
            <span class="menu-title">Widgets</span>
            <i class="mdi mdi-forum menu-icon"></i>
          </router-link>fixed
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'apps'">
            <span class="menu-title">Apps</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart-arrow-down menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="apps">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/calendar/">Calendar</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/email/">E-mail</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/todo/">To-do</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/gallery/">Gallery</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/tickets/">Tickets</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/apps/kanban/">Kanban Board</router-link>
              </li>
               <li class="nav-item">
                <router-link class="nav-link" to="/apps/chat/">Chat</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-basic'">
            <span class="menu-title">Basic UI Elements</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-crosshairs-gps menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-basic">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/accordions/">Accordion</router-link>
              </li>
              <li class="nav-item">fixed
                <router-link class="nav-link" to="/basic-ui/alerts/">Alerts</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/badges/">Badges</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/breadcrumbs/">Breadcrumbs</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/buttons/">Buttons</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/dropdowns/">Dropdowns</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/modals/">Modals</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/paginations/">Paginations</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/progress/">Progress</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/tabs/">Tabs</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/tooltips/">Tooltips & Popovers</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/basic-ui/typography/">Typography</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-advanced'">
            <span class="menu-title">Advanced UI</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cards-variant menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-advanced">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/dragula/">Dragula</router-link>
              </li>
              <li class="nav-item">
                <i>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/tree-view/">Tree View</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/advanced-ui/context-menu/">Context Menu</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'charts-dropdown'">
            <span class="menu-title">Charts</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-chart-bar menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="charts-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/charts/chartjs/">Chart js</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/charts/c3/">C3 charts</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/charts/chartist/">Chartist</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/charts/googleChart/">Google Chart</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/charts/justgage/">Justgage</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>user_type
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'maps-dropdown'">
            <span class="menu-title">Maps</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-chart-bar menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="maps-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/maps/google-map/">Google Maps</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'table-dropdown'">
            <span class="menu-title">Tables</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-table-large menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="table-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/tables/basic-tables/">Basic Table</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/tables/advanced-tables/">Advanced Table</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'user-page-dropdown'">
            <span class="menu-title">User Pages</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-lock menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="user-page-dropdown">
            <ul class="nav flex-user_typecolumn sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/auth-pages/login/">Login</router-link>
              </li>
              <li classcontentType="nav-item">
                <router-link class="nav-link" to="/auth-pages/login-2/">Login - 2</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/auth-pages/multi-level-login/">Multi Level Login</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/auth-pages/register/">Register</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/auth-pages/register-2/">Register - 2</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/auth-pages/lock-screen/">Lockscreen</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>user_type
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'error-page-dropdown'">
            <span class="menu-title">Error pages</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-security menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="error-page-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/error-pages/error-404/">404</router-link>
              </li>
              <li classcontentType="nav-item">
                <router-link class="nav-link" to="/error-pages/error-500/">500</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'general-page-dropdown'">
            <span class="menu-title">General Pages</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-medical-bag menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="general-page-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/blank-page/">Blank Page</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/profile/">Profile</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/faq-1/">FAQ</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/faq-2/">FAQ 2</router-link>
              </li>
              <li classcontentType="nav-item">
                <router-link class="nav-link" to="/general-pages/news-grid/">News Grid</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/timeline/">Timeline</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/search-result/">Search Result</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/portfolio/">Portfolio</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/user-listing/">User Listing</router-link>
              </li>
            </ul>
          </b-collapse>contentType
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/popups/">
            <span class="menu-title">Popups</span>
            <i class="mdi mdi-forum menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/notifications/">
            <span class="menu-title">Notifications</span>
            <i class="mdi mdi-bell-ring menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'form-elements'">
            <span class="menu-title">Forms</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-format-list-bulleted menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="form-elements">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/forms/basic-elements/">Basic Elements</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/forms/advanced-elements/">Advanced Elements</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/forms/wizard/">Wizard</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/forms/validation/">Validation</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/text-editors/">
            <span class="menu-title">Text Editors</span>
            <i class="mdi mdi-file-document menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/code-editors/">
            <span class="menu-title">Code Editors</span>
            <i class="mdi mdi-code-not-equal-variant menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <span class="contentTypenav-link" v-b-toggle="'icons-dropdown'">
            <span class="menu-title">Icons</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-contacts menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="icons-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/icons/flag-icons/">Flag icons</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/icons/fontawesome/">Font Awesome</router-link>
              </li>
               <li class="nav-item">
                <router-link class="nav-link" to="/icons/mdi-icons/">MDI</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/icons/simpleline/">Simple line icons</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/icons/themify-icons/">Themify icons</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item">
          <span class="contentTypenav-link" v-b-toggle="'e-commerce-page-dropdown'">
            <span class="menu-title">E-commerce</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-shopping menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="e-commerce-page-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/e-commerce/invoice/">Invoice</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/e-commerce/orders/">Orders</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/e-commerce/pricing-table/">Pricing Table</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/e-commerce/product-catalogue/">Product Catalogue</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/e-commerce/project-list/">Project list</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item menu-items" v-on:click="collapseAll">
          <router-link class="nav-link" to="/general-pages/landing-page/">
            <span class="menu-icon">
              <i class="mdi mdi-table-large"></i>
            </span>
            <span class="menu-title">Landing Page</span>
          </router-link>
        </li> 
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="http://www.bootstrapdash.com/demo/purple/vue/documentation/documentation.html">
            <span class="menu-title">Documentation</span>
            <i class="menu-icon mdi mdi-file-document-outline"></i>
          </a>
        </li>
        <li class="nav-item sidebar-actions">
          <span class="nav-link">
            <div class="border-bottom">
              <h6 class="font-weight-normal mb-3">Projects</h6>
            </div>contentType
            <button class="btn btn-block btn-lg btn-gradient-primary mt-4">+ Add a project</button>
            <div class="mt-4">
              <div class="border-bottom">
                <p class="text-secondary">Categories</p>
              </div>
              <ul class="gradient-bullet-list mt-4">
                <li>Free</li>
                <li>Pro</li>
              </ul>
            </div>
          </span>
        </li> -->
          </div>
        </ul>

      </div>
    </nav>
  </section>
</template>

<script>
import config from "@/data/audio-categories";
import config_mb from "@/data/meditation-background";
import config_mt from "@/data/meditation-tune";
import config_script_categories from "@/data/script-categories";
import config_series_categories from "@/data/series-categories";
import config_video_categories from "@/data/video-categories";
import config_course_categories from "@/data/course-categories";
import config_event from "@/data/event";
import config_course from "@/data/course";
import config_module from "@/data/module";
import config_content from "@/data/content";

import config_ebook from "@/data/ebook";
import config_notification from "@/data/notification";
import config_tag from "@/data/tag";
import config_goal from "@/data/goal";
import config_feeling from "@/data/feeling";
import config_user from "@/data/user";
import config_user_paid from "@/data/user_paid";
import config_user_free from "@/data/user_free";
import config_promo from "@/data/promocode";
export default {
  name: 'sidebar',
  data() {
    return {
      user_name2: "",
      user_email2: "",
      user_image2: "",
      li_class: "nav-link"
    }
  },
  computed: {
    // currentRouteName() {
    //   let routeName = this.$route.name;
    //   let active_classes = "nav-link active router-link-active";
    //   if(routeName == "UserShow"){
    //     this.li_class = active_classes
    //   }
    //   if(routeName == "UserInsert"){
    //     this.li_class = active_classes
    //   }
    //   return this.$route.name;
    // },
    user_name() {
      this.user_name2 = localStorage.getItem('user_name');
      // console.log('computed');
      // console.log(this.user_name2);
      return this.$store.state.name;
    },
    user_email() {
      this.user_email2 = localStorage.getItem('user_email');
      return this.$store.state.email;
    },
    user_image() {
      this.user_image2 = localStorage.getItem('user_image');
      return this.$store.state.image;
    },
  },
  created() {
    this.name = config.name;
    this.contentTypes = config.contentTypes;
    this.config_mb = config_mb.contentTypes;
    this.config_mt = config_mt.contentTypes;
    this.config_script_categories = config_script_categories.contentTypes;
    this.config_series_categories = config_series_categories.contentTypes;
    this.config_video_categories = config_video_categories.contentTypes;
    this.config_course_categories = config_course_categories.contentTypes;
    this.config_event = config_event.contentTypes;
    this.config_course = config_course.contentTypes;
    this.config_module = config_module.contentTypes;
    this.config_content = config_content.contentTypes;
    this.config_ebook = config_ebook.contentTypes;
    this.config_notification = config_notification.contentTypes;
    this.config_tag = config_tag.contentTypes;
    this.config_goal = config_goal.contentTypes;
    this.config_feeling = config_feeling.contentTypes;
    this.config_user = config_user.contentTypes;
    this.config_user_paid = config_user_paid.contentTypes;
    this.config_user_free = config_user_free.contentTypes;
    this.config_promo = config_promo.contentTypes;
  },
  data() {
    return {
      user_name2: "",
      user_email2: "",
      user_image2: "",
      collapses: [
        { show: false },
        { show: false },
        { show: false }
      ]
    }
  },
  methods: {
    profilePage(id, name, image) {
      const url = `/profile/edit`
      this.$router.push({ path: url });
    },

    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    }
  },
  mounted() {
    // this.user_name()
    // this.user_email()
    // this.user_image()
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
  },
  watch: {
    $route() {
      document.querySelector('#sidebar').classList.toggle('active');
    }
  }
}
</script>

<style>

@media (992px <= width <= 1440px) {
.sidebarScrollArea{
  min-height:340px  !important;
}
}

.app-sidebar .position-fixed {
  width: inherit;
}

@media (min-width: 992px) {
  .sidebar-icon-only .sidebar .nav .nav-item {
    position: relative;
    padding: 0;
    z-index: 9999;
  }
}

/* 
nav#sidebar ul.nav {
    position: fixed;
} */
</style>