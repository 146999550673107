<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        <!-- {{ contentType.label }} -->
        <!-- All Lessons -->
        All Lessons of {{ module_name }}
      </h3>
      <!-- @click="showAddModal() -->
      <!-- <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Content
      </b-button> -->

      <div class="btn-group" role="group" aria-label="Basic example">
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search Lessons"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

      <b-dropdown class="" right variant="info">
        <template slot="button-content">
          Add Lesson By
          <!-- <i class="mdi mdi-dots-vertical"></i> -->
        </template>
        <b-dropdown-item @click="showAddModal('audio')">Audio</b-dropdown-item>
        <b-dropdown-item @click="showAddModal('video')">Video</b-dropdown-item>
        <b-dropdown-item @click="showAddModal('pdf')">PDF</b-dropdown-item>
        <b-dropdown-item @click="showAddModal('text')">Text</b-dropdown-item>
      </b-dropdown>

      </div>

    </div>
    <div class="row">

      <!-- <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-3">
                <b-form-select v-model="selected_category" value-field="id" text-field="title" @change="changeCategory">
                  <option :value="null" selected disabled>-- Please select sub category --</option>
                  <option :disabled="(selected_category == null || selected_category == 'all') ? true : false" value="all">All Sub Categories</option>
                  <option v-for="option in all_categories" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>
              <div class="col-md-3">
                <b-form-select v-model="selected_course" value-field="id" text-field="title" @change="changeCourse">
                  <option :value="null" selected disabled>-- Please select course --</option>
                  <option :disabled="(selected_course == null || selected_course == 'all') ? true : false" value="all">All Courses</option>
                  <option v-for="option in all_courses" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>
              <div class="col-md-3">
             
                <b-form-select @change="changeModule" v-model="selected_module" value-field="id" text-field="title">
                  <option :value="null" selected disabled>-- Please select module --</option>
                  <option :disabled="(selected_module == null || selected_module == 'all') ? true : false" value="all">All Modules</option>
                  <option v-for="option in all_modules" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>
              <div class="col-md-3">
                <button class="btn btn-success btn-block" @click="filter">Filter</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Scripts</h4> -->



            <!-- <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1> -->
            <div class="error_div" v-if="empty_data_error != ''">
              <h1>{{ empty_data_error }}</h1>
            </div>
            <div class="table_div" v-else>

              <!-- <div class="mb-4">
                <span class="page-title"><b>Show all records for sorting</b></span> &nbsp;
                <toggle-button @change="changePagiStatus" :value="data_table_pagination" />
              </div> -->

              <table class="table table-hover table-striped table-responsive" id="audios_table">
                <thead>
                  <tr class="frown" style="color:#303030 !important">
                    <th v-if="draggableStatus" scope="col" style="width: 1%"></th>
                    <th scope="col" style="width: 1%">#</th>
                    <th scope="col" style="width: 10%">Name</th>
                    <th scope="col" style="width: 2%">Type</th>
                    <!-- <th scope="col" style="width: 2%">Order</th> -->
                    <th scope="col" style="width: 2%">Created Date</th>
                    <th scope="col" style="width: 1%">Status</th>
                    <th scope="col" style="width: 1%">Actions</th>
                  </tr>
                </thead>
                <!-- :list="items" -->
                <!-- :force-fallback="true" :scroll-sensitivity="200" -->
                <!-- :disabled="!enabled" -->
                <draggable :sort="draggableStatus" :list="items" :force-fallback="true" :scroll-sensitivity="200" :options="{ animation: 200 }"
                  :element="'tbody'" @change="update2">
                  <tr v-for="(item, index) in items" :key="item.id" @mouseover="check_lesson(item.lesson_id)">
                    <!-- v-b-tooltip.html.right :title="!data_table_pagination ? 'Please On Show all Records for Sorting' : ''" -->
                    <td v-if="draggableStatus"><i class="mdi mdi-drag-vertical grab-cursor" style="font-size:24px;"></i></td>
                    <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                    <td>
                      <p class="bigText">
                        {{ item.title }}
                      </p>
                    </td>
                    <td>
                      <p class="bigText">
                        {{ item.type }}
                      </p>
                    </td>

                    <!-- <td>
                      <p class="bigText">
                        {{ item.order }}
                      </p>
                    </td> -->

                    <td>
                      <p class="bigText">
                        <!-- {{ item.created_at | moment('ll') }} -->
                        {{ getFormattedTime(item.created_at) }}
                      </p>
                    </td>

                    <td>
                      <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                    </td>
                    <td>

                      <!-- <button class="btn btn-info"  @click="showShowModal(item.id)">
                              <i class="fa fa-eye"></i>    
                            </button> -->
                      <button class="btn btn-warning" @click="showEditModal(item.id,
                      item.title,
                      item.url,
                      item.description,
                      item.lesson_id,
                      item.duration,
                      item.type,
                      item.image,
                      item.artist,
                      item.cover_image,
                      item.tag,
                      item.goal,
                      item.feeling)">
                        <i class="fa fa-pencil"></i>
                      </button>
                      <button class="btn btn-danger" @click="showAlert(item.id)">
                        <i class="fa fa-trash-o"></i>
                      </button>

                      <!-- <b-dropdown class="" right variant="info">
                        <template slot="button-content">
                          Actions
                        </template>
                        <a href="javascript:;" class="" style="text-decoration:none;">
                          <b-dropdown-item @click="
                          showEditModal(
                            item.id,
                            item.title,
                            item.url,
                            item.description,
                            item.lesson_id,
                            item.duration,
                            item.type,
                            item.image,
                            item.artist,
                            item.cover_image,
                            item.tag
                          )">
                            <i class="fa fa-pencil mr-2"></i>
                            Update
                          </b-dropdown-item>
                        </a>
                        <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                          <b-dropdown-item>
                            <i class="fa fa-trash-o mr-2"></i>
                            Delete
                          </b-dropdown-item>
                        </a>
                      </b-dropdown> -->
                    </td>
                  </tr>
                </draggable>
              </table>


              <!-- <table v-else class="table table-hover table-striped table-responsive" id="mytable">
                <thead>
                  <tr class="frown" style="color:#303030 !important">
                    <th scope="col" style="width: 1%">#</th>
                    <th scope="col" style="width: 10%">Name</th>
                    <th scope="col" style="width: 2%">Type</th>
                    <th scope="col" style="width: 2%">Order</th>
                    <th scope="col" style="width: 2%">Created Date</th>
                    <th scope="col" style="width: 1%">Status</th>
                    <th scope="col" style="width: 1%">Actions</th>
                  </tr>
                </thead>
                <draggable :force-fallback="true" :scroll-sensitivity="200" :list="items_custom"
                  :options="{ animation: 200 }" :element="'tbody'" @change="update">
                  <tr v-for="(item, index) in items_custom" :key="item.id">
                    <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                    <td>
                      <p class="bigText">
                        {{ item.title }}
                      </p>
                    </td>
                    <td>
                      <p class="bigText">
                        {{ item.type }}
                      </p>
                    </td>

                    <td>
                      <p class="bigText">
                        {{ item.order }}
                      </p>
                    </td>


                    <td>
                      <p class="bigText">
                        {{ item.created_at | moment('ll') }}
                      </p>
                    </td>

                    <td>
                      <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                    </td>

                    <td>
                      <b-dropdown class="" right variant="info">
                        <template slot="button-content">
                          Actions

                        </template>
                        <a href="javascript:;" class="" style="text-decoration:none;">
                          <b-dropdown-item @click="
                          showEditModal(
                        
                        
                            item.id,
                            item.title,
                            item.url,
                            item.description,
                            item.lesson_id,
                            item.duration,
                            item.type,
                            item.image,
                            item.artist,
                            item.cover_image,
                            item.tag
                        
                          )">

                            Update Lesson
                          </b-dropdown-item>
                        </a>
                        <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                          <b-dropdown-item>

                            Delete Now
                          </b-dropdown-item>
                        </a>
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>

              </table> -->

              <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
                  <div class="col-md-6 pageInfoBox">
                    Total records : {{ total_data }}
                    <!-- | Total pages : {{ items_total }}
                    | Current page : {{ page }} -->
                    <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
                  </div>
                  <!-- <div class="col-md-6 text-right">
                    <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                        class="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                        class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div> -->
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
    draggable
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      draggableStatus : true,
      pageNumberCategory : "",
      pageNumberCourse : "",
      pageNumberModule : "",
      pageNumber : "",

      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      // enabled: false,
      module_name: null,
      items_custom_status: false,
      items_custom_status2: false,
      data_table_pagination: false,
      selected_module: null,
      selected_module2: null,
      selected_course: null,
      selected_category: null,
      all_courses: [],
      all_categories: [],
      all_modules: [],
      computed_count: 0,
      computed_count2: 0,
      empty_data_error: "",
      items_data: "",
      items2: [],
      items_custom: [],
      items_backup: [],
      data_table: "",
      module_id: null,
      course_id: null,
      url: null,
    }
  },

  // computed: {
  //   // items2(){
  //   //   this.computed_count2++;
  //   // },
  //   items: {
  //     get() {
  //       console.log('get 1111');
  //       this.computed_count++;
  //       if (this.computed_count > 1 && this.$store.getters[`${this.contentType.name}/list`].length == 0) {
  //         this.empty_data_error = "Data Not Found!"
  //       } else {
  //         console.log('data table run')
  //         // this.data_table.destroy();
  //         if (this.data_table == "") {
  //           this.setTable();
  //         }
  //       }

  //       // this.items2 = this.$store.getters[`${this.contentType.name}/list`];
  //       // this.items_backup = this.items2
  //       // if (this.items_custom_status2) {
  //       //   console.log('under if items 2');
  //       //   console.log(this.$store.getters[`${this.contentType.name}/list`]);
  //       //   this.items_custom = this.$store.getters[`${this.contentType.name}/list`];
  //       // }
  //       // this.setTable();
  //       return this.$store.getters[`${this.contentType.name}/list`];
  //       // return this.$store.getters[`${this.contentType.name}/list`];
  //     },
  //     set(value) {
  //       this.items2 = value;
  //       return value;
  //     }
  //   }
  // },

  // watch: {
  //   items(newVal) {
  //     this.setTable();
  //     console.log('watch of computed : ', newVal);
  //   },
  //   items_custom(newVal) {
  //     this.setTable2();
  //   }
  // },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  async created() {
    this.$root.$refs.Category = this;
    this.module_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    // await this.$store.dispatch(`${this.contentType.name}/all`, {
    //   id: this.module_id,
    // });

    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  mounted() {
    // this.FetchAllCategories();
    // this.FetchAllCourses();
    // this.FetchAllModules();
    this.fetchPrevData();
    console.log('mounted pageNumber : ' , this.pageNumberModule);
    this.getData('next');
  },

  methods: {

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-content', { params: { keyword: this.keyword, lesson_id : this.module_id  } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.draggableStatus = false;
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/lesson-content?lesson_id=${this.module_id}&page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.content;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
          this.draggableStatus = true;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },

    async fetchPrevData() {
      // let data_header = await this.$store.getters['getEditFormData'];
      // if (data_header.length == 0) {
      //   data_header = localStorage.getItem('data_for_edit');
      // }
      // this.module_name = data_header?.category_name;


      let data_header = await this.$store.getters['getHeaderData'];
      console.log("items data 2 ::::: ", data_header)
      if (data_header.length == 0) {

        data_header = localStorage.getItem('data_for_header');
        data_header = JSON.parse(data_header);
      }
      console.log("data edit form 2 ::::: " , data_header)
      this.pageNumberCategory = data_header?.pageNumberCategory,
      this.pageNumberCourse = data_header?.pageNumberCourse,
      console.log(data_header?.pageNumberModule)
      this.pageNumberModule = data_header?.pageNumberModule,
      console.log(this.pageNumberModule);
      this.pageNumber = data_header?.pageNumber,
      this.module_name = data_header?.module_name
      // this.course_name = data_header?.course_name


      let data = await this.$store.getters['getEditFormData2'];
      if (data.length == 0) {
        console.log("items type", typeof data)
        console.log("items before parse ::::: ", data)
        data = localStorage.getItem('data_for_edit2');
        data = JSON.parse(data);
        console.log("items type", typeof data)
        console.log("items after parse ::::: ", data)
      }
      this.url = data?.module_url;
    },

    goBack() {
      console.log('page number :::: ',this.pageNumberModule);
      let prev_url = `/module/list/${this.url}`;
      this.$store.state.pageNumberAfterAction = this.pageNumberModule; // search &
      this.$router.push({ path: this.url });
      // this.$router.go(-1);
    },

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/lesson-content-status?id=${item.id}`);
        this.$swal('Success!', result.data.message, 'success')
        if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        // await this.$store.dispatch(`content/all`);
        // await this.$store.dispatch(`content/all`, {
        //   id: this.module_id,
        // });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changePagiStatus() {
      // this.enabled = !this.enabled;
      if (!this.data_table_pagination) {
        await this.$store.dispatch(`${this.contentType.name}/all`, {
          id: this.module_id
        });

        // this.data_table.destroy();

        // this.data_table.page.len(this.data_table.page.info().recordsTotal).draw()
        this.data_table_pagination = true;
        console.log("after action ::::: ", this.items);
      } else {
        this.data_table.page.len(10).draw()
        this.data_table_pagination = false;
      }
    },

    async update() {
      this.items_custom.map((dt, index) => {
        dt.order = index + 1;
      })
      let ids = this.items_custom.map((dt) => {
        return dt.id
      })
      const data = {
        "lesson_id": this.selected_module,
        "ids": ids
      }
      try {
        await api.post(`/admin/update-order`, data);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    check_lesson(id) {
      this.selected_module2 = id;
    },

    async update2() {
      // console.log('update 2');

      // console.log("selected module conten :::::: ", this.selected_module2)
      if (this.selected_module2 != null) {
        this.items.map((dt, index) => {
          dt.order = index + 1;
        })
        // console.log(this.items);
        let ids = this.items.map((dt) => {
          return dt.id
        })
        const data = {
          "lesson_id": this.selected_module2,
          "ids": ids
        }
        // console.log("update 2 for sorting ::: ", data);
        try {
          let res = await api.post(`/admin/update-order`, data);
          if (res.status == 200) {
            await this.$store.dispatch(`${this.contentType.name}/all`, {
              id: this.module_id
            });
          }
        } catch (error) {
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      }
      // console.log(this.selected_module2);
    },


    changeModule() {
      console.log(this.selected_module);
    },
    
    // setTable() {
    //   setTimeout(() => {
    //     if (this.items.length > 0) {
    //       if ($.fn.dataTable.isDataTable('#audios_table')) {
    //         this.data_table = $("#audios_table").DataTable();
    //       }
    //       else {
    //         this.data_table = $("#audios_table").DataTable({
    //         "paging": false
    //         });
    //       }
    //     }
    //   }, 2000);
    // },
    // setTable2() {
    //   setTimeout(() => {
    //     if (this.items_custom.length > 0) {
    //       $("#mytable").DataTable({
    //         "paging": false
    //       });
    //     }
    //   }, 0);
    // },

    async changeCategory() {
      try {
        if (this.selected_category == "all") {
          let result = await api.get(`/admin/all-courses`);
          this.all_courses = result.data.categories;
        } else {
          let result = await api.get(`/admin/course-cat-courses?course_cat_id=${this.selected_category}`);
          this.all_courses = result.data.courses;
        }
      } catch (error) {
        this.error = error.response.data.message;
        console.log(error);
      }
    },

    async changeCourse() {
      try {
        if (this.selected_course == "all") {
          let result = await api.get(`/admin/all-courses`);
          this.all_courses = result.data.categories;
        } else {
          let result = await api.get(`/admin/course-lessons?course_id=${this.selected_course}`);
          this.all_modules = result.data.lessons;
        }
      } catch (error) {
        this.error = error.response.data.message;
        console.log(error);
      }
    },

    async filter() {
      if (this.selected_module != null) {
        this.data_table.destroy();
        this.items_custom_status = false;
        console.log("selected :::: ", this.selected_module);
        try {
          if (this.selected_module == "all") {
            // this.items_custom = this.items_backup;
            // this.setTable();
            let result = await api.get(`/admin/all-lesson-content`);
            this.items_custom = result.data.all_lesson_content;
            if (result.status == 200) {
              // this.setTable2();
            }
          } else {
            let result = await api.get(`/admin/lesson-content?lesson_id=${this.selected_module}`);
            this.items_custom = result.data.content;
            if (result.status == 200) {
              // this.setTable2();
            }
            // this.items_custom = result.data.content;
            // if (result.data.content.length == 0) {
            //   this.items_custom_status = true;
            //   this.empty_data_error = "Data Not Found!"

            // }
            // this.items_custom_status2 = true;
          }
          if (this.items_custom.length == 0) {
            this.items_custom_status = true;
            this.empty_data_error = "Data Not Found!"
          }
          this.items_custom_status2 = true;

        } catch (error) {
          this.error = error.response.data.message;
          // this.$toast.error(this.error);
          console.log(error);
        }
      }
    },

    async FetchAllCategories() {
      try {
        let result = await api.get(`/admin/all-course-cat`);
        console.log('fetch all cat');
        console.log(result.data.all_course_categories);
        this.all_categories = result.data.all_course_categories;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async FetchAllCourses() {
      try {
        let result = await api.get(`/admin/all-courses`);
        // console.log("fetch all courses");
        // console.log(result);
        // console.log(result.data.all_course_categories);
        this.all_courses = result.data.categories;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async FetchAllModules() {
      try {
        let result = await api.get(`/admin/all-lessons`);
        // console.log("fetch all courses");
        // console.log(result);
        // console.log(result.data.all_course_categories);
        this.all_modules = result.data.all_lessons;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    async showEditModal(id, title, url, description, lesson_id, duration, type, image, artist, cover_image, tag_arr, goal_arr, feeling_arr) {
      // var tag = JSON.parse(tag_arr);
      // var goal = JSON.parse(goal_arr);
      // var feeling = JSON.parse(feeling_arr);
      var tag = tag_arr != null ? JSON.parse(tag_arr) : [];
      var goal = goal_arr != null ? JSON.parse(goal_arr) : [];
      var feeling = feeling_arr != null ? JSON.parse(feeling_arr) : [];
      // console.log('mounted from parent real');
      // console.log('title : ', title);
      // console.log('desc : ', description);
      // console.log('duration : ', duration);
      // console.log('image : ', image);
      // console.log('cover_image : ', cover_image);
      // console.log('author : ', artist);
      // console.log('tag : ', tag_arr);
      // console.log('id : ', id);
      // console.log('mid : ', lesson_id);
      // console.log('dl : ', this.items2.length);
      // console.log('type : ', type);
      // return false;
      await this.$store.dispatch('setDataInEditForm', {
        "pageNumber": this.page,
        "type": this.contentType.name, "form_type": type,
        "module_id": lesson_id, "data_length": this.items.length,
        id, title, url, description, duration, type, image, artist, cover_image, tag, goal, feeling,
        "course_id": this.course_id, "module_name": this.module_name
      })
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name, "form_type": type,
        "module_id": lesson_id, "data_length": this.items.length,
        id, title, url, description, duration, type, image, artist, cover_image, tag, goal, feeling,
        "course_id": this.course_id, "module_name": this.module_name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url2 = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url2 });
    },

    async showAddModal(type) {
      await this.$store.dispatch('setDataInEditForm', {
        "type": this.contentType.name, "form_type": type,
        "module_id": this.selected_module, "module_id2": this.module_id,
        "data_length": this.items.length, "course_id": this.course_id
        , "module_name": this.module_name
      })
      var obj = {
        "type": this.contentType.name, "form_type": type,
        "module_id": this.selected_module, "module_id2": this.module_id, "data_length": this.items.length,
        "course_id": this.course_id, "module_name": this.module_name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      console.log(url);
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.error(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id) {
      // console.log(this.items_custom_status);
      try {
        let endpoint2 = "/admin/delete-lesson-content/";
        const module_name = this.contentType.name;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              // await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.module_id
              // });
              this.getData();
              this.$swal(
                'Deleted!',
                'Lesson has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
i th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}

.grab-cursor {
  cursor: grabbing;
}
</style>
