<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Notifications
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">

        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search notifications"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <!-- <button type="button" class="btn btn-primary believe-btn" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i> Back</button> -->
        <!-- @click="showAddModal()" -->
        <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-send"></i> Send notification to all users</button>
      </div>
      <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a style="color:#3bccb0 !important" href="javascript:void(0);" @click="goBack()">Categories</a></li>
          <li class="breadcrumb-item active" aria-current="page">Scripts</li>
        </ol>
      </nav> -->
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Scripts</h4> -->
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 2%">#</th>
                  <th scope="col" style="width: 5%">Title</th>
                  <th scope="col" style="width: 20%">Description</th>
                  <th scope="col" style="width: 5%">Sending Time</th>
                  <th scope="col" style="width: 5%">Sending Date</th>
                  <th scope="col" style="width: 5%">Created Since</th>
                  <th scope="col" style="width: 5%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <p class="bigText">
                      {{ item.title }}
                    </p>
                  </td>

                  <td v-b-tooltip.html.right :title="item.description">
                    <p class="bigText">
                      {{ item.body.substr(0, 50).concat('....') }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ convertTime(item.notification_time) }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.notification_date,'ll') }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.created_at) }}
                    </p>
                  </td>
                  <td>
                    <button class="btn btn-warning" @click="showEditModal(
                      item.id,
                      item.title,
                      item.body,
                      item.notification_time,
                      item.notification_date
                    )">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>


            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <!-- <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
      <AudioAddModal />
    </b-modal>

    <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
      <AudioEditModal />
    </b-modal> -->

    <vue-snotify></vue-snotify>
  </section>

</template>
<script>
//   import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
//   import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import Vue from "vue";
// import SortedTablePlugin from "vue-sorted-table";
import $ from "jquery";

export default {
  components: {
    //   AudioEditModal,
    //   AudioAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  async created() {
    localStorage.removeItem('data_for_edit');
    //   console.log('data dome1')
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    // await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      data_table: null,
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
    };
  },
  // computed: {
  //   items() {
  //     this.computed_count++;
  //     const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //     console.log("notification ::::: ", audios_data);
  //     if (this.computed_count > 1 && audios_data.length == 0) {
  //       this.empty_data_error = "Data Not Found!"
  //     }
  //     else {
  //       this.setTable();
  //       return audios_data;
  //     }
  //   },

  // },
  mounted() {
    // window.addEventListener("keypress", function (e) {
    //   if (e.code == "Enter") {
    //     console.log('enter click');
    //     e.preventDefault()
    //   }
    // }.bind(this));
    this.getData('next');
  },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  methods: {


    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-notifications', { params: { keyword: this.keyword } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/all-notifications?page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.all_notifications;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    async showEditModal(id, title, body, notification_time,notification_date) {
      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": this.page,"type": this.contentType.name,
        id, title, body, notification_time, notification_date
      });

      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        id, title, body, notification_time, notification_date
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url = `/notifications/${id}/edit`;
      this.$router.push({ path: url });
    },

    convertTime(time) {
      if (time != "" && time != null) {
        const time_arr = time.split(':');
        if (time_arr.length > 0) {
          let hours = time_arr[0];
          let minutes = time_arr[1];
          let AmOrPm = hours >= 12 ? 'pm' : 'am';
          hours = (hours % 12) || 12;
          let finalTime = hours + ":" + minutes + " " + AmOrPm;
          return finalTime;
        }
      }
    },

    async changeStatus(item) {
      item.status = !item.status
      let endpoint = `/admin/ebook-status?id=${item.id}`;
      try {
        let result = await api.post(endpoint);
        const msg = await result.data.message;
        this.$swal('Success!', msg, 'success')
        if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }

        // await this.$store.dispatch(`ebooks/all_after_submit`);
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        let type = "Ebook";
        const data = {
          "type": type,
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status', data);
        const msg = await result.data.message;
        this.$swal('Success!', msg, 'success')
        if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        // await this.$store.dispatch(`ebooks/all_after_submit`);
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },

    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          this.data_table = $("#audios_table").DataTable();
        }
      }, 0);
    },

    async play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
    goBack() {
      let prev_url = "/notifications/list";
      this.$router.push({ path: prev_url });
    },
    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm',
        { "type": this.contentType.name })
      // this.$refs["add-modal"].show();
      const url = `/notifications/add/`;
      // console.log(url);
      this.$router.push({ path: url });
    },


    

    // showSnotifySuccess(msg) {
    //   this.$snotify.success(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },
    // showSnotifyError(msg) {
    //   this.$snotify.error(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },

    showAlert(id) {
      try {
        let endpoint2 = `/admin/delete-notification/`;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`);
              this.getData();
              this.$swal(
                'Deleted!',
                'Notification has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    }
  },
}
</script>

<style>
.bigText {
  /* display: inline-block; */
  /* width: 180px; */
  /* white-space: nowrap; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis; */
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

footer.b-time-footer {
  padding: 0px !important;
  background-color: #fff !important;
}


/* #audios_table .btn-secondary{
  background-color: #3bccb0 !important;
  border-color:  #3bccb0 !important;
} */
</style>
