<template>
  <div id="app">
    <loading
      :active="$store.state.isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <router-view></router-view>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: 'app',
  components: {
    Loading
  }
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";

.pageInfoBox{
  color: rgb(105, 105, 105) !important;
}

.pageInfoInput{
  width: 100px !important;
}

// .searchInputButton{
//   width: 10px !important;
// }

h1, h2, h3, h4, h5, h6 {
    color: #495057 !important;
}

.font-size-11 {
    font-size: 12px!important;
    font-weight: normal !important;
}
.badge-soft-warning {
    color: #f1b44c;
    background-color: rgba(241,180,76,.18);
  }
.badge-soft-success {
    color: #34c38f;
    background-color: rgba(52,195,143,.18);
}

.sidebar .nav .nav-item .nav-link .menu-title{
  font-size: 13px !important;
  // color: #545a6d !important;
}


.ql-container{
  height: 250px !important;
  overflow-y: auto;
}

table , #audios_table_wrapper{
  font-size: 14px !important;
}

table .btn{
  padding: 0.25rem 0.5rem;
  font-size: .71094rem;
    border-radius: 0.2rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color:#F8F8F8 !important;
}

.table-hover tbody tr:hover {
    background-color: #F8F8F8 !important;
}

table tr th{
  // font-size: 14px !important;
  // font-weight: 50;
  // color:#8a8a8adf;
}

table.dataTable thead th, table.dataTable tfoot th {
    font-weight: normal !important;
    color: #696969 !important;
}

table.dataTable th{
    border-bottom: none !important;
}

// table.dataTable tr{
//     border-color: #F8F8F8 !important;
// }

table, table.dataTable tr{
  border-color: #E8E8E8 !important;
}


.page-header .page-title{
  width: 70% !important;
  line-break: anywhere !important;
}

svg#line-progress , .vuejs-progress-bar{
  display: block !important;
  width: 100% !important;
}

svg#line-progress{
  border-radius: 0.25rem !important;
}


.card-body .dataTables_wrapper .dataTables_paginate .paginate_button.current, .card-body .dataTables_wrapper 
.dataTables_paginate .paginate_button.current:hover {
    background: linear-gradient(to bottom right,#45C5AF);
    color: #fff!important;
  }

  .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    background: linear-gradient(to bottom right,#45C5AF) !important;
  }


.err{
  color:red;
}

.same_btn{
  background-color:#45C5AF !important; 
  color:#fff !important;
}

.same_modal{
  border-radius: 5px;
  box-shadow: 0 0 50px #0000001f;
}

button:disabled {
    cursor:not-allowed
 }

// .believe-btn,
// button.btn-gradient-dark , 
// .btn-secondary:not(.btn-light):focus, 
// .btn-secondary:not(.btn-light):active{
//   background: #3bccb0 !important;
//   border-color: #3bccb0 !important;
//   color: white !important;
// }

.sidebar .nav.sub-menu .nav-item .nav-link.active {
  color: #3bccb0;
}

.file-box{
  cursor: pointer;
  border: 0.5px solid #A9A9A9;
  border-style: dashed;
  padding:5px 15px;
  // height: 85px;
}

.file-box .file-text-box{
  margin-left:25px; margin-top:13px
}
.file-box .file-text-box>span{
font-size: 16px; font-weight: bold;
}

.page-title{
  color:#696969 !important;
}


.button-group{
  display: flex;
  justify-content: center;
}

.button-group a{
  width: 33%;
}


.portfolio-grid .effect-text-in-footer h6 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.btn-group>.btn{
  flex: 1 !important;
}

</style>
