<template>
    <section class="forms">
        <div class="page-header">
            <h3 class="page-title">
                Edit Audio Of {{ this.category_name }} (Hypnosis)
            </h3>
            <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                <i class="mdi mdi-keyboard-backspace"></i> Back</button>

            <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
            <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
            <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
          <li class="breadcrumb-item active" aria-current="page">Basic</li>
        </ol>
      </nav> -->
        </div>
        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-8 offset-2 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <!-- <h4 class="card-title">Default form</h4>
                                <p class="card-description">
                                    Basic form layout
                                </p> -->
                                <b-form @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.name.required">Title is required</div>
                                                    <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                        characters</div>
                                                </div>

                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">

                                            <b-form-group id="example-input-group-1" label="Artist"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.artist.$model"
                                                    :state="$v.form.artist.$dirty ? !$v.form.artist.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                <div v-if="$v.form.artist.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.artist.required">Artist is required</div>
                                                    <div v-if="!$v.form.artist.maxLength">Maximum limit 30
                                                        characters</div>
                                                </div>

                                            </b-form-group>
                                        </div>
                                    </div>



                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="file-box row" @click="$refs.image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload cover image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url !== ''" :src="image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                            </div>
                                            <div class="col-md-12 my-4">
                                                <div class="file-box row" @click="$refs.cover_image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange2" ref="cover_image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload play screen image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url2 !== ''" :src="image_url2"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error2 != ''">{{ image_error2 }}</p>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="file-box row" v-if="audioFile">
                                                    <div class="col-md-12">
                                                        <div class="row file-text-box">
                                                            <div class="col-md-9">
                                                                <AudioFile :key="count" :audioSource="audioFile" />
                                                            </div>
                                                            <div class="col-md-3">
                                                                <button style="margin-top:6px;"
                                                                    @click="closeAudioPlayer()"
                                                                    class="btn btn-dark">x</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else class="file-box row" @click="$refs.audioFile.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload audio file here</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input class="ml" type="file" 
                                                    @change="uploadAudio" ref="audioFile" style="display: none" />
                                                <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <b-form-group id="example-input-group-1" label="Play Screen Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url !== ''" :src="image_url"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange"
                                            ref="image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Cover Image"
                                        label-for="example-input-1">
                                        <img v-if="image_url2 == ''" :src="'/assets/base.jpg'"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <img v-if="image_url2 !== ''" :src="image_url2"
                                            class="img-thumbnail rounded-circle" alt="Image"
                                            style="height: 65px; width: 65px" />
                                        <i class="fa fa-camera" @click="$refs.cover_image.click()"
                                            style="font-size: 25px;"></i>
                                        <div class="clear"></div>
                                        <p class="err" v-if="image_error2 != ''">{{ image_error2 }}</p>
                                        <input class="ml" type="file"  @change="onAudioImageChange2"
                                            ref="cover_image" style="display: none" />
                                    </b-form-group>

                                    <b-form-group id="example-input-group-1" label="Audio" label-for="example-input-1">
                                        <a href="javascript:;" class="btn btn-primary" @click="$refs.audioFile.click()">
                                            <i class="mdi mdi-upload"></i> Upload Audio</a>
                                        <AudioFile :key="count" :audioSource="audioFile" />
                                        <div class="clear"></div>
                                        <input class="ml" type="file" 
                                            @change="uploadAudio" ref="audioFile" style="display: none" />
                                        <p class="err" v-if="audioFile_err != ''">{{ audioFile_err }}</p>
                                    </b-form-group> -->

                                    <Tag @updateTag="updateTagFromChild" oldtags="" />
                                    <!-- <p class="err" v-if="tags_err != ''">{{ tags_err }}</p> -->


                                    <!-- <Goal @updateGoal="updateGoalFromChild" oldtags="" />
                                    <p class="err" v-if="goal_err != ''">{{ goal_err }}</p>


                                    <Feeling @updateFeeling="updateFeelingFromChild" oldtags="" />
                                    <p class="err" v-if="feeling_err != ''">{{ feeling_err }}</p> -->


                                    <b-tabs>
                                        <b-tab title="Goals" active>
                                            <Goal @updateGoal="updateGoalFromChild" oldgoals="" />
                                        </b-tab>
                                        <b-tab title="Feelings">
                                            <Feeling @updateFeeling="updateFeelingFromChild" oldfeelings="" />
                                        </b-tab>
                                    </b-tabs>

                                    <!-- <p class="err" v-if="goal_err != ''">{{ goal_err }}</p>
                                    <p class="err" v-if="feeling_err != ''">{{ feeling_err }}</p> -->


                                    <b-form-group id="example-input-group-1" label="Description"
                                        label-for="descriptionTextarea">
                                        <b-form-textarea @keydown.native="test_keydown_handler" id="descriptionTextarea"
                                            name="example-input-1" v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>

                                        <div v-if="$v.form.description.$error" class="invalid-feedback">
                                            <div v-if="!$v.form.description.required">Description is required</div>
                                        </div>

                                    </b-form-group>

                                    <b-button :disabled='is_loading' type="submit" variant="success"
                                        class="btn-block believe-btn">
                                        <i
                                            :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                        Submit
                                    </b-button>
                                </b-form>


                                <br />
                                <div v-show="is_progress_show">
                                    <progress-bar :value="progress" :options="options" />
                                </div>

                                <vue-snotify></vue-snotify>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import Tag from "@/components/Tag.vue";
import Goal from "@/components/Goal.vue";
import Feeling from "@/components/Feeling.vue";
import AudioFile from "@/components/AudioFile.vue";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioAddModal',
    mixins: [validationMixin],
    data() {
        return {
            category_name: null,
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            image_error2: "",
            image_url2: "",
            audioFile: "",
            audioFile_err: "",
            tags_err: "",
            file_duration: "",
            tags: [],
            goals: [],
            feelings: [],
            // goal_err: "",
            // feeling_err: "",
            pageNumber : "",
            type: "",
            form: {
                name: null,
                image: "",
                path: null,
                description: null,
                cat_id: null,
                duration: null,
                cover_image: null,
                artist: null,

            },
            is_progress_show: false,
            progress: 0,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: true,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#2dbd2d',
                    backgroundColor: '#333333',
                    inverted: false
                },
                layout: {
                    height: 35,
                    width: 887,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 50,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },

    components: {
        Tag,
        AudioFile,
        Goal,
        Feeling,
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                required,
            },
            artist: {
                required,
                maxLength: maxLength(30)
            },
        }
    },
    watch: {
        // tags(newVal) {
        //     if (newVal.length > 0) {
        //         this.tags_err = "";
        //     }
        // },
        // goals(newVal) {
        //     if (newVal.length > 0) {
        //         this.goal_err = "";
        //     }
        // },
        // feelings(newVal) {
        //     if (newVal.length > 0) {
        //         this.feeling_err = "";
        //     }
        // }
    },

    mounted() {
        window.addEventListener("keypress", function (e) {
            if (e.code == "Enter") {
                e.preventDefault()
            }
        }.bind(this));
    },

    created() {
        this.items()
    },

    methods: {

        test_keydown_handler(event) {
            if (event.which === 13) {
                let txt = document.getElementById('descriptionTextarea');
                let indexOfChar = txt.value.slice(0, txt.selectionStart).length + 1
                txt.value = txt.value.slice(0, txt.selectionStart) + '\r\n' +
                txt.value.slice(txt.selectionStart, txt.value.length);
                this.setCaretPosition('descriptionTextarea',indexOfChar);
            }
        },

        setCaretPosition(elemId, caretPos) {
            var elem = document.getElementById(elemId);
            if (elem != null) {
                if (elem.createTextRange) {
                    var range = elem.createTextRange();
                    range.move('character', caretPos);
                    range.select();
                }
                else {
                    if (elem.selectionStart) {
                        elem.focus();
                        elem.setSelectionRange(caretPos, caretPos);
                    }
                    else
                        elem.focus();
                }
            }
        },

        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            // console.log("tags ::::::: " , data);
            this.pageNumber = data?.pageNumber,
            this.type = data?.type,
                this.form.name = data?.name;
            this.form.path = data?.path;
            this.audioFile = data?.path;
            this.form.description = data?.description;
            this.form.cat_id = data?.cat_id;
            this.form.duration = data?.duration;
            this.image_url = data?.image;
            this.image_url2 = data?.cover_image;
            this.form.artist = data?.artist;
            this.tags = data?.tag;
            if (data?.goal == null) {
                this.goals = [];
            } else {
                this.goals = data?.goal;
            }
            if (data?.feeling == null) {
                this.feelings = [];
            } else {
                this.feelings = data?.feeling;
            }
            // this.feelings = data?.feeling;
            this.form.id = data?.id;
            this.category_name = data?.category_name
        },

        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
            let prev_url = `/hypnosis/list/${category_id}`;
            this.$router.push({ path: prev_url });
        },

        updateTagFromChild(arr) {
            this.tags = arr;
            console.log("from parent tags :::: ", this.tags);
        },

        updateGoalFromChild(arr) {
            this.goals = arr;
        },
        updateFeelingFromChild(arr) {
            this.feelings = arr;
        },


        onAudioImageChange(e) {
            this.form.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },
        onAudioImageChange2(e) {
            this.form.cover_image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image2')
            if (status) {
                this.image_error2 = "";
                this.form.cover_image = file;
                this.image_url2 = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (num == 'audio') {
                types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
                if (!types.includes(file.type)) {
                    this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
                    this.audioFile = ""
                    return false;
                }
                return true;
            } else {
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.form.image = "";
                    }
                    if (num == 'image2') {
                        this.image_error2 = msg
                        this.form.cover_image = "";
                    }
                    return false;
                }
                return true;
            }
        },

        uploadAudio(e) {
            this.audioFile_err = "";
            this.audioFile = "";
            const audio = e.target.files[0];
            var status = this.checkFileType(audio, 'audio');
            if (status) {
                const reader = new FileReader();
                reader.readAsDataURL(audio);
                var audio_el = document.createElement("audio");
                reader.onload = (e) => {
                    this.audioFile = e.target.result;
                    console.log('audio upload');
                    console.log(this.audioFile);
                    audio_el.src = e.target.result;
                    audio_el.addEventListener("loadedmetadata", () => {
                        var duration = audio_el.duration;
                        // const audio_file = new Date(duration * 1000).toISOString().slice(14, 19);
                        this.file_duration = duration;
                        // console.log(
                        //   "The duration of the song is of: " + this.file_duration + " seconds"
                        // );
                    });
                };
                this.count++;
                // console.log(this.file_duration, "Song Duration");
            }
            console.log('audio upload');
            console.log(this.audioFile);
        },

        async onSubmit(e) {
            e.preventDefault();
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }
            if (this.image_url != ""
                && this.image_url2 != ""
                && this.audioFile != ""
                // && this.tags.length != 0
                // && this.goals.length != 0
                // && this.feelings.length != 0
            ) {
                //     console.log("image  ::::: " , this.image_url);
                // console.log("image2  ::::: " , this.image_url2);
                // console.log("audioFile  ::::: " , this.audioFile);
                // console.log("tags  ::::: " , this.tags.length);
                // console.log("goals  ::::: " , this.goals.length);
                // console.log("feelings  ::::: " , this.feelings.length);
                //     console.log("goals  ::::: " , this.goals);
                // console.log("feelings  ::::: " , this.feelings);
                try {
                    // console.log("all tags :::::: "  , this.tags)
                    // console.log("all goals ::::: " , this.goals)
                    // console.log("all feelings ::::: " , this.feelings)
                    this.is_loading = true;
                    // const obj = this.$store.getters['getEditFormData'];
                    let endpoint = "";
                    if (this.type != "") {
                        if (this.type == "hypnosis") {
                            endpoint = "/admin/update-hypno-audio";
                        }
                        else if (this.type == "meditation") {
                            endpoint = "/admin/update-meditation-audio";
                        }
                        else if (this.type == "affirmation") {
                            endpoint = "/admin/update-affirmation-audio";
                        }
                    }

                    const formData = new FormData();
                    formData.append("id", this.form?.id);
                    formData.append("name", this.form?.name);
                    formData.append("cat_id", this.form?.cat_id);
                    formData.append("description", this.form.description);
                    formData.append("artist", this.form.artist);

                    if (this.tags) {
                        if (typeof this.tags == 'string') {
                            formData.append('tag[]', this.tags);
                        } else {
                            for (var i = 0; i < this.tags.length; i++) {
                                formData.append('tag[]', this.tags[i]);
                            }
                        }
                    }

                    if (typeof this.goals == 'string') {
                        formData.append('goal[]', this.goals);
                    } else {
                        // console.log('else')
                        for (var i = 0; i < this.goals.length; i++) {
                            formData.append('goal[]', this.goals[i]);
                        }
                    }

                    if (typeof this.feelings == 'string') {
                        formData.append('feeling[]', this.feelings);
                    } else {
                        // console.log('else')
                        for (var i = 0; i < this.feelings.length; i++) {
                            formData.append('feeling[]', this.feelings[i]);
                        }
                    }


                    if (this.$refs.audioFile.files[0]) {
                        formData.append("path", this.$refs.audioFile.files[0]);
                        formData.append("duration", this.file_duration);
                    }
                    if (this.form.image) {
                        formData.append("image", this.form.image);
                    }
                    if (this.form.cover_image) {
                        formData.append("cover_image", this.form.cover_image);
                    }

                    this.is_progress_show = true;
                    let result = await api.post(endpoint, formData, {
                        onUploadProgress: function (progressEvent) {
                            this.progress = parseInt(
                                Math.round((progressEvent.loaded / progressEvent.total) * 100)
                            );
                        }.bind(this)
                    });
                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    // await this.$store.dispatch(`${this.type}/all_after_submit`, {
                    //     id: this.form?.cat_id
                    // });
                    // this.$root.$refs.Audio.$refs['add-modal'].hide();
                    // const category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
                    let prev_url = `/hypnosis/list/${this.form?.cat_id}`;
                    this.$store.state.pageNumberAfterAction = this.pageNumber;
                    this.$router.push({ 
                        path: prev_url, 
                    });
                    this.is_loading = false;
                    this.is_progress_show = false;
                } catch (error) {
                    console.log(error);
                    this.progress = 0;
                    this.is_progress_show = false;
                    // let err = error.response.data.message;
                    // this.$store.dispatch('setMessage', { err });
                    // this.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            } else {
                this.audioFile_err = (this.audioFile == "" && this.audioFile_err == "") ? "Audio file is required" : this.audioFile_err;
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
                this.image_error2 = (this.image_url2 == "" && this.image_error2 == "") ? "Image is required" : this.image_error2
                // this.tags_err = (this.tags.length == 0 && this.tags_err == '') ? "Tags required" : this.tags_err
                // this.feeling_err = (this.feelings.length == 0 && this.feeling_err == '') ? "Feelings require" : this.feeling_err
                // this.goal_err = (this.goals.length == 0 && this.goal_err == '') ? "Goals require" : this.goal_err
            }
        },


        showSnotifySuccess(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.success(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },
        showSnotifyError(msg) {
            let obj = this.$store.getters['getMessage'];
            this.$snotify.error(obj.msg, {
                timeout: 5000,
                showProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
            });
        },

    },




}
</script>