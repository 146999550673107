<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        <!-- <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button> -->
        Meditation Backgrounds
      </h3>
      <div class="btn btn-group">

        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search Backgrounds"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <!-- <button type="button" class="btn btn-info believe-btn" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i> Back</button> -->
        <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add Background</button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Audios</h4> -->

            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 1%">#</th>
                  <th scope="col" style="width: 1%">Background Image</th>
                  <th scope="col" style="width: 10%">Title</th>
                  <th scope="col" style="width: 1%">Created Date</th>
                  <th scope="col" style="width: 1%">Status</th>
                  <th scope="col" style="width: 1%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id" :id="index">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td class="">
                    <img :src="item.background_image" alt="" />
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.title }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      <!-- {{ item.created_at | moment('ll') }} -->
                      {{ getFormattedTime(item.created_at) }}
                    </p>
                  </td>

                  <td>
                    <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td>

                  <td>

                    <button class="btn btn-warning" @click="
                     showEditModal(
                        item.id,
                          item.title,
                          item.background_image,
                        )">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>
                    
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
      <MeditationBackgroundAddModal />
    </b-modal>

    <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
      <MeditationBackgroundEditModal />
    </b-modal> -->

    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import MeditationBackgroundEditModal from "../modals/medi-other-content-modals/MeditationBackgroundEditModal.vue";
import MeditationBackgroundAddModal from "../modals/medi-other-content-modals/MeditationBackgroundAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "MBListingContainer",
  props: {
    contentType: {
      required: true,
      type: Object
    }
  },
  components: {
    MeditationBackgroundAddModal,
    MeditationBackgroundEditModal,
  },
  async created() {
    // this.$root.$refs.Category = this;
    // await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },

  data() {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work


      data_table : null,
      empty_data_error: "",
      computed_count: 0,
    }
  },

  // computed: {
  //   items() {
  //     this.computed_count++;
  //     const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //     if (this.computed_count > 1 && audios_data.length == 0) {
  //       this.empty_data_error = "Data Not Found!"
  //     }
  //     else {
  //       this.setTable();
  //     }
  //     // console.log("computed run ::: " , audios_data)
  //     return audios_data;
  //   }
  // },

  mounted() {
    // window.addEventListener("keypress", function (e) {
    //   if (e.code == "Enter") {
    //     console.log('enter click');
    //     e.preventDefault()
    //   }
    // }.bind(this));
    this.getData('next');
  },


  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  methods: {

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-medi-backgrounds', { params: { keyword: this.keyword } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/all-med-backgrounds?page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.all_meditation_backgrounds;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    goBack() {
      let prev_url = `/meditation-categories/list/`;
      this.$router.push({ path: prev_url });
    },

    async showEditModal(id, name, image) {
      await this.$store.dispatch('setDataInEditForm', { "pageNumber": this.page,"type": this.contentType.name, id, name, image })
      // this.$refs['edit-modal'].show()
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        id,
        name,
        image,
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          this.data_table = $("#audios_table").DataTable();
        }
      }, 2000);
    },

    async changeStatus(item) {
      item.status = !item.status
      let endpoint = "";
      if (this.contentType?.name != "") {
        endpoint = `/admin/med-background-status?id=${item.id}`;
      }
      try {
        let result = await api.post(endpoint);
        const msg = await result.data.message;
        this.$swal('Success!', msg, 'success')
        if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },

    showSnotifySuccess(msg) {
      this.$snotify.success(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      this.$snotify.error(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm', { "type": this.contentType.name })
      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      console.log(url);
      this.$router.push({ path: url });
    },

    showAlert(id) {
      // console.log(table_row);
      try {
        let endpoint2 = "/admin/delete-med-background/";
        const module_name = this.contentType.name;
        // if (module_name) {
        //   if (module_name == "hypnosis") {
        //     endpoint2 = "/admin/delete-hypno-audio/";
        //   }
        //   else if (module_name == "meditation") {
        //     endpoint2 = "/admin/delete-meditation-audio/";
        //   }
        //   else if (module_name == "affirmation") {
        //     endpoint2 = "/admin/delete-affirmation-audio/";
        //   }
        // }
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if(result.status == 200){
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`);
              this.getData();
              this.$swal(
                'Deleted!',
                'Meditation Background has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    }
  }

};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
th {
  padding: 0.25em;
}
</style>
