<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        Events
      </h3>

      <div class="btn-group">

        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search events"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Event
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Scripts</h4> -->
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 1%">#</th>
                  <th scope="col" style="width: 1%">Image</th>
                  <th scope="col" style="width: 10%">Title</th>
                  <!-- <th scope="col" style="width: 1%">Type</th> -->
                  <th scope="col" style="width: 1%">Link</th>
                  <th scope="col" style="width: 1%">Date</th>
                  <th scope="col" style="width: 1%">Start time</th>
                  <th scope="col" style="width: 1%">End time</th>
                  <th scope="col" style="width: 1%">Notification Date</th>
                  <th scope="col" style="width: 1%">Notification Time</th>                
                  <!-- <th scope="col" style="width: 0%">Status</th> -->
                  <!-- <th scope="col" style="width: 5%">Is Paid</th> -->
                  <th scope="col" style="width: 0%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <img :src="item.image" alt="" />
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.name }}
                    </p>
                  </td>
                  <!-- <td>
                    <p>
                      {{ item.type }}
                    </p>
                  </td> -->

                  <td>
                    <a href="javascript:;" @click="gotostreampage(
                    item.id,
                    item.name,
                    item.type,
                    item.description,
                    item.image,
                    item.date,
                    item.start_time,
                    item.notify_time,
                    item.notify_date,
                    item.end_time,
                    item.link)">Go to Stream</a>
                  </td>

                  <td>
                    <p class="bigText">
                      <!-- {{ item.date | moment('ll') }} -->
                      {{ getFormattedTime(item.date,'ll') }}
                    </p>
                  </td>

                  <td>
                    <p>
                      {{ getFormattedTime("2022-08-10 " + item.start_time,'h:mm a') }}
                    </p>
                  </td>

                  <td>
                    <p>
                      {{ getFormattedTime("2022-08-10 " + item.end_time,'h:mm a') }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.notify_date,'ll') }}
                    </p>
                  </td>

                  <td>
                    <p>
                      {{ getFormattedTime("2022-08-10 " + item.notify_time,'h:mm a') }}
                    </p>
                  </td>

                  <!-- <td>
                    <p class="bigText">
                      {{ item.description }}
                    </p>
                  </td> -->

                  <!-- <td>
                    <a href="javascript:;" @click="play_aud(item.path)"
                      class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                      <i class="mdi mdi-play"></i>
                    </a>
                  </td> -->

                  <!-- <td>
                    <toggle-button  @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td> -->
                  <!-- 
                  <td>
                    <toggle-button color="rgb(0,123,255)" @change="changeSubscriptionStatus(item)"
                      :value="(item.is_paid == 1) ? true : false" />
                  </td> -->

                  <td>

                    <button class="btn btn-warning" @click="
                      showEditModal(
                          item.id,
                          item.name,
                          item.type,
                          item.description,
                          item.image,
                          item.date,
                          item.start_time,
                          item.notify_time,
                          item.notify_date,
                          item.end_time,
                          item.link
                        )">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!searchButtonClicked && empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      data_table : null,
      empty_data_error: "",
      items_data: "",
    }
  },

  // computed: {
  //   // items: {
  //   //   get() {
  //   //     return this.$store.getters[`${this.contentType.name}/list`];
  //   //   },
  //   //   set(value) {
  //   //     this.items_data = value;
  //   //   }
  //   // }
  //   items() {
  //     const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //     // console.log("audios items  :::::: " ,audios_data);
  //     if (this.computed_count > 1 && audios_data.length == 0) {
  //       this.empty_data_error = "Data Not Found!"
  //     } else {
  //       this.setTable();
  //       return audios_data;
  //     }
  //   },

  // },


  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  async created() {
    this.$root.$refs.Category = this;
    // await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },

  mounted() {
    this.getData('next');
    },

  methods: {

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-events', { params: { keyword: this.keyword } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/all-events?page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.all_events;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    async gotostreampage(id, name, type2, description, image, date, start_time, notify_time,notify_date,end_time, link) {
      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": this.page,"type": this.contentType.name,
        id, name, type2, description, image, date, start_time, notify_time,notify_date, end_time, link
      });
      var obj = {
        "pageNumber": this.page,"type": this.contentType.name,
        id, name, type2, description, image, date, start_time,  notify_time,notify_date, end_time, link
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url = `/events/${id}/show`;
      this.$router.push({ path: url });
    },


    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          this.data_table = $("#audios_table").DataTable();
        }
      }, 2000);
    },


    // async changeStatus(item) {
    //   item.status = !item.status
    //   let endpoint = "";
    //   if (this.contentType?.name != "") {
    //     if (this.contentType.name == "hypnosis-categories") {
    //       endpoint = `/admin/hypno-cat-status?id=${item.id}`;
    //     }
    //     else if (this.contentType.name == "meditation-categories") {
    //       endpoint = `/admin/med-cat-status?id=${item.id}`;
    //     }
    //     else if (this.contentType.name == "affirmation-categories") {
    //       endpoint = `/admin/aff-cat-status?id=${item.id}`;
    //     }
    //   }
    //   try {
    //     let result = await api.post(endpoint);
    //     await this.$store.dispatch(`${this.contentType.name}/all`);
    //     this.$swal('Success!', result.data.message, 'success')
    //     if (this.keyword != "") {
    //         this.getResults()
    //       } else {
    //         this.getData();
    //       }
    //   } catch (error) {
    //     this.error = error.response.data.message;
    //     this.$toast.error(this.error);
    //   }
    // },

    async showEditModal(id, name, type2, description, image, date, start_time,notify_time,notify_date,end_time, link) {
      await this.$store.dispatch('setDataInEditForm', { "pageNumber": this.page,"type": this.contentType.name, 
      id, name, type2, description, image, date, start_time,notify_time,notify_date, end_time, link })
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        id, name, type2, description, image, date, start_time,notify_time,notify_date, end_time, link
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    // async showAddModal() {
    //   await this.$store.dispatch('setDataInEditForm', { "type": this.contentType.name })
    //   this.$refs['add-modal'].show()
    // },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm',
        { "type": this.contentType.name })
      // localStorage.setItem('obj',{ "type": this.contentType.name, "category_id": this.category_id })
      // this.$refs["add-modal"].show();
      const url = `/${this.contentType.name}/add/`
      console.log(url);
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.error(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-event/";
        const module_name = this.contentType.name;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if(result.status == 200){
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`);
              this.getData();
              this.$swal(
                'Deleted!',
                'Event has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}
</style>
