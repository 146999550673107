<template>
    <div>
      <audio controls v-if="audioSource !== ''">
        <source :src="audioSource" type="audio/mpeg" />
      </audio>
    </div>
  </template>
  
  <script>
  export default {
    name: "AudioFile",
    props:{
      audioSource:String
  
    }
  };
  </script>
  
  <style></style>
  