import Vue from "vue";

export default function makeCrudModule({
  normalizeRelations = x => x,
  resolveRelations = x => x,
  service
} = {}) {
  return {
    // Actions for `create`, `update` and `delete` omitted for brevity.
    actions: {
      all: async ({ commit }, data) => {
        // console.log('all function check for course');
        // console.log(data);
         // It is not strictly necessary to pass a service,
        // but if none was passed, no data can be loaded.
        if (!service) throw new Error("No service specified!");
        let items = null;
        if(data || typeof data !== "undefined"){
          console.log("all dispatch if :::::::::: " , data);
          items = await service.list2(data?.id,data?.endpoint2); 
        }else{
          items = await service.list(); 
          console.log("all dispatch else :::::::::: " , items);
        }
        commit("set_all1");
        console.log(items);
        items.forEach(item => {
          // Noramlize nested data and swap the related objects
          // in the API response with an ID reference.
          commit("add", normalizeRelations(item));
        });
      },
      all_after_submit: async ({ commit }, data) => {
        console.log('list 2 store');
        console.log(data);
        if (!service) throw new Error("No service specified!");
        let items = null;
        if(data || typeof data !== "undefined"){
          items = await service.list2(data?.id); 
        }else{
          items = await service.list(); 
        }
        items.forEach(item => {
          commit("add_after_submit", normalizeRelations(item));
        });
      },
      load: async ({ commit }, id) => {
        if (!service) throw new Error("No service specified!");

        const item = await service.find(id);
        commit("add", normalizeRelations(item));
      },

      submit: async ({ commit }, params) => {
        if (!service) throw new Error("No service specified!");

        const message = await service.submit(params);
        commit("message", message);
      },
    },
    getters: {
      // Return a single item with the given id.
      find: state => id => {
        // Swap ID referenes with the resolved objects.
        return resolveRelations(state.byId[id]);
      },
      // Return a list of items in the order of `allIds`.
      list: (state, getters) => {
        // console.log('state list');
        // console.log(state);
        let data = state.allIds.map(id => getters.find(id));
        return data;
      },

      getMessage:(state)=>{
        return state.message;
      }
    },
    mutations: {
      add: (state, item) => {
        Vue.set(state.byId, item.id, item);
        // if (state.allIds.includes(item.id)) return;
        state.allIds.push(item.id);
      },

      set_all1: (state) => {
        state.allIds = [];
      },

      add_after_submit: (state, item) => {
        Vue.set(state.byId, item.id, item);
        if (state.allIds.includes(item.id)) return;
        state.allIds.unshift(item.id);
      },
      
      message: (state, message) => {
        state.message = message;
      },

    },
    namespaced: true,
    state: {
      byId: {},
      allIds: [],
      message: null,
      // categories: []
    }
  };
}
