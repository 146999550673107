<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        <!-- {{ contentType.label }} -->
        <!-- All Courses -->
        All Courses of {{ this.category_name }}
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search Courses"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <!-- @click="showAddModal() -->
        <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Course
        </b-button>
      </div>
    </div>
    <div class="row">

      <!-- <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-4">
                <b-form-select v-model="selected_category" value-field="id" text-field="title">
                  <option :value="null" disabled>-- Please select an category --</option>
                  <option :disabled="(selected_category == null || selected_category == 'all') ? true : false" value="all">From All Categories</option>
                  <option v-for="option in all_categories" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>
              <div class="col-md-2">
                <button class="btn btn-success" @click="filter">Filter</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->


      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="">
              <!-- <h1>second div</h1> -->
              <div class="error_div" v-if="empty_data_error != ''">
                <h1>{{ empty_data_error }}</h1>
              </div>
              <div class="table_div" v-else>
                <table id="audios_table" class="table table-hover table-striped table-responsive audios_table">
                  <thead>
                    <tr class="frown" style="color:#303030 !important">
                      <th scope="col" style="width: 1%">#</th>
                      <!-- <th scope="col" style="width: 10%">Image</th> -->
                      <th scope="col" style="width: 15%">Name</th>
                      <!-- <th scope="col" style="width: 50%">Description</th> -->
                      <!-- <th scope="col" style="width: 10%">Audio File</th> -->
                      <th scope="col" style="width: 1%">Created Date</th>
                      <th scope="col" style="width: 1%">Status</th>
                      <!-- <th scope="col" style="width: 1%">Is Paid</th> -->
                      <th scope="col" style="width: 1%">Actions</th>
                    </tr>
                  </thead>
                  <tbody style="color:#696969 !important;">
                    <tr v-for="(item, index) in items" :key="item.id">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                      <!-- <td>
                    <img :src="item.image" alt="" />
                  </td> -->
                      <td>
                        <p class="bigText">
                          {{ item.title }}
                        </p>
                      </td>

                      <!-- <td>
                    <p class="bigText">
                      {{ item.description }}
                    </p>
                  </td> -->

                      <!-- <td>
                    <a href="javascript:;" @click="play_aud(item.path)"
                      class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                      <i class="mdi mdi-play"></i>
                    </a>
                  </td> -->

                      <td>
                        <p class="bigText">
                          <!-- {{ item.created_at | moment('ll') }} -->
                          {{ getFormattedTime(item.created_at) }}
                        </p>
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>

                      <!-- <td>
                        <toggle-button @change="changeSubscriptionStatus(item)"
                          :value="(item.is_available == 1) ? true : false" />
                      </td> -->

                      <td>

                        <button class="btn btn-info" @click="showShowModal(item.title, item.id)">
                          <i class="fa fa-eye"></i>
                        </button>
                        <button class="btn btn-warning"
                          @click="showEditModal(item.id, item.title, item.description, item.image, item.course_cat_id)">
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button class="btn btn-danger" :disabled="item.title.toLowerCase() == 'self hypnosis step by step'"
                          @click="showAlert(item.id, item.title)">
                          <i class="fa fa-trash-o"></i>
                        </button>

                        <!-- <b-dropdown class="" right variant="info">
                          <template slot="button-content">
                            Actions

                          </template>

                            <a href="javascript:;" class="" style="text-decoration:none;">
                        <b-dropdown-item @click="
                        showShowModal(
                          item.id,
                        )">
                          <i class="fa fa-eye mr-2"></i>
                          Show

                        </b-dropdown-item>
                      </a>


                          <a href="javascript:;" class="" style="text-decoration:none;">
                            <b-dropdown-item @click="
                            showEditModal(
                              item.id, item.title, item.description, item.image, item.course_cat_id
                            )">
                              <i class="fa fa-pencil mr-2"></i>
                              Update Course
                            </b-dropdown-item>
                          </a>
                          <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                            <b-dropdown-item>
                              <i class="fa fa-trash-o mr-2"></i>
                              Delete Now
                            </b-dropdown-item>
                          </a>
                        </b-dropdown> -->

                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
                  <div class="col-md-6 pageInfoBox">
                    Total records : {{ total_data }}
                    | Total pages : {{ items_total }}
                    | Current page : {{ page }}
                    <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
                  </div>
                  <div class="col-md-6 text-right">
                    <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                        class="fa fa-arrow-left" aria-hidden="true"></i></button>
                    <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                        class="fa fa-arrow-right" aria-hidden="true"></i></button>
                  </div>
                </div>



                <!-- <table id="mytable" class="table table-hover table-striped table-responsive audios_table">
                  <thead>
                    <tr class="frown" style="color:#303030 !important">
                      <th scope="col" style="width: 1%">#</th>
                      
                      <th scope="col" style="width: 10%">Name</th>
                      
                      <th scope="col" style="width: 1%">Created Date</th>
                      <th scope="col" style="width: 1%">Status</th>
                      
                      <th scope="col" style="width: 1%">Actions</th>
                    </tr>
                  </thead>
                  <tbody style="color:#696969 !important;">
                    <tr v-for="(item, index) in items_custom" :key="item.id">
                      <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                     
                      <td>
                        <p class="bigText">
                          {{ item.title }}
                        </p>
                      </td>

                     

                      <td>
                        <p class="bigText">
                          {{ item.created_at | moment('ll') }}
                        </p>
                      </td>

                      <td>
                        <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                      </td>


                   

                      <td>

                        <b-dropdown class="" right variant="info">
                          <template slot="button-content">
                            Actions
                            
                          </template>
                          <a href="javascript:;" class="" style="text-decoration:none;">
                            <b-dropdown-item @click="
                            showEditModal(
                              item.id, item.title, item.description, item.image, item.course_cat_id
                            )">
                             
                              Update Course
                            </b-dropdown-item>
                          </a>
                          <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                            <b-dropdown-item>
                             
                              Delete Now
                            </b-dropdown-item>
                          </a>
                        </b-dropdown>

                      </td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {

      pageNumberCategory: "",
      pageNumber: "",

      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work


      selected_category: null,
      all_categories: [],
      computed_count: 0,
      empty_data_error: "",
      items_data: "",
      items2: [],
      items_custom: [],
      items_custom_status: false,
      items_backup: [],
      data_table: "",
      category_id: null,
      category_name: null,
    }
  },

  // computed: {
  //   items: {
  //     get() {
  //       console.log('get');
  //       this.computed_count++;
  //       // console.log(this.$store.getters[`${this.contentType.name}/list`]);
  //       if (this.computed_count > 1 && this.$store.getters[`${this.contentType.name}/list`].length == 0) {
  //         this.empty_data_error = "Data Not Found!"
  //       }
  //       else {
  //         this.setTable();
  //       }
  //       // this.items2 = this.$store.getters[`${this.contentType.name}/list`];
  //       // this.items_backup = this.items2;
  //       // this.setTable();
  //       return this.$store.getters[`${this.contentType.name}/list`];

  //       // return 
  //     },
  //     set(value) {
  //       this.items2 = value;
  //       return value;
  //     }
  //   }
  // },






  // watch: {
  //   items(newVal) {
  //     this.setTable();
  //   },
  //   items_custom(newVal) {
  //     this.setTable2();
  //   }
  // },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },


  async created() {
    this.$root.$refs.Category = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    console.log('before dispatch')
    // await this.$store.dispatch(`${this.contentType.name}/all`, {
    //   id: this.category_id
    // });
    console.log('after dispatch')
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },

  mounted() {
    this.items_data2()
    this.getData('next');
  },


  methods: {
    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-courses', { params: { keyword: this.keyword, course_cat_id: this.category_id } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/course-cat-courses?course_cat_id=${this.category_id}&page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.courses;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    async items_data2() {
      let data = await this.$store.getters['getHeaderData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_header');
        data = JSON.parse(data);
      }
      this.pageNumberCategory = data?.pageNumberCategory,
        console.log("items data 2 ::::: ", data)
      this.category_name = data?.category_name
    },


    goBack() {
      let prev_url = "/course-categories/list";
      this.$store.state.pageNumberAfterAction = this.pageNumberCategory; // search &
      this.$router.push({ path: prev_url });
      // this.$router.go(-1)
    },
    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/course-status?id=${item.id}`);
        console.log(result)
        if (result.status == 200) {
          this.$swal('Success!', result.data.message, 'success')
          // await this.$store.dispatch(`course/all`);
          // await this.$store.dispatch('course/all', {
          //   id: this.category_id
          // });
          if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type": "Course",
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status_is_avilable', data);
        if (result.status == 200) {
          // await this.$store.dispatch('course/all', {
          //   id: this.category_id
          // });
          this.$swal('Success!', result.data.message, 'success')
          if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        }

      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    // setTable() {
    //   setTimeout(() => {
    //     if (this.items.length > 0) {
    //       this.data_table = $("#audios_table").DataTable();
    //     }
    //   }, 2000);
    // },
    // setTable2() {
    //   setTimeout(() => {
    //     if (this.items_custom.length > 0) {
    //       this.data_table = $("#mytable").DataTable();
    //     }
    //   }, 0);
    // },


    async filter() {
      if (this.selected_category != null) {
        this.items_custom_status = false;
        // console.log(this.selected_category);
        try {
          if (this.selected_category == "all") {
            this.items_custom = this.items_backup;
            // this.setTable();
          } else {
            let result = await api.get(`/admin/course-cat-courses?course_cat_id=${this.selected_category}`);
            this.items_custom = result.data.courses;
            if (result.data.courses.length == 0) {
              this.items_custom_status = true;
              this.empty_data_error = "Data Not Found!"
              this.data_table.destroy();
            }
            // this.setTable();
          }

        } catch (error) {
          this.error = error.response.data.message;
          // this.$toast.error(this.error);
          console.log(error);
        }
      }
    },

    async FetchAllCategories() {
      try {
        let result = await api.get(`/admin/all-course-cat`);
        // console.log(result.data.all_course_categories);
        this.all_categories = result.data.all_course_categories;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    async showShowModal(name, id) {

      await this.$store.dispatch('setHeaderData', {
        "pageNumberCourse": this.page,
        "pageNumberCategory": this.pageNumberCategory,
        "category_name": this.category_name, "course_name": name
      })
      var obj = {
        "pageNumberCourse": this.page,
        "pageNumberCategory": this.pageNumberCategory,
        "category_name": this.category_name, "course_name": name
      };
      localStorage.setItem('data_for_header', JSON.stringify(obj));

      // console.log("course id : " , id , " Course Category id :::: " , this.category_id);
      await this.$store.dispatch('setDataInEditForm', {
        "pageNumber": this.page, "type": this.contentType.name, id,
        "category_name": name,
      })
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name, id, prev_category_id: this.category_id,
        "category_name": name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      let currentUrl = window.location.pathname;
      let url_obj = {
        pageNumber: this.page,
        course_url: currentUrl,
        module_url: ""
      }
      await this.$store.dispatch('setDataInEditForm2', url_obj)
      localStorage.setItem('data_for_edit2', JSON.stringify(url_obj));

      const url = `/module/list/${id}`
      this.$router.push({ path: url });
    },

    async showEditModal(id, title, description, image, course_cat_id) {
      await this.$store.dispatch('setDataInEditForm', {
        "pageNumber": this.page,
        "type": this.contentType.name,
        "category_name": this.category_name,
        id, title, description, image, course_cat_id
      })
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        "category_name": this.category_name,
        id, title, description, image, course_cat_id
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm', {
        "type": this.contentType.name,
        course_cat_id: this.selected_category,
        course_cat_id2: this.category_id,
        "category_name": this.category_name,
      })
      var obj = {
        "type": this.contentType.name,
        course_cat_id: this.selected_category,
        course_cat_id2: this.category_id,
        "category_name": this.category_name,
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      console.log(url);
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.error(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id, title) {
      if (title.toLowerCase() == "self hypnosis step by step") {
        alert('Course Self Hypnosis Step by Step has unlock achievement functionality you can not delete it');
        return false;
      }
      try {
        let endpoint2 = "/admin/delete-course/";
        const module_name = this.contentType.name;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.category_id
              // });
              this.getData();
              this.$swal(
                'Deleted!',
                'Course has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
i th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}</style>
