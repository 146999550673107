import layout from '../layout'
export default function makeCrudRoutes({
  contentType,
  components: { FormContainer, ListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list`,
          component: ListingContainer,
          props: {
            contentType
          }
        },
        {
          path: `/${contentType.name}/:id/edit`,
          component: FormContainer,
          props: route => ({ contentType, id: route.params.id })
        }
      ]
    }
  ];
}
