import layout from '../layout'
export default function makeMtRoutes({
  contentType,
  components: { MTListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list`,
          component: MTListingContainer,
          props: {
            contentType
          }
        },
      ]
    }
  ];
}
