<template>
    <section class="validation">
        <div>
            <div class="page-header">
                <h3 class="page-title">
                    Edit Subcategory of Series
                </h3>
                <button type="button" class="btn btn-info believe-btn" @click="goBack()">
                    <i class="mdi mdi-keyboard-backspace"></i> Back</button>
                <!-- <router-link class="btn btn-primary believe-btn" :to="`/hypnosis/list/${current_category_id}`">
                    <i class="mdi mdi-keyboard-backspace"></i> Back</router-link> -->
                <!-- <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:void(0);">hypn</a></li>
                <li class="breadcrumb-item active" aria-current="page">Basic</li>
                </ol>
            </nav> -->
            </div>
            <div class="row text">
                <div class="col-md-8  m-auto">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title mb-4">Edit Audio Category</h4> -->
                            <b-form @submit.stop.prevent="onSubmit">
                                <div class="container-fluid my-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Title"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>


                                                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                    <div v-if="!$v.form.name.required">Title is required</div>
                                                    <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                        characters</div>
                                                </div>
                                                
                                            </b-form-group>
                                            <!-- </div> -->
                                        </div>
                                        <!-- <div class="col-md-12">
                                            <b-form-group id="example-input-group-1" label="Type"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.type.$model"
                                                    :state="$v.form.type.$dirty ? !$v.form.type.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>
                                            </b-form-group>
                                        </div> -->
                                    </div>
                                    <div class="container-fluid my-4">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="file-box row" @click="$refs.image.click()">
                                                    <div class="col-md-1">
                                                        <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                        <input class="ml" type="file" 
                                                            @change="onAudioImageChange" ref="image"
                                                            style="display: none" />
                                                    </div>
                                                    <div class="col-md-8">
                                                        <div class="file-text-box">
                                                            <span>Upload</span><br />
                                                            <small>Upload background image here</small>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <img v-if="image_url !== ''" :src="image_url"
                                                            class="img-thumbnail rounded-circle" alt="Image"
                                                            style="height: 65px; width: 65px; margin-top:8px" />
                                                    </div>
                                                </div>
                                                <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <b-form-group id="example-input-group-1" label="Description"
                                        label-for="example-input-1">
                                        <!-- @keydown.native="test_keydown_handler"  -->
                                        <b-form-textarea id="descriptionTextarea" name="example-input-1"
                                            v-model="$v.form.description.$model"
                                            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                                            aria-describedby="input-1-live-feedback" placeholder="Enter something..."
                                            rows="3" max-rows="6"></b-form-textarea>

                                            <div v-if="$v.form.description.$error" class="invalid-feedback">
                                            <div v-if="!$v.form.description.required">Description is required</div>
                                        </div>

                                    </b-form-group>
                                    <div class="text-right mt-3">
                                        <b-button :disabled='is_loading' type="submit" variant="success"
                                            class="btn believe-btn btn-block">
                                            <i
                                                :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                            Submit
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                            <vue-snotify></vue-snotify>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required,maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
import AudioFile from "@/components/AudioFile.vue";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioCategoryAddModal',
    mixins: [validationMixin],
    data() {
        return {
            count: 0,
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            file_duration: "",
            form: {
                id: null,
                name: null,
                type: null,
                image: "",
                description : null
            }
        }
    },
    components: {
        // simpleSnotifysuccess
        AudioFile
    },
    validations: {
        form: {
            name: {
                required,
                maxLength : maxLength(100)
            },
            // type: {
            //     required,
            // },
            // image: {
            //     required,
            // },
            description: {
                required,
            },
        }
    },

    mounted(){
        this.items()
        
    },

    methods: {
        // test_keydown_handler(event) {
        //     if (event.which === 13) {
        //         let txt = document.getElementById('descriptionTextarea');
        //         txt.value = txt.value.slice(0,txt.selectionStart) + '\r\n' + 
                // txt.value.slice(txt.selectionStart,txt.value.length);
        //     }
        // },
        
        async items() {
            let data = await this.$store.getters['getEditFormData'];
            if (data.length == 0) {
                data = localStorage.getItem('data_for_edit');
                data = JSON.parse(data);
            }
            this.form.name = data?.name;
            this.image_url = data?.image;
            // this.form.image = data?.image;
            this.form.id = data?.id;
            this.form.description = data?.description;
            this.form.type = data?.type;
        },
        closeAudioPlayer() {
            this.audioFile = "";
            this.$refs.audioFile.value = null;
        },

        goBack() {
            let prev_url = `/series-categories/list/`;
            this.$router.push({ path: prev_url });
        },

        onAudioImageChange(e) {
            // this.image = "";
            // this.image_url = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (num == 'audio') {
                types = ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/mp4', 'audio/mp3']
                if (!types.includes(file.type)) {
                    this.audioFile_err = "The audio must be a file of type: mp3, wav, ogg"
                    this.audioFile = ""
                    return false;
                }
                return true;
            } else {
                if (!types.includes(file.type)) {
                    if (num == 'image1') {
                        this.image_error = msg
                        this.form.image = "";
                    }
                    if (num == 'image2') {
                        this.image_error2 = msg
                        this.form.cover_image = "";
                    }
                    return false;
                }
                return true;
            }
        },


        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return false;
            }

            if (this.image_url != ""
            ) {
                try {
                    this.is_loading = true;
                    // const obj = this.$store.getters['getEditFormData'];
                    let endpoint = "/admin/update-series-cat"
                    const formData = new FormData();
                    formData.append("name", this.form?.name);
                    formData.append("id", this.form?.id);
        formData.append("description", this.form?.description);
        // formData.append("type", this.form?.type);
        if (this.form?.image) {
          formData.append("image", this.form?.image);
        }
        // console.log('under first if');
                    let result = await api.post(endpoint, formData);
                    if (result.status = 200) {
                        const msg = await result.data.message;
                        this.$store.dispatch('setMessage', { msg });
                        await this.$store.dispatch(`${this.form?.type}/all`);
                        let prev_url = '/series-categories/list/';
                        this.$router.push({ path: prev_url });
                        this.is_loading = false;
                    }
                } catch (error) {
                    // this.progress = 0;
                    let err = error.response.data.message;
                    this.$store.dispatch('setMessage', { err });
                    // this.$root.$refs.Category.showSnotifyError()
                    // this.$toast.error(this.error);
                }
            } else {
                // console.log('under first else');
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
            }
        },

    },




}
</script>