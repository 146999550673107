<template>
    <section class="tables">
      <div class="page-header">
        <h3 class="page-title">
          Tags
        </h3>
        <div class="btn-group" role="group" aria-label="Basic example">
          <!-- <button type="button" class="btn btn-primary believe-btn" @click="goBack()">
            <i class="mdi mdi-keyboard-backspace"></i> Back</button> -->
            <!-- @click="showAddModal()" -->
          <button  @click="showAddModal()" type="button" class="btn btn-success believe-btn">
            <i class="fa fa-plus"></i> Add Tag</button>
        </div>
        <!-- <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a style="color:#3bccb0 !important" href="javascript:void(0);" @click="goBack()">Categories</a></li>
            <li class="breadcrumb-item active" aria-current="page">Scripts</li>
          </ol>
        </nav> -->
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title" style="color:#696969 !important;">Tags</h4> -->
              <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
              <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
                <thead>
                  <tr class="frown" style="color:#303030 !important">
                    <th scope="col" style="width: 2%">#</th>
                    <th scope="col" style="width: 20%">Name</th>
                    <th scope="col" style="width: 2%">Status</th>
                    <th scope="col" style="width: 1%">Actions</th>
                  </tr>
                </thead>
                <tbody style="color:#696969 !important;">
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                    <!-- <td>
                      <img :src="item.image" alt="" />
                    </td> -->
                    <td>
                      <p class="bigText">
                        {{ item.tag }}
                      </p>
                    </td>

                    <td>
                      <toggle-button  @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                    </td>
  
                    <!-- <td>
                      <toggle-button color="rgb(0,123,255)" @change="changeSubscriptionStatus(item)"
                        :value="(item.is_paid == 1) ? true : false" />
                    </td> -->
  
                    <td>
  
                      <b-dropdown class="" right variant="info">
                        <template slot="button-content">
                          Actions
                          <!-- <i class="mdi mdi-dots-vertical"></i> -->
                        </template>
                        <a href="javascript:;"  class="" style="text-decoration:none;">
                        <b-dropdown-item @click="
                          showEditModal(
                            item.id,
                            item.tag,
                          )">
                            <!-- <box-icon name="edit" class="myicon"></box-icon> -->
                            Update Tag
                          
                        </b-dropdown-item>
                      </a>
                      <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                        <b-dropdown-item>
                            <!-- <box-icon type="solid" name="message-alt-x" class="myicon"></box-icon> -->
                            Delete Now
                        </b-dropdown-item>
                      </a>
                      </b-dropdown>
  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <!-- <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
        <AudioAddModal />
      </b-modal>
  
      <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
        <AudioEditModal />
      </b-modal> -->
  
      <vue-snotify></vue-snotify>
    </section>
  
  </template>
  <script>
//   import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
//   import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
  import "jquery/dist/jquery.min.js";
  import "bootstrap/dist/css/bootstrap.min.css";
  import "datatables.net-dt/js/dataTables.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import api from "../../config/api.js";
  import Vue from "vue";
  // import SortedTablePlugin from "vue-sorted-table";
  import $ from "jquery";
  
  export default {
    components: {
    //   AudioEditModal,
    //   AudioAddModal,
    },
  
    props: {
      contentType: {
        required: true,
        type: Object
      }
    },
  
    async created() {
    //   console.log('data dome1')
      this.$root.$refs.Audio = this;
      this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
      await this.$store.dispatch(`${this.contentType.name}/all`);
      if(this.$store.getters['getMessage']){
        let obj = this.$store.getters['getMessage'];
        this.$swal('Success!', obj.msg, 'success')
        // this.showSnotifySuccess(obj.msg)
        this.$store.dispatch('setMessage', "");
      }
    },
    data: function () {
      return {
        empty_data_error : "",
        computed_count : 0,
        category_id: null,
      };
    },
    computed: {
      items() {
        this.computed_count++;
        const audios_data = this.$store.getters[`${this.contentType.name}/list`];
        if(this.computed_count > 1 && audios_data.length == 0){
          this.empty_data_error = "Data Not Found!"
        }else{
          this.setTable();
          return audios_data;
        }
      },
    },
    mounted() {
      // window.addEventListener("keypress", function (e) {
      //   if (e.code == "Enter") {
      //     console.log('enter click');
      //     e.preventDefault()
      //   }
      // }.bind(this));
      // this.setTable();
    },
    methods: {
  
      async changeStatus(item) {
        item.status = !item.status
        try {
          let result = await api.post(`/admin/tag-status?id=${item.id}`);
          const msg = await result.data.message;
          await this.$store.dispatch(`tags/all_after_submit`);
          this.$swal('Success!', msg, 'success');
        } catch (error) {
          console.log(error);
          // this.error = error.response.data.message;
          // this.$toast.error(this.error);
        }
      },
  
      async changeSubscriptionStatus(item) {
        item.is_paid = !item.is_paid
        try {
          let type = "";
          if (this.contentType?.name != "") {
            if (this.contentType.name == "hypnosis") {
              type = "Hypnosis"
            }
            else if (this.contentType.name == "meditation") {
              type = "Meditation"
            }
            else if (this.contentType.name == "affirmation") {
              type = "Affirmation"
            }
          }
          const data = {
            "type": type,
            "id": item.id
          }
          let result = await api.post('/admin/toggle-subscription-status', data);
          const msg = await result.data.message;
          this.$swal('Success!', msg, 'success')
        } catch (error) {
          console.log(error);
          // this.error = error.response.data.message;
          // this.$toast.error(this.error);
        }
      },
  
      setTable() {
        setTimeout(() => {
          if (this.items.length > 0) {
            $("#audios_table").DataTable();
          }
        }, 2000);
      },

      async play_aud(path) {
        this.$swal({
          // icon: 'audio',
          title: "Audio",
          html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
          showCloseButton: true,
          showConfirmButton: false,
        });
      },
      goBack() {
        let prev_url = "/ebooks/list";
        // if (this.contentType.name == "hypnosis") {
        //   prev_url = "/hypnosis-categories/list";
        // }
        // else if (this.contentType.name == "meditation") {
        //   prev_url = "/meditation-categories/list";
        // }
        // else if (this.contentType.name == "affirmation") {
        //   prev_url = "/affirmation-categories/list";
        // }
        this.$router.push({ path: prev_url });
      },
      async showAddModal() {
        await this.$store.dispatch('setDataInEditForm',
          { "type": this.contentType.name })
        // this.$refs["add-modal"].show();
        const url = `/tags/add`;
        console.log(url);
        this.$router.push({ path: url });
      },
  
  
      async showEditModal(id, tag) {
        // console.log("kkk ",url);
        await this.$store.dispatch("setDataInEditForm", {
          "type": this.contentType.name, id, tag
        });

        var obj = {
          "type": this.contentType.name, id, tag
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

        const url2 = `/tags/${id}/edit`;
        this.$router.push({ path: url2 });
      },
  
      showSnotifySuccess(msg) {
        this.$snotify.success(msg, {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
      showSnotifyError(msg) {
        this.$snotify.error(msg, {
          timeout: 5000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      },
  
      showAlert(id) {
        try {
          let endpoint2 = `/admin/delete-tag/`;
          const module_name = this.contentType.name;
        //   if (module_name) {
        //     if (module_name == "hypnosis") {
        //       endpoint2 = "/admin/delete-hypno-audio/";
        //     }
        //     else if (module_name == "meditation") {
        //       endpoint2 = "/admin/delete-meditation-audio/";
        //     }
        //     else if (module_name == "affirmation") {
        //       endpoint2 = "/admin/delete-affirmation-audio/";
        //     }
        //   }
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (res) => {
            if (res.value) {
              let result = await api.delete(endpoint2 + id);
              result = await this.$store.dispatch(`${this.contentType.name}/all`);
              this.$swal(
                'Deleted!',
                'Tag has been deleted.',
                'success'
              )
            }
          })
        } catch (error) {
          console.log(error)
          // let err = error.response.data.message;
          // this.$store.dispatch('setMessage', { err });
          // this.showSnotifyError()
        }
      }
    },
  }
  </script>
  
  <style>
  .bigText {
    /* display: inline-block; */
    /* width: 180px; */
    /* white-space: nowrap; */
    /* overflow: hidden !important; */
    /* text-overflow: ellipsis; */
  }
  
  .modalDesc {
    padding: 20px 40px;
    max-height: 700px;
    overflow-y: scroll;
  }
  
  /* #audios_table .btn-secondary{
    background-color: #3bccb0 !important;
    border-color:  #3bccb0 !important;
  } */
  </style>
  