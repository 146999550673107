<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        All Series of {{ this.category_name }}
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">
        <!-- <button type="button" class="btn btn-primary believe-btn" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i> Back</button> -->
        <!-- @click="showAddModal()" -->
        <!-- <button  @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add Series</button> -->
      </div>
      <!-- <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a style="color:#3bccb0 !important" href="javascript:void(0);" @click="goBack()">Categories</a></li>
          <li class="breadcrumb-item active" aria-current="page">Scripts</li>
        </ol>
      </nav> -->
      <div class="btn-group">
        <!-- <button type="button" class="btn btn-info" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i> Back to Series Categories</button> -->
        <!-- @click="showAddModal()" -->
        <!-- <button  @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add Series</button> -->

        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search Categories"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <b-dropdown class="" right variant="success">
          <template slot="button-content">
            Add Content
            <!-- <i class="mdi mdi-dots-vertical"></i> -->
          </template>
          <b-dropdown-item @click="showAddModal('audio')">Audio</b-dropdown-item>
          <b-dropdown-item @click="showAddModal('video')">Video</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- <div class="page-header"> -->
    <!-- @click="showAddModal() -->
    <!-- <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Category
      </b-button> -->

    <!-- </div> -->

    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Scripts</h4> -->

            <div class="row portfolio-grid">
              <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
              <draggable v-else :list="items" :force-fallback="true" :scroll-sensitivity="200"
                :options="{ animation: 200 }" :element="'div'" class="col-12 drag-wrapper" @change="update2">
                <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12  card-box " v-for="item in items" :key="item.id"
                  @mouseover="check_lesson(item.cat_id)">
                  <figure class="effect-text-in" style="height: 300px;">
                    <!-- <span v-for="field in contentType.fields" :key="`${field.name}-${item.id}`"> -->
                    <!-- <h4>{{ item[field.name] }}</h4> -->
                    <img :src="item.image" alt="image" />
                    <!-- </span> -->
                    <figcaption>
                      <div>
                        <div class="btn-group" role="group" aria-label="Basic example"
                          style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                          <!-- <RouterLink class="btn btn-info"
                              :to="`/series/list/${item.id}`"><i class="mdi mdi-eye"></i>
                            </RouterLink> -->

                          <button type="button" @click="showShowModal(
                            item.id,
                            item.name,
                            item.description,
                            item.image,
                            item.cover_image,
                            item.path,
                            item.tag,
                            item.goal,
                            item.feeling,
                            item.created_at)" class="btn btn-sm btn-info"><i class="mdi mdi-eye"></i></button>

                          <button type="button" @click="showEditModal(
                            item.id,
                            item.name,
                            item.description,
                            item.image,
                            item.cover_image,
                            item.path,
                            item.artist,
                            item.tag,
                            item.goal,
                            item.feeling,
                            item.type,
                            item.cat_id
                          )" class="btn btn-sm btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                          <button v-on:click="showAlert(item.id)" type="button" class="btn btn-sm btn-danger"><i
                              class="mdi mdi-delete-forever"></i></button>
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                  <div class="effect-text-in-footer">
                    <div class="row">
                      <div class="col-12">
                        <h6 style="color:#696969 !important; text-align: center; margin-left:20px; text-transform: capitalize;
                          font-weight: bold;">{{ item.name }} ({{ item.type }})</h6>
                      </div>
                      <div class="col-12 m-auto py-3" style="display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;">
                        <div class="">
                          <span style="color:#696969 !important;">Status : </span>
                          <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                        </div>
                        <div class="">
                          <span style="color:#696969 !important;">Premium : </span>
                          <toggle-button @change="changeSubscriptionStatus(item)"
                            :value="(item.is_paid == 1) ? true : false" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </draggable>

              <!-- <b-modal ref="edit-modal" id="modalmd" title="Edit Audio Category" size="md" hide-footer>
                    <AudioCategoryEditModal />
                  </b-modal>
                  <b-modal ref="add-modal" id="modalmd" title="Add Audio Category" size="md" hide-footer>
                    <AudioCategoryAddModal />
                  </b-modal> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <!-- <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
      <AudioAddModal />
    </b-modal>

    <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
      <AudioEditModal />
    </b-modal> -->

    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import Vue from "vue";
import draggable from 'vuedraggable'
// import SortedTablePlugin from "vue-sorted-table";
import $ from "jquery";

export default {
  components: {
    AudioEditModal,
    AudioAddModal,
    draggable
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  async created() {
    console.log('data dome1')
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    await this.$store.dispatch(`${this.contentType.name}/all`, {
      id: this.category_id
    });
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {

      keywordEmptyAfterDelete: false,
      searchButtonClicked: false,
      keyword: "",
      // items_data: "",

      items2: [],
      items: [],
      selected_module2: null,
      data_table: null,
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
      category_name: null,
    };
  },

  watch: {
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
  },

  // computed: {
  //   items: {
  //     get() {
  //       if (this.keyword != "" && this.searchButtonClicked) {
  //         return this.items_data;
  //       } else {
  //         this.computed_count++;
  //         console.log(this.computed_count);
  //         const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //         if (this.computed_count > 1 && audios_data.length == 0) {
  //           this.empty_data_error = "Data Not Found!"
  //         }
  //         else if (this.computed_count > 1 && audios_data.length > 0) {
  //           this.items2 = audios_data;
  //           return audios_data;
  //         }
  //       }
  //     },
  //     set(value) {
  //       this.items_data = value;
  //     }
  //   }
  // },

  mounted() {
    this.getData();
    //   window.addEventListener("keypress", function (e) {
    //     if (e.code == "Enter") {
    //       console.log('enter click');
    //       e.preventDefault()
    //     }
    //   }.bind(this));
    //   // this.setTable();
    this.items_data();
    //   console.log("items ss ss s s s s :", this.items)
  },
  methods: {

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },
    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      this.searchButtonClicked = true;
      if (this.keyword != "") {
        api.get('/admin/livesearch-series', { params: { keyword: this.keyword, cat_id: this.category_id } })
          .then(res => {
            this.items = res.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },


    async getData() {
      this.searchButtonClicked = false;

      let result = await api.get(`/admin/series-cat-series?series_cat_id=${this.category_id}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.videos;
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },

    check_lesson(id) {
      // console.log("tr click ::: ", id);
      this.selected_module2 = id;
    },

    async update2() {
      console.log('update 2');
      console.log("selected module conten :::::: ", this.selected_module2)
      if (this.selected_module2 != null) {
        this.items.map((dt, index) => {
          dt.order = index + 1;
        })
        console.log(this.items);
        let ids = this.items.map((dt) => {
          return dt.id
        })
        const data = {
          "lesson_id": this.selected_module2,
          "ids": ids
        }
        console.log("update 2 for sorting ::: ", data);
        try {
          let res = await api.post(`/admin/update-order-series`, data);
          if (res.status == 200) {
            await this.$store.dispatch(`${this.contentType.name}/all`, {
              id: this.category_id
            });
          }
        } catch (error) {
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      }
      console.log(this.selected_module2);
    },

    async items_data() {
      let data = await this.$store.getters['getHeaderData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_header');
        data = JSON.parse(data);
      }
      this.category_name = data?.category_name
    },



    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/series-status?id=${item.id}`);
        this.$swal('Success!', result.data.message, 'success')
        // await this.$store.dispatch(`series/all`, {
        //   id: item?.cat_id
        // });
        if (this.keyword != "") {
          this.getResults()
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type": "Series",
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status', data);
        this.$swal('Success!', result.data.message, 'success')
        // await this.$store.dispatch(`series/all`, {
        //   id: item?.cat_id
        // });
        if (this.keyword != "") {
          this.getResults()
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    // setTable() {
    //   setTimeout(() => {
    //     if (this.items.length > 0) {
    //       this.data_table = $("#audios_table").DataTable();
    //     }
    //   }, 2000);
    // },
    async play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
    goBack() {
      let prev_url = "/series-categories/list";
      this.$router.push({ path: prev_url });
    },
    async showAddModal(type) {
      // console.log("category id :::: " + this.category_id);
      // this.contentType.name
      await this.$store.dispatch('setDataInEditForm',
        {
          "type": type, "form_type": type,
          "category_id": this.category_id,
          "category_name": this.category_name
        })
      var obj = {
        "type": type, "form_type": type,
        "category_id": this.category_id,
        "category_name": this.category_name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // this.$refs["add-modal"].show();
      const url = `/series/add/${this.category_id}`;
      // console.log(url);
      this.$router.push({ path: url });
    },


    // async showAddModal(type) {
    //   await this.$store.dispatch('setDataInEditForm', {
    //     "type": this.contentType.name, "form_type": type,
    //     "module_id": this.selected_module, "data_length": this.items2.length
    //   })
    //   // this.$refs['add-modal'].show()
    //   const url = `/${this.contentType.name}/add`
    //   console.log(url);
    //   this.$router.push({ path: url });
    // },


    async showEditModal(id, name, description, image, cover_image, path, artist, tags_arr, goal_arr, feeling_arr, type, cat_id) {
      // console.log('show edit modal btn click');
      // var tag = JSON.parse(tags_arr);
      // var goal = JSON.parse(goal_arr);
      // var feeling = JSON.parse(feeling_arr);
      var tag = tags_arr != null ? JSON.parse(tags_arr) : [];
      var goal = goal_arr != null ? JSON.parse(goal_arr) : [];
      var feeling = feeling_arr != null ? JSON.parse(feeling_arr) : [];

      await this.$store.dispatch("setDataInEditForm", {
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        "form_type": type,
        id, name, description, image, cover_image, path, artist, tag, goal, feeling, cat_id
      });

      var obj = {
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        "form_type": type,
        id, name, description, image, cover_image, path, artist, tag, goal, feeling, cat_id
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // this.$refs["edit-modal"].show();
      const url = `/series/${id}/edit/${this.category_id}`;
      this.$router.push({ path: url });
    },

    async showShowModal(id, name, description, image, cover_image, path, tags_arr, goal_arr, feeling_arr, date) {
      var tag = tags_arr != null ? JSON.parse(tags_arr) : [];
      var goal = goal_arr != null ? JSON.parse(goal_arr) : [];
      var feeling = feeling_arr != null ? JSON.parse(feeling_arr) : [];
      await this.$store.dispatch("setDataInEditForm", {
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id, name, description, image, cover_image, path, tag, goal, feeling, date
      });

      var obj = {
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id, name, description, image, cover_image, path, tag, goal, feeling, date
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // this.$refs["edit-modal"].show();
      const url = `/series-content/${id}/show`;
      this.$router.push({ path: url });
    },

    // showSnotifySuccess(msg) {
    //   this.$snotify.success(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },
    // showSnotifyError(msg) {
    //   this.$snotify.error(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-series/";
        const module_name = this.contentType.name;

        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // result = await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.category_id
              // });
              this.getData();
              this.$swal(
                'Deleted!',
                'Series has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    }
  },
}
</script>

<style>
.bigText {
  /* display: inline-block; */
  /* width: 180px; */
  /* white-space: nowrap; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis; */
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

.drag-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/* #audios_table .btn-secondary{
  background-color: #3bccb0 !important;
  border-color:  #3bccb0 !important;
} */
</style>
