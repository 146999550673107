import layout from '../layout'
export default function makeUserPromoRoutes({
  contentType,
  components: { UserPromoListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/promo-users/list/:promoId`,
          component: UserPromoListingContainer,
          props: {
            contentType,
          }
        },
      ]
    },
  ];
}
