<template>
    <section class="validation">
        <div>
            <div class="page-header">
                <h3 class="page-title">
                    Add Category of Hypnosis
                </h3>
                <div class="button-group">
                    <button type="button" class="btn btn-info" @click="goBack()">
                        <i class="mdi mdi-keyboard-backspace"></i> Back</button>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-7 m-auto">
                    <div class="card">
                        <div class="card-body">
                            <!-- <h4 class="card-title mb-4">Edit Audio Category</h4> -->
                            <b-form @submit.stop.prevent="onSubmit">
                                <!-- <b-form-group id="example-input-group-1" label="Category Name"
                                    label-for="example-input-1">
                                    <b-form-input id="example-input-1" name="example-input-1"
                                        v-model="$v.form.name.$model"
                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                        aria-describedby="input-1-live-feedback"></b-form-input>
                                </b-form-group> -->

                                <div class="container-fluid my-4">
                                    <div class="row">
                                        <div class="col-md-12">

                                            <!-- <div class="file-box" style="padding:10px 20px; cursor: default;"> -->
                                            <b-form-group id="example-input-group-1" label="Category Name"
                                                label-for="example-input-1">
                                                <b-form-input id="example-input-1" name="example-input-1"
                                                    v-model="$v.form.name.$model"
                                                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                    aria-describedby="input-1-live-feedback"></b-form-input>

                                                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                                                        <div v-if="!$v.form.name.required">Category name is required</div>
                                                        <div v-if="!$v.form.name.maxLength">Maximum limit 100
                                                        characters</div>
                                                    </div>
                                                    
                                            </b-form-group>
                                            <!-- </div> -->

                                        </div>
                                        <div class="col-md-12">
                                            <div class="file-box d-flex" @click="$refs.image.click()">
                                                <div class="col-md-1">
                                                    <i class="mdi mdi-cloud-upload" style="font-size:50px"></i>
                                                    <input class="ml" type="file" 
                                                        @change="onAudioImageChange" ref="image"
                                                        style="display: none" />
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="file-text-box">
                                                        <span>Upload</span><br />
                                                        <small>Upload category image here</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <img v-if="image_url !== ''" :src="image_url"
                                                        class="img-thumbnail rounded-circle" alt="Image"
                                                        style="height: 65px; width: 65px; margin-top:8px" />
                                                </div>
                                            </div>
                                            <p class="err" v-if="image_error != ''">{{ image_error }}</p>
                                        </div>
                                    </div>


                                    <div class="text-right mt-3">
                                        <b-button :disabled='is_loading' type="submit" variant="success"
                                            class="btn believe-btn btn-block">
                                            <i
                                                :class="is_loading ? 'circle-loader' : 'mdi mdi-file-check btn-icon-prepend'"></i>
                                            Submit
                                        </b-button>
                                    </div>
                                </div>
                            </b-form>
                            <vue-snotify></vue-snotify>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>
<script>
import { json } from 'body-parser';
import { validationMixin } from 'vuelidate'
import { required,maxLength } from 'vuelidate/lib/validators'
import api from "../../../config/api.js";
// import simpleSnotifysuccess from '@/components/alerts/snotify/simpleSnotifysuccess'
export default {
    name: 'AudioCategoryAddModal',
    mixins: [validationMixin],
    data() {
        return {
            msg: "",
            is_loading: false,
            image_error: "",
            image_url: "",
            form: {
                name: null,
                image: "",
            }
        }
    },
    components: {
        // simpleSnotifysuccess
    },
    validations: {
        form: {
            name: {
                required,
                maxLength: maxLength(100)
            },
            // image: {
            //     required,
            // },
        }
    },

    methods: {
        goBack() {
            let prev_url = `/hypnosis-categories/list/`;
            this.$router.push({ path: prev_url });
        },
        onAudioImageChange(e) {
            this.image = "";
            const file = e.target.files[0];
            var status = this.checkFileType(file, 'image1')
            if (status) {
                this.image_error = "";
                this.form.image = file;
                this.image_url = URL.createObjectURL(file)
            }
        },

        checkFileType(file, num) {
            var types = ['image/jpeg', 'image/png', 'image/jpg']
            var msg = "The image must be a file of type: jpeg, jpg, png";
            if (!types.includes(file.type)) {
                if (num == 'image1') {
                    this.image_error = msg
                    this.form.image = "";
                    this.image_url = "";
                }
                return false;
            }
            return true;
        },

        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError || this.image_error != "") {
                return false;
            }
            if (this.image_url != "")
            {
            try {
                this.is_loading = true;
                // const obj = this.$store.getters['getEditFormData'];
                let endpoint = "";
                var type = window.location.pathname.split('/');

                if (type.length > 0) {
                    if (type[1] == "hypnosis-categories") {
                        endpoint = "/admin/add-hypnosis-cat";
                    }
                    else if (type[1] == "meditation-categories") {
                        endpoint = "/admin/add-meditation-cat";
                    }
                    else if (type[1] == "affirmation-categories") {
                        endpoint = "/admin/add-affirmation-cat";
                    }

                    const formData = new FormData();
                    formData.append("name", this.form?.name);
                    formData.append("image", this.form?.image);
                    formData.append("type", type[1]);
                    let result = await api.post(endpoint, formData);
                    const msg = await result.data.message;
                    this.$store.dispatch('setMessage', { msg });
                    await this.$store.dispatch(`${type}/all`);
                    let prev_url = `/hypnosis-categories/list/`;
                    this.$router.push({ path: prev_url });
                    this.is_loading = false;
                }

            } catch (error) {
                // this.progress = 0;
                let err = error.response.data.message;
                this.$store.dispatch('setMessage', { err });
                this.$root.$refs.Category.showSnotifyError()
                // this.$toast.error(this.error);
            }
        } else {
                this.image_error = (this.image_url == "" && this.image_error == "") ? "Image is required" : this.image_error
        }
        },

    },




}
</script>