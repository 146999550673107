<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        All Users
      </h3>

      <div class="btn-group" role="group" aria-label="Basic example">
        <!-- <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add New User</button> -->


        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search users"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>


        <button type="button" style="font-size:14px !important;" @click="showAddModal()" class="
          btn btn-social-icon-text btn-linkedin text-light
          "><i style="font-size:13px !important" class="fa fa-user-plus"></i>Add User
        </button>

        <!-- <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a style="color:#3bccb0 !important" href="javascript:void(0);" @click="goBack()">Categories</a></li>
            <li class="breadcrumb-item active" aria-current="page">Scripts</li>
          </ol>
        </nav> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Tags</h4> -->
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table2">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col">#</th>
                  <th scope="col">Profile</th>
                  <th scope="col">User Name</th>
                  <th scope="col">User's Email</th>
                  <th scope="col">Type</th>
                  <th scope="col">User Since</th>
                  <!-- <th scope="col">Subscription Status</th> -->

                  <!-- <th scope="col">Trial Status</th> -->
                  <!-- <th scope="col">Remaining Trial Days</th> -->
                  <!-- <th scope="col">Is Expired</th> -->
                  <th scope="col">Signup Platform</th>
                  <th scope="col">Subscription Platform</th>
                  <!-- <th scope="col">Total Paid</th> -->

                  <th v-if="user_access == 1" scope="col">Status</th>
                  <th scope="col">Life Time Subscription</th>
                  <th scope="col">Reset Link</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <img
                      :src="(item.profile_image != '/images/avatar.jpg' && item?.profile_image != null && item?.profile_image != '') ? item.profile_image : baseUrl + '/Believe-Backend/images/avatar.jpg'"
                      alt="" />
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.name }}
                    </p>
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.email }}
                    </p>
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.type }}
                    </p>
                  </td>
                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.created_at) }}
                      <!-- {{ getFormattedTime(item.created_at,'ll') }} -->
                    </p>
                  </td>


                  <!-- <td class="text-center"> -->
                    <!-- <p class="text-light" style="text-transform: capitalize !important; font-size: 18px;">
                      <span :class="(item.is_subscribed) ?  
                      'badge rounded-pill  bg-success' : 'badge rounded-pill  bg-danger'">
                      {{ item.is_subscribed }}
                    </span>
                    </p> -->
                    <!-- <label style=" font-size: 14px;" :class="(item.is_subscribed) ? 'badge badge-success' : 'badge badge-danger'">
                      {{ item.is_subscribed }}</label> -->


                    <!-- <label style=" font-size: 14px;" :class="`badge badge-pill ${(item.revenuecat_subscription_id != null && item.revenuecat_subscription_id != 0) ? 'badge-soft-success' : 'badge-soft-warning'} 
                    font-size-11`">
                      {{ (item.revenuecat_subscription_id != null && item.revenuecat_subscription_id != 0) ? "Subscription used" : "Subscription not used" }}</label>
                  </td> -->


                  <!-- <td class="text-center" v-if="item.subscription_history?.status != 'canceled'">
                    {{ item.trial_data?.trial }}
                  </td>
                  <td v-else>
                  </td>

                  <td class="text-center">
                    <span v-if="item.trial_data?.trial == 'Under trial'">{{ item.trial_data?.trial_remain }}</span>
                  </td> -->

                  <!-- <td>
                    <p class="bigText">
                      {{
                        (item.revenuecat_subscription?.type == 'EXPIRATION') ? "Expired" : ""
                      }}
                    </p>
                  </td> -->

                  <td>
                    <p class="bigText">
                      {{ item?.signup_platform }}
                    </p>
                  </td>

                  <td>
                    <p class="bigText">
                      {{
                        item.revenuecat_subscription?.platform
                      }}
                    </p>
                  </td>


                  <!-- <td>
                    {{
                      item.revenuecat_subscription?.total_pay.toFixed(2)
                    }}
                  </td> -->

                  <td v-if="user_access == 1">
                    <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td>

                  <td class="text-center">
                    <!-- color="#76BE8E" -->
                    <toggle-button @change="changeSubscriptionStatusIntoLifetime(item)"
                      :value="(item.life_time == 'true') ? true : false" />
                  </td>



                  <!-- <td>
                      <toggle-button color="rgb(0,123,255)" @change="changeSubscriptionStatus(item)"
                        :value="(item.is_paid == 1) ? true : false" />
                    </td> -->

                  <td>
                    <!-- v-if="item.type != 'admin' && item.type != 'Admin'" -->
                    <button class="btn btn-outline-success"
                      @click="sendResetLinkToUser(item.email)">Send
                      <i class="mdi mdi-send" style="font-size:12px"></i></button>
                  </td>

                  <td>

                    <button class="btn btn-outline-warning" @click="showShowModal(item.id)" v-b-tooltip.html.bottom
                      title="View details">
                      <i class="fa fa-eye"></i>
                    </button>

                    <button class="btn btn-outline-danger"
                      @click="showEditModal(item.id, item.name, item.type, item.email)" v-b-tooltip.html.bottom
                      title="Edit">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button v-if="user_access == 1" class="btn btn-outline-danger" @click="showAlert(item.email)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                    <!-- <button class="btn btn-outline-primary" @click="showDiscountModal(item.id)" v-b-tooltip.html.bottom
                      title="Send Promocode">
                      <i class="fa fa-tag"></i>
                    </button> -->
                    <!-- v-if="user_email != item.email && user_email2 != item.email" -->

                    <button class="btn btn-outline-info" v-b-modal.modal-1 @click="showStreakModal(item.id)"
                      v-b-tooltip.html.bottom title="View Streaks">
                      <i class="fa fa-database"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <b-modal id="modal-1" title="User Streaks">
      <b-list-group v-for="(streak, streak_index) in user_streaks">
        <b-list-group-item>{{ getFormattedTime(streak.updated_at) }}</b-list-group-item>
      </b-list-group>
      <!-- <h1 v-else>No Streaks Available!</h1> -->
    </b-modal>
    <!--
      <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
        <AudioEditModal />
      </b-modal> -->

    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
//   import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
// import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Vue from "vue";
// import SortedTablePlugin from "vue-sorted-table";
import $ from "jquery";

export default {
  components: {
    //   AudioEditModal,
    // AudioAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  async created() {
    this.baseUrl = "https://api.believehypnosis.app";
    // window.location.origin
    console.log('created')
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    // await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      user_streaks: [],
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
      user_email2: "",
      user_access : null
    };
  },
  computed: {
    user_email() {
      this.user_email2 = localStorage.getItem('user_email');
      return this.$store.state.email;
    },
    // items() {
    //   this.computed_count++;
    //   const audios_data = this.$store.getters[`${this.contentType.name}/list`];
    //   if (this.computed_count > 1 && audios_data.length == 0) {
    //     this.empty_data_error = "Data Not Found!"
    //   }
    //   else {
    //     this.setTable();
    //   }
    //   return audios_data;
    // },

  },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  mounted() {
    this.getAccess();
    const prev_url = window.location.pathname;
    this.$store.state.prev_url = prev_url;
    // console.log('prev url setting :::: ',prev_url);
    localStorage.setItem('prev_url', prev_url);
    // console.log('mounted')
    // window.addEventListener("keypress", function (e) {
    //   if (e.code == "Enter") {
    //     console.log('enter click');
    //     e.preventDefault()
    //   }
    // }.bind(this));
    // this.setTable();
    this.getData('next');
  },
  methods: {

    /**
     * Fetches user access from store and local storage.
     **/

    getAccess(){
      const storeEmail = this.$store.state.access;
      const localStorageAccess = localStorage.getItem('access');
      console.log('storeEmail:', storeEmail);
      console.log('localStorageAccess:', localStorageAccess);
      this.user_access = storeEmail || localStorageAccess;
    },


    /**
     * Handles key press events, triggers a search if the "Enter" key is pressed.
     *
     * @param {Event} event - The key press event.
     **/

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    /**
     * Clears the search input field.
     **/

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    /**
     * Fetches search results based on the provided keyword.
     **/

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-allusers', { params: { keyword: this.keyword } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    /**
     * Fetches user data based on the current page.
     *
     * @param {string} $info - Information about the page change ("next", "previous").
     **/
    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/users?page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.all_users;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    /**
     * Shows a confirmation dialog and deletes a user account.
     *
     * @param {string} email - The email of the user to be deleted.
     **/
    showAlert(email) {
      try {
        let endpoint2 = `/delete-account-from-admin`;
        const module_name = this.contentType.name;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.post(endpoint2,{"email" : email});
            if (result.status == 200) {
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`);
              this.getData();
              this.$swal(
                'Deleted!',
                'User has been deleted.',
                'success'
              ).then(()=>{
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    },


     /**
     * Shows the edit modal for a user.
     *
     * @param {number} id - The user ID.
     * @param {string} name - The user name.
     * @param {string} type - The user type.
     * @param {string} email - The user email.
     **/

    async showEditModal(id, name, type, email) {
      if ((this.user_email == email || this.user_email2 == email) ||
        (this.user_email == email && this.user_email2 == email)) {
        const url = `/profile/edit`
        this.$router.push({ path: url });
      }
      else {
        await this.$store.dispatch("setDataInEditForm", { "pageNumber": this.page,id, name, type });
        var obj = { "pageNumber": this.page, id, name, type };
        localStorage.setItem('data_for_edit', JSON.stringify(obj));
        const url = `/user/${id}/edit`;
        this.$router.push({ path: url });
      }
    },

    // async sendResetLinkToUser(email) {
    //   const auth = getAuth();
    //   // console.log(auth);
    //   const firebase_response = await sendPasswordResetEmail(
    //     auth, email
    //   );
    //   // const backend_response = await api.post("/firebase", {
    //   //   token: firebase_response?.user?.accessToken,
    //   //   name: ""
    //   // });


    //   console.log(firebase_response);
    //   alert('link sent');
    // },


    /**
     * Sends a password reset link to a user.
     *
     * @param {string} email - The email of the user.
     **/

    async sendResetLinkToUser(email) {
      try {
        await api.post(`/admin/forgot-password`, {
          email: email,
        });
        alert('link sent');
      } catch (error) {
        alert('something went wrong, please try again!')
      }
    },


    /**
     * Shows the modal for adding a new user.
     **/
    async showAddModal() {
      // await this.$store.dispatch("setDataInEditForm", {
      //   "type": this.contentType.name, id
      // });
      // var obj = {
      //   "type": this.contentType.name, id
      // };
      // localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url2 = `/user/add`;
      this.$router.push({ path: url2 });
    },


    /**
     * Changes the subscription status of a user to lifetime.
     *
     * @param {Object} item - The user item.
     **/

    async changeSubscriptionStatusIntoLifetime(item) {
      try {
        let payload = {
          "user_id": item.id,
          // "plan_id" : 4 
        }
        let changeresult = await api.put(`/admin/lifetime-subscript`, payload);
        if (changeresult.status == 200) {
          const msg = await changeresult.data.message;
          // await this.$store.dispatch(`users/all_after_submit`);
          this.$swal('Success!', msg, 'success')
          if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        }
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },


    /**
     * Changes the status of a user.
     *
     * @param {Object} item - The user item.
     **/
    async changeStatus(item) {
      try {
        let changeresult = await api.put(`/admin/user-status`, {
          user_id: item.id,
        });
        if (changeresult.status == 200) {
          const msg = await changeresult.data.message;
          // await this.$store.dispatch(`users/all_after_submit`);
          this.$swal('Success!', msg, 'success')
          if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        }
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }



      // let endpoint = "";
      // if (this.contentType?.name != "") {
      //   if (this.contentType.name == "hypnosis") {
      //     endpoint = `/admin/hypno-status?id=${item.id}`;
      //   }
      //   else if (this.contentType.name == "meditation") {
      //     endpoint = `/admin/med-status?id=${item.id}`;
      //   }
      //   else if (this.contentType.name == "affirmation") {
      //     endpoint = `/admin/aff-status?id=${item.id}`;
      //   }
      // }
      // try {
      //   let result = await api.post(endpoint);
      //   const msg = await result.data.message;
      //   this.$swal('Success!', msg, 'success')
      // } catch (error) {
      //   console.log(error);
      // this.error = error.response.data.message;
      // this.$toast.error(this.error);
      // }
    },

    // async changeSubscriptionStatus(item) {
    //   item.is_paid = !item.is_paid
    //   try {
    //     let type = "";
    //     if (this.contentType?.name != "") {
    //       if (this.contentType.name == "hypnosis") {
    //         type = "Hypnosis"
    //       }
    //       else if (this.contentType.name == "meditation") {
    //         type = "Meditation"
    //       }
    //       else if (this.contentType.name == "affirmation") {
    //         type = "Affirmation"
    //       }
    //     }
    //     const data = {
    //       "type": type,
    //       "id": item.id
    //     }
    //     let result = await api.post('/admin/toggle-subscription-status', data);
    //     const msg = await result.data.message;
    //     this.$swal('Success!', msg, 'success')
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },


     /**
     * Sets up the DataTable for the user data table.
     **/

    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          $("#audios_table").DataTable();
        }
      }, 2000);
    },


     /**
     * Plays the audio associated with a user.
     *
     * @param {string} path - The path to the audio file.
     **/
    async play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },

     /**
     * Navigates back to the previous page.
     **/
    goBack() {
      let prev_url = "/ebooks/list";
      // if (this.contentType.name == "hypnosis") {
      //   prev_url = "/hypnosis-categories/list";
      // }
      // else if (this.contentType.name == "meditation") {
      //   prev_url = "/meditation-categories/list";
      // }
      // else if (this.contentType.name == "affirmation") {
      //   prev_url = "/affirmation-categories/list";
      // }
      this.$router.push({ path: prev_url });
    },


    /**
     * Shows a discount modal for a user.
     *
     * @param {number} id - The user ID.
     **/
    async showDiscountModal(id) {
      await this.$store.dispatch('setDataInEditForm',
        { "pageNumber": this.page,"type": this.contentType.name, id })
      var obj = {
        "pageNumber": this.page,"type": this.contentType.name, id
      };

      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url = `/user/send-promocode`;
      this.$router.push({ path: url });
    },


    /**
     * Shows a modal displaying user details.
     *
     * @param {number} id - The user ID.
     **/
    async showShowModal(id) {
      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": this.page,
        "type": this.contentType.name, id
      });

      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name, id
      };

      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      const url2 = `/user/${id}/show`;
      this.$router.push({ path: url2 });
    },

  /**
     * Shows a modal displaying streak details for a user.
     *
     * @param {number} id - The user ID.
     **/
    async showStreakModal(id) {
      try {
        let result = await api.get("/admin/streak-days/?user_id=" + id);
        if (result.status == 200) {
          console.log("streak data :::: ", result.data.streak_days);
          this.user_streaks = result?.data?.streak_days;
        }
      } catch (error) {
        console.log(error);
        // this.$toast.error(error.response.data.message);
      }

      // await this.$store.dispatch("setDataInEditForm", {
      //   "type": this.contentType.name, id
      // });

      // var obj = {
      //   "type": this.contentType.name, id
      // };

      // localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // const url2 = `/user/${id}/show`;
      // this.$router.push({ path: url2 });
    },


     /**
     * Displays a success notification using Snotify.
     *
     * @param {string} msg - The success message.
     **/
    showSnotifySuccess(msg) {
      this.$snotify.success(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },


    /**
     * Displays an error notification using Snotify.
     *
     * @param {string} msg - The error message.
     **/
    showSnotifyError(msg) {
      this.$snotify.error(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    
  },
}
</script>
  
<style>
.bigText {
  /* display: inline-block; */
  /* width: 180px; */
  /* white-space: nowrap; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis; */
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}


.modal-body {
  max-height: 300px;
  overflow-y: auto;
}

#modal-1 footer {
  display: none !important;
}

/* #audios_table .btn-secondary{
    background-color: #3bccb0 !important;
    border-color:  #3bccb0 !important;
  } */
</style>
  