import Vue from 'vue'
import Vuex from 'vuex'
import api from "../config/api.js";
import makeService from "../services/api";
Vue.use(Vuex)

export const store = new Vuex.Store({
    namespaced: true,
    //export default new Vuex.Store({
    state: {
        baseurl: 'https://virtualrealitycreators.com/Believe-Backend/api',
        isLoading: false,
        country_n: "",
        city_n: "",
        user_name: "",
        booking: "",
        id: "",
        name: "",
        email: "",
        access : "",
        token: "",
        type: "",
        image: '',
        status: '',
        tabid: "",
        user: {
            name: "",
            email: "",
            type: ""
        },
        lesson_content_data: null,
        filterLessonContentData: false,
        editFormData: [],
        headerData: [],
        editFormData2: [],
        message: null,
        prev_url: "",
        pageNumberAfterAction: "",
        page: 0,
        items: [],
        content: [],
        pagiTotalPages: 1,
        pagiPage: 1,
        totalRecords: 0,
        pagiBackButtonDisabled: false,
        pagiNextButtonDisabled: true,
        contentSearched: false,
        successMessage: null,
        info: [],
    },
    getters: {

        getContentData: (state) => {
            return state.content;
        },
        getPagiData(state) {
            const arr = {
                backButton: state.pagiBackButtonDisabled,
                nextButton: state.pagiNextButtonDisabled,
                page: state.pagiPage,
                totalPages: state.pagiTotalPages,
                total: state.totalRecords,
            };
            return arr;
        },

        getSearchedData(state) {
            const arr = {
                contentSearched: state.contentSearched
            }
            return arr;
        },

        getInfo(state) {
            return state.info;
        },

        getEditFormData: (state) => {
            return state.editFormData;
        },
        getHeaderData: (state) => {
            return state.headerData;
        },
        getEditFormData2: (state) => {
            return state.editFormData2;
        },
        getMessage: (state) => {
            return state.message;
        },
        getid(state) {
            return state.id
        },
        getname(state) {
            return state.name
        },
        getemail(state) {
            return state.email
        },

        access(state) {
            return state.access
        },

        getimage(state) {
            return state.image
        },
    },

    mutations: {

        increment(state) {
            state.pagiPage++;
        },
        decrement(state) {
            state.pagiPage--;
        },

        setContent(state, { data, pages, total_records }) {
            state.contentSearched = false;
            state.totalRecords = total_records;
            state.pagiTotalPages = pages;
            console.log('page : ', state.pagiPage);
            console.log('total_records : ', total_records);
            state.pagiBackButtonDisabled = (state.pagiPage > 1) ? false : true;
            state.pagiNextButtonDisabled = (state.pagiPage >= pages) ? true : false;
            state.content = data;
        },

        setSearchedContent(state, { data }) {
            state.contentSearched = true;
            state.content = data;
        },

        setSuccessMessage(state, message) {
            console.log('mutation setsuccess mesage : ', message);
            state.successMessage = message;
        },

        clearSuccessMessage(state) {
            state.successMessage = null;
        },

        setInfo(state, data) {
            state.info = data;
        },

        //     loader(state, isLoading) {

        //         this.state.isLoading = isLoading;
        //     }

        message: (state, message) => {
            state.message = message;
        },

        getname(state, uname) {
            this.state.name = uname;

        },
        getid(state, id) {
            this.state.id = id;
        },
        getemail(state, email) {
            this.state.email = email;
        },

        access(state, access) {
            this.state.access = access;
        },

        gettoken(state, token) {
            this.state.token = token;
        },

        getkey(state, token) {
            this.state.token = token;
            //console.log(this.state.token, "Log Token")

        },
        gettype(state, type) {
            this.state.type = type;

        },
        getimage(state, uimage) {
            this.state.image = uimage;

        },
        getstatus(state, status) {
            this.state.status = status;
        },

        gettabid(state, tabid) {
            this.state.type = tabid;

        },
        updateLessonContentData(state, data) {
            state.lesson_content_data = data;
        },
        changeFilterContentDataStatus(state) {
            state.filterLessonContentData = !state.filterLessonContentData;
        },
        // insertLessonContentData(state,data){
        //     console.log('insert');
        //     state.lesson_content_data.data.content.push(data);
        //     // state.lesson_content_data.push(data);
        //     // state.lesson_content_data = data;
        // },

        editform: (state, data) => {
            state.editFormData = data;
        },
        headerdata: (state, data) => {
            state.headerData = data;
        },
        editform2: (state, data) => {
            state.editFormData2 = data;
        },
        insertAudioCategoriesData: (state, data) => {
            state.editFormData = data;
        },
    },
    actions: {

        submitForm({ state, commit }) {
            let endpoint = state?.info?.addPageEndpoint
            let dataFull = state?.info?.formDataInsert;
            let formData = {};
            dataFull.forEach(element => {
                if (element.splitColumnData) {
                    element.value = element.value.split(',');
                }
                formData[element.objKey] = element.value
            });
            api.post(endpoint, formData)
                .then(response => {
                    setTimeout(() => {
                        const successMessage = `${state?.info?.pageName} Successfully Added`;
                        commit('setSuccessMessage', successMessage);
                    }, 1000);
                    return true;
                })
                .catch(error => {
                    console.log(error)
                });
        },

        async fetchData({ commit }, { url, keys }) {
            try {
                let result = await api.get(`${url}?page=${this.state.pagiPage}`);
                if (result?.status == 200) {
                    let filteredArray = "";
                    if (result?.data[Object.keys(result?.data)[0]].length > 0) {
                        filteredArray = result?.data[Object.keys(result?.data)[0]].map(obj => {
                            return Object.keys(obj).reduce((acc, key) => {
                                if (keys.includes(key)) {
                                    acc[key] = obj[key];
                                }
                                return acc;
                            }, {});
                        });
                    }
                    const payload = {
                        data: filteredArray,
                        pages: result?.data['total'],
                        total_records: result?.data['total_data']
                    }
                    commit('setContent', payload);
                }
            } catch (error) {
                console.log("fetchData error", error);
            }
        },


        async fetchSearchedData({ commit }, { url, keys, keyword }) {
            try {
                let result = await api.get(`${url}?keyword=${keyword}`);
                if (result?.status == 200) {
                    let filteredArray = "";
                    if (result?.data?.length > 0) {
                        filteredArray = result?.data?.map(obj => {
                            return Object.keys(obj).reduce((acc, key) => {
                                if (keys.includes(key)) {
                                    acc[key] = obj[key];
                                }
                                return acc;
                            }, {});
                        });
                    }
                    const payload = {
                        data: filteredArray,
                    }
                    commit('setSearchedContent', payload);
                }
            } catch (error) {
                console.log("fetchSearchedData error", error);
            }
        },

        async changeStatus({ commit }, { url, id }) {
            try {
                let result = await api.post(`${url}?id=${id}`);
                if (result?.status == 200) {
                    const msg = "Status has been Changed Successfully";
                    commit('setSuccessMessage', msg);
                }
            } catch (error) {
                console.log("changeStatus error", error);
            }
        },

        async changeSubscriptionStatus({ commit }, { url, type, id }) {
            try {
                const body = {
                    "type": type,
                    "id": id
                }
                let result = await api.post(url, body);
                if (result?.status == 200) {
                    const msg = "Subscription Status has been Changed Successfully";
                    commit('setSuccessMessage', msg);
                }
            } catch (error) {
                console.log("changeSubscriptionStatus error", error);
            }
        },

        async getLessonContentData({ commit }, id) {
            const response = await api.get(`/admin/lesson-content`, {
                params: { lesson_id: id },
            });
            commit('updateLessonContentData', response);
        },

        setDataInEditForm: async ({ commit }, params) => {
            commit("editform", params);
        },

        setHeaderData: async ({ commit }, params) => {
            commit("headerdata", params);
        },

        setDataInEditForm2: async ({ commit }, params) => {
            commit("editform2", params);
        },

        setMessage: async ({ commit }, params) => {
            commit("message", params);
        },


        // insertAudioCategoriesData: ({ commit }, params) => {
        //     makeService(params.endpoint)
        //     commit("insertAudioCategoriesData", params);
        // },

        insertAudioCategoriesData: async ({ commit }, params) => {
            console.log('from store insertaudioncategories data', params);
            const response = await api.post(params?.endpoint, params.FormData);
            const json = await response.data.message;
            // return json;
            console.log(json);
            commit("message", json);
        },
    },
})
