import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'
import { store } from '../Store/index.js'
import api from "../config/api.js"

Vue.use(Router)

function reverse_guard(to, from, next) {
  api.get("/admin/verify")
      .then(() => {
          if (store.state.type == 'admin') {
              next('/users');
          }
          next('/');
      })
      .catch(() => {
        next()
      });
}

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes: [
    { 
      name: "Login", 
      path: "/login", 
      component: () => import('@/pages/auth/login'),
      beforeEnter: reverse_guard 
    },
    {
      path: '/forgetpassword',
      name: 'forgetpassword',
      component: () => import('@/pages/auth/forgetpassword')
    },
    {
      path: '/codeverify',
      name: 'codeverify',
      component: () => import('@/pages/auth/codeverify')
    },
    {
      path: '/confirmpassword',
      name: 'confirmpassword',
      component: () => import('@/pages/auth/confirmpassword')
    },
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard')
        },

       
        // dynamic feeling routes start 
        {
          path: '/custom',
          name: 'custom',
          component: () => import('../components/FeelingCustom'),
        },

        {
          path: '/custom/add',
          name: 'CustomFeelingInsert',
          component: () => import('../components/DynamicForms/Add')
        },

        // dynamic feeling routes end 

        {
          path: '/customGoals',
          name: 'customGoals',
          component: () => import('../components/GoalCustom'),
        },

        {
          path: '/customGoals/add',
          name: 'customGoalInsert',
          component: () => import('../components/DynamicForms/Add')
        },

        {
          path: '/customEbooks',
          name: 'customEbooks',
          component: () => import('../components/EbooksCustom'),
        },

        {
          path: '/customEbooks/add',
          name: 'customEbookInsert',
          component: () => import('../components/DynamicForms/Add')
        },



        // {
        //   path: '/abc',
        //   name: 'abc',
        //   component: () => import('@/pages/dashboard/abc')
        // },

        // all orders
        // {
        //   path: '/all-orders',
        //   name: 'AllOrders',
        //   component: () => import('../components/Orders/OrderListingContainer')
        // },

        // order details
        {
          path: '/order-details/:id',
          name: 'OrderDetails',
          component: () => import('../components/Orders/OrderDetailsContainer')
        },


        // all search terms
        {
          path: '/all-search-terms',
          name: 'AllSearchTerms',
          component: () => import('../components/Search/SearchListingContainer')
        },

        {
          path: '/all-search-terms/:id/edit',
          name: 'EditSearchTerm',
          component: () => import('../components/modals/search-modals/EditModal')
        },


        // all course options show
        {
          path: '/all-course-modules',
          name: 'AllCourseModules',
         
          component: () => import('../components/Courses/AllListing')
        },

        // course category routes start
        {
          path: '/course-categories/add',
          name: 'CourseCategoryInsert',
         
          component: () => import('../components/modals/course-category-modals/AddModal')
        },
        {
          path: '/course-categories/:id/edit',
          name: 'ScriptCategoryEdit',
         
          component: () => import('../components/modals/course-category-modals/EditModal')
        },
        // course category routes end

        // course routes start
        {
          path: '/course/add',
          name: 'CourseInsert',
         
          component: () => import('../components/modals/course-modals/AddModal')
        },
        {
          path: '/course/:id/edit',
          name: 'CourseEdit',
         
          component: () => import('../components/modals/course-modals/EditModal')
        },
        // course routes end

        // module routes start
         {
          path: '/module/add',
          name: 'ModuleInsert',
         
          component: () => import('../components/modals/module-modals/AddModal')
        },
        {
          path: '/module/:id/edit',
          name: 'ModuleEdit',
         
          component: () => import('../components/modals/module-modals/EditModal')
        },
        // module routes end

        // content routes start
        {
          path: '/content/add',
          name: 'ContentInsert',
         
          component: () => import('../components/modals/content-modals/AddModal')
        },
        {
          path: '/content/:id/edit',
          name: 'ContentEdit',
         
          component: () => import('../components/modals/content-modals/EditModal')
        },
        // content routes end




        // series routes

        {
          path: '/series-categories/add',
          name: 'SeriesCategoryInsert',
         
          component: () => import('../components/modals/series-category-modals/AddModal')
        },

        {
          path: '/series-categories/:id/edit',
          name: 'SeriesCategoryEdit',
         
          component: () => import('../components/modals/series-category-modals/EditModal')
        },


        {
          path: '/series/add/:category_id',
          name: 'SeriesInsert',
         
          component: () => import('../components/modals/series-modals/AddModal')
        },

        {
          path: '/series/:id/edit/:category_id',
          name: 'SeriesEdit',
         
          component: () => import('../components/modals/series-modals/EditModal')
        },
        {
          path: '/series-content/:id/show',
          name: 'SeriesShow',
         
          component: () => import('../components/modals/series-modals/ShowModal')
        },






        // video routes

        {
          path: '/video-categories/add',
          name: 'VideoCategoryInsert',
         
          component: () => import('../components/modals/video-category-modals/AddModal')
        },

        {
          path: '/video-categories/:id/edit',
          name: 'VideoCategoryEdit',
         
          component: () => import('../components/modals/video-category-modals/EditModal')
        },


        {
          path: '/video/add/:category_id',
          name: 'VideoInsert',
         
          component: () => import('../components/modals/video-modals/AddModal')
        },

        {
          path: '/video/:id/edit/:category_id',
          name: 'VideoEdit',
         
          component: () => import('../components/modals/video-modals/EditModal')
        },
        {
          path: '/video-content/:id/show',
          name: 'VideoShow',
         
          component: () => import('../components/modals/video-modals/ShowModal')
        },
      



        //script routes
        {
          path: '/script-categories/add',
          name: 'ScriptCategoryInsert',
         
          component: () => import('../components/modals/script-category-modals/AddModal')
        },

        {
          path: '/script-categories/:id/edit',
          name: 'ScriptCategoryEdit',
         
          component: () => import('../components/modals/script-category-modals/EditModal')
        },


        {
          path: '/scripts/add/:category_id',
          name: 'ScriptInsert',
         
          component: () => import('../components/modals/script-modals/AddModal')
        },

        {
          path: '/scripts/:id/edit/:category_id',
          name: 'ScriptEdit',
         
          component: () => import('../components/modals/script-modals/EditModal')
        },



        // user routes

        {
          path: '/user/add',
          name: 'UserInsert',
          component: () => import('../components/modals/user-modals/AddModal')
        },

        {
          path: '/user/:id/edit',
          name: 'UserEdit',
          component: () => import('../components/modals/user-modals/EditModal')
        },



        // notifications routes 
        {
          path: '/notifications/add/',
          name: 'NotificationInsert',
         
          component: () => import('../components/modals/notification-modals/AddModal')
        },

        {
          path: '/notifications/:id/edit',
          name: 'NotificaitonsEdit',
          component: () => import('../components/modals/notification-modals/EditModal')
        },



        // ebook routes 
        {
          path: '/ebooks/add/',
          name: 'EbookInsert',
         
          component: () => import('../components/modals/ebook-modals/AddModal')
        },

        {
          path: '/ebooks/:id/edit',
          name: 'EbookEdit',
         
          component: () => import('../components/modals/ebook-modals/EditModal')
        },


        // tag routes
        {
          path: '/tags/add/',
          name: 'TagInsert',
         
          component: () => import('../components/modals/tag-modals/AddModal')
        },

        {
          path: '/tags/:id/edit',
          name: 'TagEdit',
         
          component: () => import('../components/modals/tag-modals/EditModal')
        },


        // goal routes
        {
          path: '/goals/add/',
          name: 'GoalInsert',
         
          component: () => import('../components/modals/goal-modals/AddModal')
        },

        {
          path: '/goals/:id/edit',
          name: 'GoalEdit',
         
          component: () => import('../components/modals/goal-modals/EditModal')
        },



        // event routes
        {
          path: '/events/add/',
          name: 'EventInsert',
         
          component: () => import('../components/modals/event-modals/AddModal')
        },

        {
          path: '/events/:id/edit',
          name: 'EventEdit',
         
          component: () => import('../components/modals/event-modals/EditModal')
        },
        {
          path: '/events/:id/show',
          name: 'EventShow', 
          component: () => import('../components/modals/event-modals/ShowModal')
        },




        // feeling routes
        {
          path: '/feelings/add/',
          name: 'FeelingInsert',
         
          component: () => import('../components/modals/feeling-modals/AddModal')
        },

        {
          path: '/feelings/:id/edit',
          name: 'FeelingEdit',
         
          component: () => import('../components/modals/feeling-modals/EditModal')
        },




        // user routes
        {
          path: '/user/:id/show',
          name: 'UserShow',
         
          component: () => import('../components/modals/user-modals/ShowModal')
        },

        // {
        //   path: '/user/discount/',
        //   name: 'UserInsert',
         
        //   component: () => import('../components/modals/user-modals/DiscountModal')
        // },

        {
          path: '/user/send-promocode/',
          name: 'SendPromo',
         
          component: () => import('../components/modals/user-modals/DiscountModal')
        },

        {
          path: '/send-promocodes',
          name: 'SendPromos',
          component: () => import('../components/modals/user-modals/BulkPromo')
        },


         // promocode routes
         {
          path: '/promocode/add',
          name: 'PromoInsert',
         
          component: () => import('../components/modals/promo-modals/AddModal')
        },

        {
          path: '/promocode/:id/edit',
          name: 'PromoEdit',
         
          component: () => import('../components/modals/promo-modals/EditModal')
        },


        // profile route //

        {
          path: '/profile/edit',
          name: 'ProfileEdit',
         
          component: () => import('../components/modals/ProfileEditModal')
        },


        // ---

        {
          path: '/meditation-backgrounds/add',
          name: 'MeditationBackgroundInsert',
          component: () => import('../components/modals/medi-other-content-modals/MeditationBackgroundAddModal')
        },
        {
          path: '/meditation-backgrounds/:id/edit',
          name: 'MeditationBackgroundEdit',
          component: () => import('../components/modals/medi-other-content-modals/MeditationBackgroundEditModal')
        },


        {
          path: '/meditation-tunes/add',
          name: 'MeditationRingtuneInsert',
          component: () => import('../components/modals/medi-other-content-modals/MeditationRingtuneAddModal')
        },
        {
          path: '/meditation-tunes/:id/edit',
          name: 'MeditationRingtuneEdit',
          component: () => import('../components/modals/medi-other-content-modals/MeditationRingtuneEditModal')
        },

        // audio categories routes start
        {
          path: '/hypnosis-categories/add',
          name: 'HypnosisCategoryInsert',
          component: () => import('../components/modals/audio-category-modals/AudioCategoryAddModal')
        },
        {
          path: '/hypnosis-categories/:id/edit',
          name: 'HypnosisCategoryEdit',
          component: () => import('../components/modals/audio-category-modals/AudioCategoryEditModal')
        },
        {
          path: '/meditation-categories/add',
          name: 'MeditationCategoryInsert',
          component: () => import('../components/modals/audio-category-modals/MeditationCategoryAddModal')
        },
        {
          path: '/meditation-categories/:id/edit',
          name: 'MeditationCategoryEdit',
          component: () => import('../components/modals/audio-category-modals/MeditationCategoryEditModal')
        },
        {
          path: '/affirmation-categories/add',
          name: 'AffirmationCategoryInsert',
          component: () => import('../components/modals/audio-category-modals/AffirmationCategoryAddModal')
        },
        {
          path: '/affirmation-categories/:id/edit',
          name: 'AffirmationCategoryEdit',
          component: () => import('../components/modals/audio-category-modals/AffirmationCategoryEditModal')
        },
        // audio categories routes end



        {
          path: '/hypnosis/add/:category_id',
          name: 'HypnosisAudioInsert',
          component: () => import('../components/modals/audio-modals/AudioAddModal')
        },
        {
          path: '/hypnosis/:id/edit/:category_id',
          name: 'HypnosisAudioEdit',
          component: () => import('../components/modals/audio-modals/AudioEditModal')
        },
        {
          path: '/affirmation/add/:category_id',
          name: 'AffirmationAudioInsert',
          component: () => import('../components/modals/audio-modals/AffirmationAddModal')
        },
        {
          path: '/affirmation/:id/edit/:category_id',
          name: 'AffirmationAudioEdit',
          component: () => import('../components/modals/audio-modals/AffirmationEditModal')
        },
        {
          path: '/meditation/add/:category_id',
          name: 'MeditationAudioInsert',
          component: () => import('../components/modals/audio-modals/MeditationAddModal')
        },
        {
          path: '/meditation/:id/edit/:category_id',
          name: 'MeditationAudioEdit',
          component: () => import('../components/modals/audio-modals/MeditationEditModal')
        },
        // {
        //   path: '/hypnosis/:id/edit/:category_id',
        //   name: 'HypnosisAudioEdit',
        //   component: () => import('../components/modals/audio-modals/AudioEditModal')
        // },
      ]
    },

    // {
    //   path: '/content',
    //   component: layout,
    //   children: [
    //     {
    //       path: 'hypnosis',
    //       name: 'hypnosis',
    //       component: () => import('@/components/listing-categories')
    //     },
    //     {
    //       path: 'meditation',
    //       name: 'meditation',
    //       component: () => import('@/components/listing-categories')
    //     },
    //     {
    //       path: 'affirmation',
    //       name: 'affirmation',
    //       component: () => import('@/components/listing-categories')
    //     },
    //   ]
    // },
    {
      path: '/widgets',
      component: layout,
      children: [
        {
          path: '',
          name: 'widgets',
          component: () => import('@/pages/widgets')
        }
      ]
    },
    
    {
      path: '/basic-ui',
      component: layout,
      children: [
        {
          path: 'accordions',
          name: 'accordions',
          component: () => import('@/pages/basic-ui/accordions')
        },
        {
          path: 'badges',
          name: 'badges',
          component: () => import('@/pages/basic-ui/badges')
        },
        {
          path: 'alerts',
          name: 'alerts',
          component: () => import('@/pages/basic-ui/alerts')
        },
        {
          path: 'breadcrumbs',
          name: 'breadcrumbs',
          component: () => import('@/pages/basic-ui/breadcrumbs')
        },
        {
          path: 'buttons',
          name: 'buttons',
          component: () => import('@/pages/basic-ui/buttons')
        },
        {
          path: 'dropdowns',
          name: 'dropdowns',
          component: () => import('@/pages/basic-ui/dropdowns')
        },
        {
          path: 'modals',
          name: 'modals',
          component: () => import('@/pages/basic-ui/modals')
        },
        {
          path: 'paginations',
          name: 'paginations',
          component: () => import('@/pages/basic-ui/paginations')
        },
        {
          path: 'progress',
          name: 'progress',
          component: () => import('@/pages/basic-ui/progress')
        },
        {
          path: 'tabs',
          name: 'tabs',
          component: () => import('@/pages/basic-ui/tabs')
        },
        {
          path: 'tooltips',
          name: 'tooltips',
          component: () => import('@/pages/basic-ui/tooltips')
        },
        {
          path: 'typography',
          name: 'typography',
          component: () => import('@/pages/basic-ui/typography')
        }
      ]
    },
    {
      path: '/advanced-ui',
      component: layout,
      children: [
        {
          path: 'dragula',
          name: 'dragula',
          component: () => import('@/pages/advanced-ui/dragula')
        },
        {
          path: 'carousel',
          name: 'carousel',
          component: () => import('@/pages/advanced-ui/carousel')
        },
        {
          path: 'loaders',
          name: 'loaders',
          component: () => import('@/pages/advanced-ui/loaders')
        },
        {
          path: 'slider',
          name: 'slider',
          component: () => import('@/pages/advanced-ui/slider')
        },
        {
          path: 'context-menu',
          name: 'context-menu',
          component: () => import('@/pages/advanced-ui/context-menu')
        },
        {
          path: 'clipboard',
          name: 'clipboard',
          component: () => import('@/pages/advanced-ui/clipboard')
        },
        {
          path: 'tree-view',
          name: 'tree-view',
          component: () => import('@/pages/advanced-ui/tree-view')
        }
      ]
    },
    {
      path: '/charts',
      component: layout,
      children: [
        {
          path: 'chartjs',
          name: 'chartjs',
          component: () => import('@/pages/charts/chartjs')
        },
        {
          path: 'c3',
          name: 'c3',
          component: () => import('@/pages/charts/c3')
        },
        {
          path: 'chartist',
          name: 'chartist',
          component: () => import('@/pages/charts/chartist')
        },
        {
          path: 'googleChart',
          name: 'googleChart',
          component: () => import('@/pages/charts/googleChart')
        },
        {
          path: 'justgage',
          name: 'justgage',
          component: () => import('@/pages/charts/justgage')
        },
      ]
    },
    {
      path: '/maps',
      component: layout,
      children: [
        {
          path: 'google-map',
          name: 'google-map',
          component: () => import('@/pages/maps/google-map')
        }
      ]
    },
    {
      path: '/tables',
      component: layout,
      children: [
        {
          path: 'basic-tables',
          name: 'basic-tables',
          component: () => import('@/pages/tables/basic-tables')
        },
        {
          path: 'advanced-tables',
          name: 'advanced-tables',
          component: () => import('@/pages/tables/advanced-tables')
        }
      ]
    },
    {
      path: '/auth-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/samples/auth-pages/login')
        },
        {
          path: 'login-2',
          name: 'login-2',
          component: () => import('@/pages/samples/auth-pages/login-2')
        },
        {
          path: 'multi-level-login',
          name: 'multi-level-login',
          component: () => import('@/pages/samples/auth-pages/multi-level-login')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/samples/auth-pages/register')
        },
        {
          path: 'register-2',
          name: 'register-2',
          component: () => import('@/pages/samples/auth-pages/register-2')
        },
        {
          path: 'lock-screen',
          name: 'lock-screen',
          component: () => import('@/pages/samples/auth-pages/lock-screen')
        },
      ]
    },
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/samples/error-pages/error-500')
        }
      ]
    },
    {
      path: '/general-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'landing-page',
          name: 'landing-page',
          component: () => import('@/pages/samples/general-pages/landing-page')
        }
      ]
    },
    {
      path: '/general-pages',
      component: layout,
      children: [
        {
          path: 'blank-page',
          name: 'blank-page',
          component: () => import('@/pages/samples/general-pages/blank-page')
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/pages/samples/general-pages/profile')
        },
        {
          path: 'faq-1',
          name: 'faq-1',
          component: () => import('@/pages/samples/general-pages/faq-1')
        },
        {
          path: 'faq-2',
          name: 'faq-2',
          component: () => import('@/pages/samples/general-pages/faq-2')
        },
        {
          path: 'news-grid',
          name: 'news-grid',
          component: () => import('@/pages/samples/general-pages/news-grid')
        },
        {
          path: 'timeline',
          name: 'timeline',
          component: () => import('@/pages/samples/general-pages/timeline')
        },
        {
          path: 'search-result',
          name: 'search-result',
          component: () => import('@/pages/samples/general-pages/search-result')
        },
        {
          path: 'portfolio',
          name: 'portfolio',
          component: () => import('@/pages/samples/general-pages/portfolio')
        },
        {
          path: 'user-listing',
          name: 'user-listing',
          component: () => import('@/pages/samples/general-pages/user-listing')
        },
      ]
    },
    {
      path: '/notifications',
      component: layout,
      children: [
        {
          path: '',
          name: 'notifications',
          component: () => import('@/pages/advanced-ui/notifications')
        }
      ]
    },
    {
      path: '/popups',
      component: layout,
      children: [
        {
          path: '',
          name: 'popups',
          component: () => import('@/pages/advanced-ui/popups')
        }
      ]
    },
    {
      path: '/forms',
      component: layout,
      children: [
        {
          path: 'basic-elements',
          name: 'basic-elements',
          component: () => import('@/pages/forms/basic-form-elements')
        },
        {
          path: 'advanced-elements',
          name: 'advanced-elements',
          component: () => import('@/pages/forms/advanced-form-elements')
        },
        {
          path: 'wizard',
          name: 'wizard',
          component: () => import('@/pages/forms/wizard')
        },
        {
          path: 'validation',
          name: 'validation',
          component: () => import('@/pages/forms/validation')
        }
      ]
    },
    {
      path: '/text-editors',
      component: layout,
      children: [
        {
          path: '',
          name: 'text-editors',
          component: () => import('@/pages/forms/text-editors')
        }
      ]
    },
    {
      path: '/code-editors',
      component: layout,
      children: [
        {
          path: '',
          name: 'code-editors',
          component: () => import('@/pages/forms/code-editors')
        }
      ]
    },
    {
      path: '/icons',
      component: layout,
      children: [
        {
          path: 'flag-icons',
          name: 'flag-icons',
          component: () => import('@/pages/icons/flag-icons')
        },
        {
          path: 'fontawesome',
          name: 'fontawesome',
          component: () => import('@/pages/icons/fontawesome')
        },
        {
          path: 'mdi-icons',
          name: 'mdi-icons',
          component: () => import('@/pages/icons/mdi-icons')
        },
        {
          path: 'simpleline',
          name: 'simpleline',
          component: () => import('@/pages/icons/simple-line')
        },
        {
          path: 'themify-icons',
          name: 'themify-icons',
          component: () => import('@/pages/icons/themify')
        }
      ]
    },
    {
      path: '/e-commerce',
      component: layout,
      children: [
        {
          path: 'invoice',
          name: 'invoice',
          component: () => import('@/pages/samples/e-commerce/invoice')
        },
        {
          path: 'orders',
          name: 'orders',
          component: () => import('@/pages/samples/e-commerce/orders')
        },
        {
          path: 'pricing-table',
          name: 'pricing-table',
          component: () => import('@/pages/samples/e-commerce/pricing-table')
        },
        {
          path: 'product-catalogue',
          name: 'product-catalogue',
          component: () => import('@/pages/samples/e-commerce/product-catalogue')
        },
        {
          path: 'project-list',
          name: 'project-list',
          component: () => import('@/pages/samples/e-commerce/project-list')
        }
      ]
    },
    {
      path: '/apps',
      component: layout,
      children: [
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('@/pages/apps/calendar')
        },
        {
          path: 'email',
          name: 'email',
          component: () => import('@/pages/apps/email')
        },
        {
          path: 'todo',
          name: 'todo',
          component: () => import('@/pages/apps/todo')
        },
        {
          path: 'gallery',
          name: 'gallery',
          component: () => import('@/pages/apps/gallery')
        },
        {
          path: 'tickets',
          name: 'tickets',
          component: () => import('@/pages/apps/tickets')
        },
        {
          path: 'kanban',
          name: 'kanban',
          component: () => import('@/pages/apps/kanban')
        },
        {
          path: 'chat',
          name: 'chat',
          component: () => import('@/pages/apps/chat')
        },
      ]
    },
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/samples/error-pages/error-404')
        }
      ]
    }
  ]
})

const isLoggedIn = async () => {
  return localStorage.getItem('token')
}
// const protectedRoutes = [
//   "dashboard",
// ]

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      next({ path: '/login' })
    } else {
      try{
        let result = await api.get('/admin/verify');
        if(result.status == 200){
          next();
        }
      }catch(e){
        next({ path: '/login' })
      }
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }

  // const isProtected = protectedRoutes.includes(to.name)
  // if(isProtected && !isLoggedIn()){
  //     next({
  //         path: '/login'
  //     })
  // }else next()
})
export default router
