<template>
    <b-form-group id="example-input-group-1" label="" label-for="example-input-1">
        <div class="tags-input-container">
            <!-- <input v-model="tagValue" @keyup.enter="addTag"> -->

            <!-- <b-form-input placeholder="Type goal here" id="example-input-1" name="example-input-1" v-model="tagValue"
                @keyup.enter="addTag" aria-describedby="input-1-live-feedback"></b-form-input> -->

            <!-- multi tag work start-->
            <p class="mb-2">Choose goals from pre define goals</p>
            <div class="tag_box mb-0 mt-0">
                <span v-for="(tag,index) in allTags" :key="'tag'+index">
                    <span :style="(tag.selected) ? 'background:#3bccb0;color:#fff' : ''" @click="tagSelect(tag)">{{
                    tag.name
                    }}</span>
                </span>
            </div>
            <!-- multi tag work end-->

            <b v-if="tags.length > 0">Selected Goals :</b>
            <div class="selected-tags-box">
                
                <div class="tag" v-if="typeof tags == 'string'">
                    <!-- @click="activeTag = index" -->
                    <span v-if="activeTag !== index">{{ tags }}</span>
                    <input v-else v-model="tags" v-focus :style="{'width': 1 + 'ch'}"
                        @keyup.enter="activeTag = null" @blur="activeTag = null" />
                    <span @click="removeTag(index,tags)"><i class="mdi mdi-close-circle-outline"></i></span>
                </div>
                
                <div class="tag" v-else v-for="(tag, index) in tags" :key="'tag'+index">
                    <!-- @click="activeTag = index" -->
                    <span v-if="activeTag !== index">{{ tag }}</span>
                    <input v-else v-model="tags[index]" v-focus :style="{'width': tag.length + 'ch'}"
                        @keyup.enter="activeTag = null" @blur="activeTag = null" />
                    <span @click="removeTag(index,tag)"><i class="mdi mdi-close-circle-outline"></i></span>
                </div>
            </div>
        </div>
    </b-form-group>
</template>

<script>
import api from "../config/api";
export default {
    name: "Goal",
    // props: ['oldtags'],
    data() {
        return {
            allTags: [],
            tags: [],
            tagValue: '',
            activeTag: null,
            oldtags: "",
        }
    },
    mounted() {
        console.log('il')
        // this.fetchOldTags()
        // if(this.oldtags != ""){
        this.allTagsFunc();
        // }
        if (this.oldtags != "")
            if (typeof (this.oldtags) == 'string') {
                this.tags = JSON.parse(this.oldtags);
            } else {
                this.tags = this.oldtags;
            }
    },
    methods: {
        async allTagsFunc() {
            try {
                let data = await this.$store.getters['getEditFormData'];
                if (data.length == 0) {
                    console.log('data lenght == 0')
                    data = localStorage.getItem('data_for_edit');
                    console.log("all tags func :::::: " , data)
                    data = JSON.parse(data);
                    console.log("data from tag compo ::::: if 1")
                }
                if (data?.goal != null) {
                    this.oldtags = data?.goal
                    if(typeof data?.goal == "string"){
                        this.tags.push(data?.goal)
                    }
                    else{
                        this.tags = data?.goal;
                    }
                }

                // if(data?.tag != null){
                //         console.log("data from tag compo ::::: if 2" , data?.tag)
                //     }
                    let result = await api.get(`/admin/all-goals-form`);
                    let tags = result.data.goals;
                    console.log("all goals ::::::: "  , tags);
                    tags.forEach(object => {
                        if (this.oldtags != "") {
                            if (this.oldtags.includes(object.name)) {
                                object.selected = true;
                            }
                        } else {
                            object.selected = false;
                        }
                    });
                    tags = tags.filter(x => x.status == 'Active');
                    this.allTags = tags;
                // }
            } catch (error) {
                this.error = error.response.data.message;
                this.$toast.error(this.error);
            }
        },
        addTag() {
            if (!this.tagValue == '')
                console.log(this.tagValue);
                if (this.tagValue.indexOf(',') > -1) {
                    console.log('under if');
                    const arr = this.tagValue.split(',');
                    this.tags = [...this.tags, ...arr];
                    this.$emit('updateGoal', this.tags);
                } else {
                    console.log('under else' , this.tags);
                    this.tags.push(this.tagValue);
                    if(this.tags.length > 1){
                        this.$emit('updateGoal', this.tags);    
                    }else{
                        this.$emit('updateGoal', this.tagValue);
                    }
                }
            this.tagValue = '';
        },
        tagSelect(tags) {
            this.allTags.map((tg) => {
                if (tg.id == tags.id) {
                    tg.selected = !tg.selected
                }
                return tg;
            })

            if (!this.tags.includes(tags.name)) {
                this.tags.push(tags.name);
            } else {
                this.tags = this.tags.filter(x => x != tags.name);
            }
            this.$emit('updateGoal', this.tags);
        },
        tagSelectForUpdate(tags) {
            this.allTags.map((tg) => {
                if (tg.id == tags.id) {
                    tg.selected = !tg.selected
                }
                return tg;
            })

            if (!this.tags.includes(tags.name)) {
                this.tags.push(tags.name);
            } else {
                this.tags = this.tags.filter(x => x != tags.name);
            }
            this.$emit('updateGoal', this.tags);
        },
        removeTag(index, tag) {
            this.allTags.map((tg) => {
                if (tg.name == tag) {
                    tg.selected = false
                }
                return tg;
            })
            this.tags.splice(index, 1);
            this.$emit('updateGoal', this.tags);
        },

    }
}
</script>

<style scoped>
/* multi tags work start */
.tag_box {
    background: #fff;
    border: solid 1px #dadada;
    border-radius: 8px;
    padding: 8px;
    color: #333;
    outline: none;
    width: 100%;
    height: 150px;
    overflow-y: auto;
    margin: 15px 0;
    word-break: break-word;
}

.tags-input-container>input {
    background: #fff;
    border: solid 1px #dadada;
    border-radius: 8px;
    padding: 8px 16px;
    color: #333;
    outline: none;
    width: 100%;
    margin: 15px 0;
}


.tag_box span {
    display: inline-block;
}

.tag_box span span {
    border: 1px solid #D3D3D3;
    padding: 4px 10px;
    border-radius: 9px;
    margin: 10px 2px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #808080;
}

.tag {
    display: inline-block;
    padding: 10px 15px;
}

.selected-tags-box {
    /* border:1px solid black; */
    max-height: 100px;
    overflow-y: scroll;
}

/* multi tags work end */
</style>