<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        All Scripts of {{ this.category_name }}
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">

        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search scripts" v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add Script</button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 2%">#</th>
                  <th scope="col" style="width: 40%">Name</th>
                  <th scope="col" style="width: 5%">Status</th>
                  <th scope="col" style="width: 5%">Is Paid</th>
                  <th scope="col" style="width: 5%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <p class="bigText">
                      {{ item.name }}
                    </p>
                  </td>

                  <td>
                    <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td>

                  <td>
                    <toggle-button @change="changeSubscriptionStatus(item)"
                      :value="(item.is_paid == 1) ? true : false" />
                  </td>

                  <td>

                    <button class="btn btn-warning" 
                    @click="showEditModal(
                        item.id,
                        item.name,
                        item.author,
                        item.description,
                        item.script_id,
                        item.text,
                        item.image,
                        item.tag,
                        item.goal,
                        item.feeling
                      )">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <vue-snotify></vue-snotify>
  </section>

</template>
<script>
import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import Vue from "vue";
import $ from "jquery";

export default {
  components: {
    AudioEditModal,
    AudioAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  async created() {
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    console.log("before dispatch");
    // await this.$store.dispatch(`${this.contentType.name}/all`, {
    //   id: this.category_id
    // });
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data : 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work
      
      data_table: null,
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
      category_name: null,
    };
  },
  // computed: {
  //   items() {
  //     this.computed_count++;
  //     const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //     if (this.computed_count > 1 && audios_data.length == 0) {
  //       this.empty_data_error = "Data Not Found!"
  //     }
  //     else {
  //       this.setTable();
  //     }
  //     if (audios_data.length > 0) {
  //       console.log("before return computed data");
  //       console.log(audios_data.length);
  //       return audios_data;
  //     }
  //   }
  // },
  mounted() {
    this.items_data();
    const module_name = this.contentType.name;
    if (module_name) {
        this.baseUrl = "admin/script-chapters?script_id"
        this.searchedUrl = "/admin/livesearch-chapter"
    }
    this.getData('next');
  },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if(this.items.length > 0){
          this.empty_data_error = "";
        }
      } else if(after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },
  
  methods: {

    handleKeyPress(event){
      if (event.key == "Enter" && this.keyword != "") {
          this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get(this.searchedUrl, { params: { keyword: this.keyword, cat_id: this.category_id } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            }else{
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`${this.baseUrl}=${this.category_id}&page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.chapters;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },

    async items_data() {
      let data = await this.$store.getters['getHeaderData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_header');
        data = JSON.parse(data);
      }
      this.category_name = data?.category_name
    },

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/chapter-status?id=${item.id}`);
        this.$swal('Success!', result.data.message, 'success')
        if(this.keyword != ""){
          this.getResults()
        }else{
          this.getData();
        }
        // await this.$store.dispatch(`scripts/all_after_submit`, {
        //   id: item?.script_id
        // });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type": "Chapter",
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status_is_avilable', data);
        this.$swal('Success!', result.data.message, 'success')
        if(this.keyword != ""){
          this.getResults()
        }else{
          this.getData();
        }
        // await this.$store.dispatch(`scripts/all_after_submit`, {
        //   id: item?.script_id
        // });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          this.data_table = $("#audios_table").DataTable();
        }
      }, 2000);
    },
    async play_aud(path) {
      this.$swal({
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
    goBack() {
      let prev_url = "/script-categories/list";
      this.$router.push({ path: prev_url });
    },
    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm',
        { "type": this.contentType.name, "category_id": this.category_id, "category_name": this.category_name })
      localStorage.setItem('data_for_edit', JSON.stringify({
        "type": this.contentType.name, "category_id": this.category_id,
        "category_name": this.category_name, tag: [], goal: [], feeling: []
      }))
      const url = `/scripts/add/${this.category_id}`;
      this.$router.push({ path: url });
    },


    async showEditModal(id, name, author, description, script_id, text, image, tags_arr, goal_arr, feeling_arr) {
      var tag = tags_arr != null ? JSON.parse(tags_arr) : [];
      var goal = goal_arr != null ? JSON.parse(goal_arr) : [];
      var feeling = feeling_arr != null ? JSON.parse(feeling_arr) : [];

      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": this.page,
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id, name, author, description, script_id, text, image, tag, goal, feeling
      });

      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id, name, author, description, script_id, text, image, tag, goal, feeling
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      const url = `/scripts/${id}/edit/${this.category_id}`;
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      this.$snotify.success(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      this.$snotify.error(msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-chapter/";
        const module_name = this.contentType.name;
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.category_id
              // });
              this.getData();
              this.$swal(
                'Deleted!',
                'Script has been deleted.',
                'success'
              ).then(()=>{
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    }
  },
}
</script>

<style>
.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}
</style>
