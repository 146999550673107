// import layout from '../layout'
// export default function makeCourseRoutes({
//   contentType,
//   components: { CourseListingContainer }
// }) {

//   return [
//     {
//       path: '/',
//       component: layout,
//       meta: {
//         requiresAuth: true
//       },
//       children: [
//         {
//           path: `/${contentType.name}/list/:categoryId`,
//           component: CourseListingContainer,
//           props: {
//             contentType
//           }
//         }
//       ]
//     }
//   ];
// }


import layout from '../layout'
export default function makeCourseRoutes({
  contentType,
  components: { CourseListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list/:categoryId`,
          component: CourseListingContainer,
          props: {
            contentType,
          }
        },
      ]
    },
  ];
}
