<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        {{ contentType.label }} Categories
      </h3>

      <div class="btn-group">
      <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search Categories" v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

      <!-- @click="showAddModal() -->
      <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Category
      </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="items.length > 0">
            <div class="row">
              <div class="col-12">
                <div class="row portfolio-grid">
                  <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" v-for="item in items" :key="item.id">
                    <figure class="effect-text-in" style="height: 300px;">
                      <!-- <span v-for="field in contentType.fields" :key="`${field.name}-${item.id}`"> -->
                      <!-- <h4>{{ item[field.name] }}</h4> -->
                      <img :src="item.image" alt="image" />
                      <!-- </span> -->
                      <figcaption>
                        <div>
                          <div class="btn-group" role="group" aria-label="Basic example"
                            style="position: absolute; bottom: 10px; left: 0; padding: 10px; margin-bottom: 0; width: 100%;">
                            <!-- <RouterLink class="btn btn-sm btn-info" :to="`/scripts/list/${item.id}`"><i
                                class="mdi mdi-eye"></i>
                            </RouterLink> -->

                            <button type="button" @click="showShowModal(item.name, item.id)" class="btn btn-sm btn-info"><i
                                class="mdi mdi-eye"></i></button>

                            <!-- <RouterLink v-if="index == 0" class="btn btn-info"
                              :to="`/${contentType.label2}/list/${item.id}`"><i class="mdi mdi-eye"></i>
                            </RouterLink> -->
                            <!-- @click="showEditModal(item.id,item.name,item.image)" -->
                            <button type="button"
                              @click="showEditModal(item.id, item.name, item.description, item.type, item.image)"
                              class="btn btn-sm btn-warning"><i class="mdi mdi-lead-pencil"></i></button>
                            <button v-on:click="showAlert(item.id)" type="button" class="btn btn-sm btn-danger"><i
                                class="mdi mdi-delete-forever"></i></button>
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                    <div class="effect-text-in-footer">
                      <!-- <h5 style="color:#696969 !important;">{{ item.name }}</h5> -->
                      <div class="row">
                        <div class="col-md-7">
                          <h6 style="color:#696969 !important; text-align: left; margin-left:20px;"
                          v-b-tooltip.html.bottom :title=item.name
                          >{{ item.name }}</h6>
                        </div>
                        <div class="col-md-4 pl-0 text-right">
                          <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <b-modal ref="edit-modal" id="modalmd" title="Edit Audio Category" size="md" hide-footer>
                    <AudioCategoryEditModal />
                  </b-modal>
                  <b-modal ref="add-modal" id="modalmd" title="Add Audio Category" size="md" hide-footer>
                    <AudioCategoryAddModal />
                  </b-modal> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" v-else>
                    <h1>No Record Found</h1>
                  </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      searchButtonClicked: false,
      keyword: "",
      keywordEmptyAfterDelete: false,
      items_data: "",
    }
  },

  watch: {
    keyword(after, before) {
      if (after != "") this.searchButtonClicked = false;
      // this.getResults();
    }
  },

  computed: {
    items: {
      get() {
        if (this.keyword != "" && this.searchButtonClicked) {
          return this.items_data;
        } else {
          console.log('get');
          console.log(this.$store.getters[`${this.contentType.name}/list`]);
          return this.$store.getters[`${this.contentType.name}/list`];
        }
      },
      set(value) {
        this.items_data = value;
      }
    }
  },
  async created() {
    this.$root.$refs.Category = this;
    await this.$store.dispatch(`${this.contentType.name}/all`);
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  methods: {

    handleKeyPress(event){
      if (event.key == "Enter" && this.keyword != "") {
          this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      this.searchButtonClicked = true;
      if (this.keyword != "") {
            api.get('/admin/livesearch-script', { params: { keyword: this.keyword } })
                .then(res => this.items = res.data)
                .catch(error => console.log("getResults : " , error));
            // console.log(this.items);
      }
    },

    async showShowModal(name, id) {
      let url = `/scripts/list/${id}`;
      await this.$store.dispatch('setHeaderData', { "category_name": name })
      var obj = { "category_name": name };
      localStorage.setItem('data_for_header', JSON.stringify(obj));
      this.$router.push({ path: url });
    },

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/script-status?id=${item.id}`);
        await this.$store.dispatch(`script-categories/all`);
        this.$swal('Success!', result.data.message, 'success')
        if(this.keyword != ""){
          this.getResults()
        }
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async showEditModal(id, name, description, type, image) {
      await this.$store.dispatch('setDataInEditForm', {
        "type": this.contentType.name,
        id, name, description, type, image
      })
      var obj = {
        "type": this.contentType.name,
        id, name, description, type, image
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm', { "type": this.contentType.name })
      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      console.log(url);
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.error(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-script/";
        const module_name = this.contentType.name;
        // if (module_name) {
        //   if (module_name == "hypnosis-categories") {
        //     endpoint2 = "/admin/delete-hypnosis-cat/";
        //   }
        //   else if (module_name == "meditation-categories") {
        //     endpoint2 = "/admin/delete-meditation-cat/";
        //   }
        //   else if (module_name == "affirmation-categories") {
        //     endpoint2 = "/admin/delete-affirmation-cat/";
        //   }
        // }
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            result = await this.$store.dispatch(`${this.contentType.name}/all`);
            this.$swal(
              'Deleted!',
              'Category has been deleted.',
              'success'
            ).then(()=>{
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
i th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}
</style>
