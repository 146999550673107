<template>
  <section class="tables">

    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        All Audios of {{ this.category_name }}
        <!-- {{ this.contentType.label }} -->
      </h3>
      <div class="btn btn-group">
        <!-- search & pagination work -->
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search audios" v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>
        <!-- search & pagination work -->
        <button @click="showAddModal()" type="button" class="btn btn-success believe-btn">
          <i class="fa fa-plus"></i> Add Audio</button>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title" style="color:#696969 !important;">Audios</h4> -->
            <h1 v-if="empty_data_error != ''">{{ empty_data_error }}</h1>
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table10">
              <thead style="width: 100%">
                <tr class="frown" style="color:#303030 !important">
                  <th scope="col" style="width: 2%">#</th>
                  <th scope="col" style="width: 2%">Image</th>
                  <th scope="col" style="width: 10%">Audio's Name</th>
                  <!-- <th scope="col" style="width: 50%">Description</th> -->
                  <th scope="col" style="width: 2%">Created Date</th>
                  <th scope="col" style="width: 2%">Audio File</th>
                  <th scope="col" style="width: 2%">Status</th>
                  <th scope="col" style="width: 2%">Is Paid</th>
                  <th scope="col" style="width: 2%">Actions</th>
                </tr>
              </thead>
              <tbody style="color:#696969 !important;">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <td>
                    <img :src="item.image" alt="" />
                  </td>
                  <td>
                    <p class="bigText">
                      {{ item.name }}
                    </p>
                  </td>

                  <!-- <td>
                    <p class="bigText">
                      {{ item.description }}
                    </p>
                  </td> -->

                  <td>
                    <p class="bigText">
                      {{ getFormattedTime(item.created_at) }}
                      <!-- {{ item.created_at | moment('llll') }} -->
                      <!-- {{ moment().tz(item.created_at,'America/Phoenix').format('h:mm:s a') }} -->
                    </p>
                  </td>

                  <td>
                    <a href="javascript:;" @click="play_aud(item.path)"
                      class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                      <i class="mdi mdi-play"></i>
                    </a>
                  </td>

                  <td>
                    <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td>

                  <td>
                    <toggle-button @change="changeSubscriptionStatus(item)" :value="(item.is_paid == 1) ? true : false" />
                  </td>

                  <td>

                    <!-- <b-dropdown class="" right variant="info">
                      <template slot="button-content">
                        Actions
                      </template>
                      <a href="javascript:;" class="" style="text-decoration:none;">
                        <b-dropdown-item @click="
                        showEditModal(
                          item.id,
                          item.name,
                          item.path,
                          item.description,
                          item.cat_id,
                          item.duration,
                          item.image,
                          item.cover_image,
                          item.artist,
                          item.tag
                        )">
                          Update Audio
                        </b-dropdown-item>
                      </a>
                      <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                        <b-dropdown-item>
                          Delete Now
                        </b-dropdown-item>
                      </a>
                    </b-dropdown> -->


                    <button class="btn btn-warning" @click="showEditModal(
                      item.id,
                      item.name,
                      item.path,
                      item.description,
                      item.cat_id,
                      item.duration,
                      item.image,
                      item.cover_image,
                      item.artist,
                      item.tag,
                      item.goal,
                      item.feeling
                    )">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                  </td>
                </tr>
              </tbody>
            </table>
            <!-- search & pagination work -->
            <div v-if="!searchButtonClicked &&  empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
                <!-- <input type="number" min="1" class="pageInfoInput" v-model="page" />  -->
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div>
            <!-- search & pagination work -->
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <b-modal ref="add-modal" id="modalmd" title="Add Audio" size="lg" hide-footer>
      <AudioAddModal />
    </b-modal>

    <b-modal ref="edit-modal" id="modalmd" title="Edit Audio" size="lg" hide-footer>
      <AudioEditModal />
    </b-modal>

    <vue-snotify></vue-snotify>
  </section>
</template>
<script>
import AudioEditModal from "../modals/audio-modals/AudioEditModal.vue";
import AudioAddModal from "../modals/audio-modals/AudioAddModal.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import api from "../../config/api.js";
import Vue from "vue";
// import SortedTablePlugin from "vue-sorted-table";
import $ from "jquery";

export default {
  components: {
    AudioEditModal,
    AudioAddModal,
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  async created() {
    // console.log('data dome1')
    this.$root.$refs.Audio = this;
    this.category_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    // let url_arr = window.location.href.split('/');
    // this.category_name = url_arr[url_arr.length - 2].replace('%20',' ')
    // await this.$store.dispatch(`${this.contentType.name}/all`, {
    //   id: this.category_id,
    // });
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  data: function () {
    return {
      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      audios: [],
      empty_data_error: "",
      computed_count: 0,
      category_id: null,
      items: [], // search & pagination work
      items_total: 0, // search & pagination work
      total_data : 0, // search & pagination work
      category_name: null,
      data_table: null,
      keywordEmptyAfterDelete: false, // search & pagination work
    };
  },

  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if(this.items.length > 0){
          this.empty_data_error = "";
        }
      } else if(after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  // computed: {
    //   items() {
  //     console.log('computed start');
  //     this.computed_count++;
  //     const audios_data = this.$store.getters[`${this.contentType.name}/list`];
  //     if (this.computed_count > 1 && audios_data.length == 0) {
  //       this.empty_data_error = "Data Not Found!"
  //     }
  //     else {
  //       this.setTable();
  //     }
  //     return audios_data;
  //   }
  // },

  mounted() {
    // console.log('moment : ' + moment().tz('America/Phoenix').format('h:mm:s a'));
    // document.getElementById('searchBtn').addEventListener("keypress", function (e) {
    //   console.log('clicked');
    //   if (e.code == "Enter") {
    //     if(this.keyword != ""){
    //       this.getResults()
    //     }
    //     // console.log('enter click');
    //     // e.preventDefault()
    //   }
    // }.bind(this));
    // this.setTable();
    this.items_data();

    // search & pagination work
    const module_name = this.contentType.name;
    if (module_name) {
      if (module_name == "hypnosis") {
        this.baseUrl = "admin/hypno-cat-audios?hypno_cat_id"
        this.searchedUrl = "/admin/livesearch-hypno"
      } else if (module_name == "meditation") {
        this.baseUrl = "admin/meditation-cat-audios?meditation_cat_id"
        this.searchedUrl = "/admin/livesearch-med"
      } else if (module_name == "affirmation") {
        this.baseUrl = "admin/affirmation-cat-audios?affirmation_cat_id"
        this.searchedUrl = "/admin/livesearch-aff"
      }
    }
    this.getData('next');
    // search & pagination work
  },


  methods: {
    // search & pagination work
    handleKeyPress(event){
      if (event.key == "Enter" && this.keyword != "") {
          this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get(this.searchedUrl, { params: { keyword: this.keyword, cat_id: this.category_id } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            }else{
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`${this.baseUrl}=${this.category_id}&page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.audios;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },
    // search & pagination work

    // async getAllData() {
    //   let result = await api.get(`admin/hypno-cat-audios?hypno_cat_id=${this.category_id}&page=${this.page}`);
    //   if (result.status == 200) {
    //     console.log("new audios data :::: " , result.data);
    // this.allData = result?.data;
    // this.showChart = true;
    // const sub_data_total = this.allData.cancelations_today_total + this.allData.subscriptions_today_total;
    // this.cancelations_percent = this.allData.cancelations_today_total / sub_data_total * 100;
    // this.subscriptions_percent = this.allData.subscriptions_today_total / sub_data_total * 100;

    // console.log(this.allData);
    // console.log("total : " , sub_data_total);
    // console.log("can : " , this.allData.cancelations_today_total);
    // console.log("sub : " , this.allData.subscriptions_today_total);
    // console.log('percent cancelations : ' + this.cancelations_percent)
    // console.log('percent subscription  : ' + this.subscriptions_percent)

    //   }
    // },

    async items_data() {
      let data = await this.$store.getters['getHeaderData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_header');
        data = JSON.parse(data);
      }
      this.category_name = data?.category_name
    },

    // addPage(){
    //   console.log(this.category_id);
    //   const url = this.contentType.name+'/'+this.category_id+'/add';
    //   console.log(url);
    //   this.$router.push({ path: url });
    // },
    async changeStatus(item) {
      item.status = !item.status
      let endpoint = "";
      if (this.contentType?.name != "") {
        if (this.contentType.name == "hypnosis") {
          endpoint = `/admin/hypno-status?id=${item.id}`;
        }
        else if (this.contentType.name == "meditation") {
          endpoint = `/admin/med-status?id=${item.id}`;
        }
        else if (this.contentType.name == "affirmation") {
          endpoint = `/admin/aff-status?id=${item.id}`;
        }
      }
      try {
        let result = await api.post(endpoint);
        const msg = await result.data.message;
        this.$swal('Success!', msg, 'success')
        // search & pagination work
        if(this.keyword != ""){
          this.getResults()
        }else{
          this.getData();
        }
        // search & pagination work
        // await this.$store.dispatch(`${this.contentType?.name}/all_after_submit`, {
        //   id: item?.cat_id
        // });
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },

    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        let type = "";
        if (this.contentType?.name != "") {
          if (this.contentType.name == "hypnosis") {
            type = "Hypnosis"
          }
          else if (this.contentType.name == "meditation") {
            type = "Meditation"
          }
          else if (this.contentType.name == "affirmation") {
            type = "Affirmation"
          }
        }
        const data = {
          "type": type,
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status', data);
        const msg = await result.data.message;
        this.$swal('Success!', msg, 'success')
        // await this.$store.dispatch(`${this.contentType?.name}/all_after_submit`, {
        //   id: item?.cat_id
        // });
        // search & pagination work
        if(this.keyword != ""){
          this.getResults()
        }else{
          this.getData();
        }
        // search & pagination work
      } catch (error) {
        console.log(error);
        // this.error = error.response.data.message;
        // this.$toast.error(this.error);
      }
    },

    setTable() {
      setTimeout(() => {
        if (this.items.length > 0) {
          this.data_table = $("#audios_table").DataTable();
        }
      }, 2000);
    },
    async play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },
    goBack() {
      let prev_url = "";
      if (this.contentType.name == "hypnosis") {
        prev_url = "/hypnosis-categories/list";
      }
      else if (this.contentType.name == "meditation") {
        prev_url = "/meditation-categories/list";
      }
      else if (this.contentType.name == "affirmation") {
        prev_url = "/affirmation-categories/list";
      }
      this.$router.push({ path: prev_url });
    },
    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm',
        {
          "type": this.contentType.name, "category_id": this.category_id,
          "category_name": this.category_name
        })
      localStorage.setItem('data_for_edit', JSON.stringify({
        "type": this.contentType.name, "category_id": this.category_id,
        "category_name": this.category_name, tag: [], goal: [], feeling: []
      }))
      // this.$refs["add-modal"].show();
      const url = `/${this.contentType.name}/add/${this.category_id}`
      this.$router.push({ path: url });
    },
    async showEditModal(id, name, path, description, cat_id, duration, image, cover_image, artist, tag, goal_arr, feeling_arr) {
      // var goal = JSON.parse(goal_arr);
      // var feeling = JSON.parse(feeling_arr);
      var goal = goal_arr != null ? JSON.parse(goal_arr) : [];
      var feeling = feeling_arr != null ? JSON.parse(feeling_arr) : [];
      await this.$store.dispatch("setDataInEditForm", {
        // search & pagination work
        "pageNumber": this.page,
        // search & pagination work
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id,
        name,
        path,
        description,
        cat_id,
        duration,
        image,
        cover_image,
        artist,
        tag, goal, feeling
      });

      var obj = {
        // search & pagination work
        "pageNumber": this.page,
        // search & pagination work
        "type": this.contentType.name,
        "category_id": this.category_id,
        "category_name": this.category_name,
        id,
        name,
        path,
        description,
        cat_id,
        duration,
        image,
        cover_image,
        artist,
        tag, goal, feeling
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // this.$refs["edit-modal"].show();
      const url = `/${this.contentType.name}/${id}/edit/${this.category_id}`
      this.$router.push({ path: url });
    },

    // showSnotifySuccess(msg) {
    //   this.$snotify.success(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },
    // showSnotifyError(msg) {
    //   this.$snotify.error(msg, {
    //     timeout: 5000,
    //     showProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //   });
    // },

    showAlert(id) {
      try {
        let endpoint2 = "";
        const module_name = this.contentType.name;
        if (module_name) {
          if (module_name == "hypnosis") {
            endpoint2 = "/admin/delete-hypno-audio/";
          }
          else if (module_name == "meditation") {
            endpoint2 = "/admin/delete-meditation-audio/";
          }
          else if (module_name == "affirmation") {
            endpoint2 = "/admin/delete-affirmation-audio/";
          }
        }
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              this.getData();
              // result = await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.category_id
              // });
              this.$swal(
                'Deleted!',
                'Audio has been deleted.',
                'success'
                // search & pagination work
              ).then(()=>{
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
              // search & pagination work
            }
          }
        })
      } catch (error) {
        console.log(error)
        // let err = error.response.data.message;
        // this.$store.dispatch('setMessage', { err });
        // this.showSnotifyError()
      }
    }
  },
}
</script>

<style>
.bigText {
  /* display: inline-block; */
  /* width: 180px; */
  /* white-space: nowrap; */
  /* overflow: hidden !important; */
  /* text-overflow: ellipsis; */
}

.modalDesc {
  padding: 20px 40px;
  max-height: 700px;
  overflow-y: scroll;
}

/* #audios_table .btn-secondary{
  background-color: #3bccb0 !important;
  border-color:  #3bccb0 !important;
} */
</style>
