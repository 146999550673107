<template>
  <section class="portfolio">
    <div class="page-header">
      <h3 class="page-title" style="color:#696969 !important;">
        <button type="button" class="btn btn-outline-success btn-sm mr-2" @click="goBack()">
          <i class="mdi mdi-keyboard-backspace"></i></button>
        <!-- {{ contentType.label }} -->
        <!-- All Modules -->
        All Modules of {{ this.course_name }}
      </h3>
      <div class="btn-group" role="group" aria-label="Basic example">
        <b-form-input type="text" class="searchInput" @keypress="handleKeyPress" placeholder="Search modules"
          v-model="keyword"></b-form-input>
        <b-button @click="getResults()" class="btn btn-primary"><i class="fa fa-search"></i>
        </b-button>
        <b-button @click="clearSearchInput()" class="mr-4 btn btn-danger"><i class="fa fa-trash-o"></i>
        </b-button>

        <!-- @click="showAddModal() -->
        <b-button @click="showAddModal()" class="btn btn-success btn-fw"><i class="fa fa-plus"></i> Add Module
        </b-button>
      </div>
    </div>
    <div class="row">
      <!-- <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row mb-4">
              <div class="col-md-5">
                <b-form-select v-model="selected_category" value-field="id" text-field="title" @change="changeCategory">
                  <option :value="null" selected disabled>-- Please select category --</option>
                  <option :disabled="(selected_category == null || selected_category == 'all') ? true : false" value="all">From All Categoires</option>
                  <option v-for="option in all_categories" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>
              <div class="col-md-5">
                <b-form-select v-model="selected_course" value-field="id" text-field="title" @change="changeCourse">
                  <option :value="null" selected disabled>-- Please select course --</option>
                  <option :disabled="(selected_course == null || selected_course == 'all') ? true : false" value="all">From All Courses</option>
                  <option v-for="option in all_courses" :value="option.id">
                    {{ option.title }}
                  </option>
                </b-form-select>
              </div>

              <div class="col-md-2">
                <button class="btn btn-success btn-block" @click="filter">Filter</button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <div class="error_div" v-if="empty_data_error != '' && items_custom_status == true"></div> -->
            <div class="error_div" v-if="empty_data_error != ''">
              <h1>{{ empty_data_error }}</h1>
            </div>
            <!-- <div class="table_div" v-if="items_custom_status == false"> -->
            <table v-else class="table table-hover table-striped table-responsive" id="audios_table">
              <thead>
                <tr class="frown" style="color:#303030 !important">
                  <th v-if="draggableStatus" scope="col" style="width: 1%"></th>
                  <th scope="col" style="width: 1%">#</th>
                  <!-- <th scope="col" style="width: 10%">Image</th> -->
                  <th scope="col" style="width: 10%">Name</th>
                  <th scope="col" style="width: 1%">Created Date</th>
                  <!-- <th scope="col" style="width: 50%">Description</th> -->
                  <!-- <th scope="col" style="width: 10%">Audio File</th> -->
                  <th scope="col" style="width: 1%">Status</th>
                  <th scope="col" style="width: 1%">Is Paid</th>
                  <th scope="col" style="width: 1%">Actions</th>
                </tr>
              </thead>
              <!-- <tbody style="color:#696969 !important;"> -->

              <draggable :sort="draggableStatus" :list="items" :force-fallback="true" :scroll-sensitivity="200" :options="{ animation: 200 }"
                :element="'tbody'" @change="update2">

                <tr v-for="(item, index) in items" :key="item.id" @mouseover="check_lesson(item.course_id)">
                  <td v-if="draggableStatus"><i class="mdi mdi-drag-vertical grab-cursor" style="font-size:24px;"></i></td>
                  <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                  <!-- <td>
                    <img :src="item.image" alt="" />
                  </td> -->
                  <td>
                    <p class="bigText">
                      {{ item.title }}
                    </p>
                  </td>
                  <td>
                    <p class="bigText">
                      <!-- {{ item.created_at | moment('ll') }} -->
                      {{ getFormattedTime(item.created_at) }}
                    </p>
                  </td>

                  <!-- <td>
                    <p class="bigText">
                      {{ item.description }}
                    </p>
                  </td> -->

                  <!-- <td>
                    <a href="javascript:;" @click="play_aud(item.path)"
                      class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                      <i class="mdi mdi-play"></i>
                    </a>
                  </td> -->

                  <td>
                    <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                  </td>

                  <td>
                    <toggle-button @change="changeSubscriptionStatus(item)" :value="(item.is_paid == 1) ? true : false" />
                  </td>

                  <td>


                    <button class="btn btn-info" @click="showShowModal(item.title, item.id)">
                      <i class="fa fa-eye"></i>
                    </button>
                    <button class="btn btn-warning"
                      @click="showEditModal(item.id, item.title, item.description, item.course_id, item.image)">
                      <i class="fa fa-pencil"></i>
                    </button>
                    <button class="btn btn-danger" @click="showAlert(item.id)">
                      <i class="fa fa-trash-o"></i>
                    </button>

                    <!-- <b-dropdown class="" right variant="info">
                        <template slot="button-content">
                          Actions
                        </template>

                            <a href="javascript:;" class="" style="text-decoration:none;">
                        <b-dropdown-item @click="
                        showShowModal(
                          item.id,
                        )">
                          <i class="fa fa-eye mr-2"></i>
                          Show

                        </b-dropdown-item>
                      </a>


                        <a href="javascript:;" class="" style="text-decoration:none;">
                          <b-dropdown-item @click="
                          showEditModal(
                            item.id, item.title, item.description, item.course_id, item.image
                          )">
                           <i class="fa fa-pencil mr-2"></i>
                            Update

                          </b-dropdown-item>
                        </a>
                        <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                          <b-dropdown-item>
                            <i class="fa fa-trash-o mr-2"></i>
                            Delete
                          </b-dropdown-item>
                        </a>
                      </b-dropdown> -->

                  </td>
                </tr>
              </draggable>
            </table>

            <!-- <div v-if="!searchButtonClicked && empty_data_error == ''" class="row">
              <div class="col-md-6 pageInfoBox">
                Total records : {{ total_data }}
                | Total pages : {{ items_total }}
                | Current page : {{ page }}
              </div>
              <div class="col-md-6 text-right">
                <button class="btn btn-success" :disabled="pagiBackButtonDisabled" @click="getData('previous')"><i
                    class="fa fa-arrow-left" aria-hidden="true"></i></button>
                <button class="btn btn-success" :disabled="pagiNextButtonDisabled" @click="getData('next')"><i
                    class="fa fa-arrow-right" aria-hidden="true"></i></button>
              </div>
            </div> -->



            <!-- <table v-else class="table table-hover table-striped table-responsive" id="mytable">
                <thead>
                  <tr class="frown" style="color:#303030 !important">
                    <th scope="col" style="width: 1%">#</th>
                    <th scope="col" style="width: 10%">Name</th>
                    <th scope="col" style="width: 1%">Created Date</th>
                    <th scope="col" style="width: 1%">Status</th>
                    <th scope="col" style="width: 1%">Actions</th>
                  </tr>
                </thead>
                <tbody style="color:#696969 !important;">
                  <tr v-for="(item, index) in items_custom" :key="index">
                    <td scope="row" class="sorting_1">{{ index + 1 }}</td>
                    <td>
                      <p class="bigText">
                        {{ item.title }}
                      </p>
                    </td>

                    <td>
                      <p class="bigText">
                        {{ item.created_at | moment('ll') }}
                      </p>
                    </td>

                    <td>
                      <toggle-button @change="changeStatus(item)" :value="(item.status == 'Active') ? true : false" />
                    </td>

                    <td>

                      <b-dropdown class="" right variant="info">
                        <template slot="button-content">
                          Actions
                        
                        </template>
                        <a href="javascript:;" class="" style="text-decoration:none;">
                          <b-dropdown-item @click="
                          showEditModal(
                            item.id, item.title, item.description, item.course_id, item.image
                           
                          )">
                         
                            Update Module

                          </b-dropdown-item>
                        </a>
                        <a href="javascript:;" @click="showAlert(item.id)" style="text-decoration:none;">
                          <b-dropdown-item>
                            
                            Delete Now
                          </b-dropdown-item>
                        </a>
                      </b-dropdown>

                    </td>
                  </tr>
                </tbody>
              </table> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import AudioCategoryEditModal from "../modals/audio-category-modals/AudioCategoryEditModal.vue";
import AudioCategoryAddModal from "../modals/audio-category-modals/AudioCategoryAddModal.vue";
import api from "../../config/api.js";
export default {
  name: "ListingContainer",
  components: {
    AudioCategoryEditModal,
    AudioCategoryAddModal,
    draggable
  },

  props: {
    contentType: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      draggableStatus : true,
      pageNumberCategory: "",
      pageNumberCourse: "",
      pageNumber: "",

      baseUrl: "", // search & pagination work
      searchedUrl: "", // search & pagination work
      searchButtonClicked: false, // search & pagination work
      keyword: "", // search & pagination work
      pagiBackButtonDisabled: true, // search & pagination work
      pagiNextButtonDisabled: false, // search & pagination work
      page: 0, // search & pagination work
      items_total: 0, // search & pagination work
      total_data: 0, // search & pagination work
      keywordEmptyAfterDelete: false, // search & pagination work
      items: [], // search & pagination work

      selected_module2: null,

      category_name: null,
      course_name: null,
      selected_course: null,
      selected_category: null,
      all_courses: [],
      all_categories: [],
      computed_count: 0,
      items_custom_status: false,
      empty_data_error: "",
      items_data: "",
      items2: [],
      items_custom: [],
      items_backup: [],
      data_table: "",
      course_id: null,
      category_id: null,
      url: null,
    }
  },

  // computed: {
  //   items: {
  //     get() {
  //       // console.log('get');
  //       this.computed_count++;
  //       // console.log(this.$store.getters[`${this.contentType.name}/list`]);
  //       if (this.computed_count > 1 && this.$store.getters[`${this.contentType.name}/list`].length == 0) {
  //         this.empty_data_error = "Data Not Found!"
  //       }
  //       else {
  //         this.setTable();
  //       }
  //       // return this.$store.getters[`${this.contentType.name}/list`];
  //       // this.items2 = this.$store.getters[`${this.contentType.name}/list`];
  //       // this.items_backup = this.items2;
  //       // this.setTable();
  //       return this.$store.getters[`${this.contentType.name}/list`];
  //     },
  //     set(value) {
  //       this.items2 = value;
  //       return value;
  //     }
  //   }
  // },

  // watch: {
  //   items(newVal) {
  //     this.setTable();
  //   },
  //   items_custom(newVal) {
  //     this.setTable2();
  //   }
  // },


  watch: {
    // search & pagination work
    keyword(after, before) {
      if (after != "") {
        if (this.items.length > 0) {
          this.empty_data_error = "";
        }
      } else if (after == "" && !this.keywordEmptyAfterDelete) {
        this.searchButtonClicked = false;
        this.getData();
      }
    },
    // search & pagination work
  },

  async created() {
    this.$root.$refs.Category = this;
    this.course_id = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
    console.log("course id from created  :::: ", this.course_id);
    // await this.$store.dispatch(`${this.contentType.name}/all`, {
    //   id: this.course_id
    // });
    if (this.$store.getters['getMessage']) {
      let obj = this.$store.getters['getMessage'];
      this.$swal('Success!', obj.msg, 'success')
      // this.showSnotifySuccess(obj.msg)
      this.$store.dispatch('setMessage', "");
    }
  },
  mounted() {
    // this.FetchAllCategories();
    // this.FetchAllCourses();
    this.fetchPrevData();
    this.items_data2();
    this.getData('next');
  },

  methods: {

    check_lesson(id) {
      this.selected_module2 = id;
    },

    async update2() {
      // console.log('update 2');

      // console.log("selected module conten :::::: ", this.selected_module2)
      if (this.selected_module2 != null) {
        this.items.map((dt, index) => {
          dt.order = index + 1;
        })
        // console.log(this.items);
        let ids = this.items.map((dt) => {
          return dt.id
        })
        const data = {
          "course_id": this.selected_module2,
          "ids": ids
        }
        // console.log("update 2 for sorting ::: ", data);
        try {
          let res = await api.post(`/admin/update-order-modules`, data);
          if (res.status == 200) {
            await this.$store.dispatch(`${this.contentType.name}/all`, {
              id: this.course_id
            });
          }
        } catch (error) {
          this.error = error.response.data.message;
          this.$toast.error(this.error);
        }
      }
      // console.log(this.selected_module2);
    },

    handleKeyPress(event) {
      if (event.key == "Enter" && this.keyword != "") {
        this.getResults()
      }
    },

    clearSearchInput() {
      this.keyword = "";
      this.keywordEmptyAfterDelete = false;
    },

    getResults() {
      if (this.keyword != "") {
        this.searchButtonClicked = true;
        api.get('/admin/livesearch-lessons', { params: { keyword: this.keyword, course_id: this.course_id } })
          .then(res => {
            console.log('get results data ::: ', res.data);
            this.items = res?.data
            if (this.items.length == 0) {
              this.empty_data_error = "Data Not Found!"
            } else {
              this.draggableStatus = false;
              this.empty_data_error = "";
            }
          })
          .catch(error => console.log("getResults : ", error));

        console.log(this.items);
      }
    },

    async getData($info = null) {
      console.log('chandio : ', this.$store.state.pageNumberAfterAction);
      this.searchButtonClicked = false;
      if (this.$store.state.pageNumberAfterAction != "") {
        console.log("store data :::: ", this.$store.state.pageNumberAfterAction);
        this.page = this.$store.state.pageNumberAfterAction;
        this.$store.state.pageNumberAfterAction = "";
      } else if ($info == "next") {
        this.page = ++this.page;
      } else if ($info == "previous") {
        this.page = --this.page;
      }

      console.log("page watch : ", this.page);
      console.log("page watch items lenght : ", this.items.length);
      console.log("page watch items lenght orignal: ", this.items_total);

      console.log("get Data page num : ", this.page);
      let result = await api.get(`/admin/course-lessons?course_id=${this.course_id}&page=${this.page}`);
      if (result.status == 200) {
        console.log("new audios data :::: ", result.data);
        this.items = result?.data?.lessons;
        this.items_total = result?.data?.total;
        this.total_data = result?.data?.total_data;
        if (this.items.length > 0) {
          this.empty_data_error = "";
          this.pagiBackButtonDisabled = (this.page > 1) ? false : true;
          this.pagiNextButtonDisabled = (this.page >= this.items_total) ? true : false;
          this.draggableStatus = true;
        }
        else {
          this.empty_data_error = "Data Not Found!"
        }

      }
    },


    async changeSubscriptionStatus(item) {
      item.is_paid = !item.is_paid
      try {
        const data = {
          "type": "Module",
          "id": item.id
        }
        let result = await api.post('/admin/toggle-subscription-status', data);
        if (result.status == 200) {
          // await this.$store.dispatch('module/all', {
          //   id: this.course_id
          // });
          this.$swal('Success!', result.data.message, 'success')
          if (this.keyword != "") {
            this.getResults()
          } else {
            this.getData();
          }
        }

      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    async items_data2() {
      let data = await this.$store.getters['getHeaderData'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_header');
        data = JSON.parse(data);
      }
      console.log("items data 2 ::::: ", data)
      this.pageNumberCategory = data?.pageNumberCategory,
        this.pageNumberCourse = data?.pageNumberCourse,
        this.course_name = data?.course_name
      this.category_name = data?.category_name
    },


    async showShowModal(name, id) {
      console.log("module id : ", id, " Course  id :::: ", this.course_id);
      await this.$store.dispatch('setDataInEditForm', {
        "pageNumber": this.page,
        "type": this.contentType.name, id,
        "category_name": name,
      })
      var obj = { "pageNumber": this.page, "type": this.contentType.name, id, "category_name": name, };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));


      await this.$store.dispatch('setHeaderData', {
        "pageNumberModule": this.page,
        "pageNumberCourse": this.pageNumberCourse,
        "pageNumberCategory": this.pageNumberCategory,
        "category_name": this.category_name, "course_name": this.course_name,
        "module_name": name
      })
      var obj = {
        "pageNumberModule": this.page,
        "pageNumberCourse": this.pageNumberCourse,
        "pageNumberCategory": this.pageNumberCategory,
        "category_name": this.category_name, "course_name": this.course_name,
        "module_name": name
      };
      localStorage.setItem('data_for_header', JSON.stringify(obj));


      let currentUrl = window.location.pathname;
      let url_obj = {
        "course_url": this.url,
        "module_url": currentUrl
      }
      await this.$store.dispatch('setDataInEditForm2', url_obj)
      localStorage.setItem('data_for_edit2', JSON.stringify(url_obj));


      // this.$refs['edit-modal'].show()
      const url = `/content/list/${id}`
      // console.log(url);
      this.$router.push({ path: url });
    },

    async fetchPrevData() {
      let data = await this.$store.getters['getEditFormData2'];
      if (data.length == 0) {
        data = localStorage.getItem('data_for_edit2');
        data = JSON.parse(data);
      }
      console.log("get edit form data 2 :::::: ", data)
      this.pageNumber = data?.pageNumber,
        // this.category_id = data?.prev_category_id;
        // this.course_id = data?.prev_course_id;
        this.url = data?.course_url;
    },

    goBack() {
      // let prev_url = `/course/list/${this.category_id}`;
      this.$store.state.pageNumberAfterAction = this.pageNumberCourse; // search &
      this.$router.push({ path: this.url });
      // this.$router.go(-1)
    },

    async changeStatus(item) {
      item.status = !item.status
      try {
        let result = await api.post(`/admin/lesson-status?id=${item.id}`);
        this.$swal('Success!', result.data.message, 'success')
        if (this.keyword != "") {
          this.getResults()
        } else {
          this.getData();
        }
        // await this.$store.dispatch(`module/all`);
        // await this.$store.dispatch(`module/all`, {
        //  id: this.course_id
        // });
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },

    // setTable() {
    //   setTimeout(() => {
    //     if (this.items.length > 0) {
    //       this.data_table = $("#audios_table").DataTable();
    //     }
    //   }, 2000);
    // },
    // setTable2() {
    //   setTimeout(() => {
    //     if (this.items_custom.length > 0) {
    //       this.data_table = $("#mytable").DataTable();
    //     }
    //   }, 0);
    // },


    async changeCategory() {
      try {
        if (this.selected_category == "all") {
          let result = await api.get(`/admin/all-courses`);
          this.all_courses = result.data.categories;
        } else {
          let result = await api.get(`/admin/course-cat-courses?course_cat_id=${this.selected_category}`);
          this.all_courses = result.data.courses;
        }
      } catch (error) {
        this.error = error.response.data.message;
        console.log(error);
      }
    },

    async changeCourse() {
      // try {
      //   if(this.selected_course == "all"){
      //     let result = await api.get(`/admin/all-courses`);
      //     this.all_courses = result.data.categories;
      //   }
      // } catch (error) {
      //   this.error = error.response.data.message;
      //   console.log(error);
      // }
    },




    async filter() {
      if (this.selected_course != null) {
        this.data_table.destroy();
        this.items_custom_status = false;
        // console.log(this.selected_course);
        try {
          if (this.selected_course == "all") {
            // this.items_custom = this.items_backup;
            // this.setTable();
            let result = await api.get(`/admin/all-lessons`);
            this.items_custom = result.data.all_lessons;
            if (result.status == 200) {
              this.setTable2();
            }
          } else {
            let result = await api.get(`/admin/course-lessons?course_id=${this.selected_course}`);
            this.items_custom = result.data.lessons;
            if (result.status == 200) {
              this.setTable2();
            }

            // if(result.data.lessons.length == 0){
            //   this.items_custom_status = true;
            //   this.empty_data_error = "Data Not Found!"
            //   this.data_table.destroy();
            // }
          }

          if (this.items_custom.length == 0) {
            this.items_custom_status = true;
            this.empty_data_error = "Data Not Found!"
          }
          this.items_custom_status2 = true;
          // this.data_table.destroy();
        } catch (error) {
          this.error = error.response.data.message;
          // this.$toast.error(this.error);
          console.log(error);
        }
      }
    },

    async FetchAllCategories() {
      try {
        let result = await api.get(`/admin/all-course-cat`);
        // console.log(result.data.all_course_categories);
        this.all_categories = result.data.all_course_categories;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },
    async FetchAllCourses() {
      try {
        let result = await api.get(`/admin/all-courses`);
        // console.log("fetch all courses");
        // console.log(result);
        // console.log(result.data.all_course_categories);
        this.all_courses = result.data.categories;
        // var obj = {"id" : "all","title" : "all"}
        // this.all_categories.unshift(obj);
      } catch (error) {
        this.error = error.response.data.message;
        this.$toast.error(this.error);
      }
    },


    async showEditModal(id, name, description, course_id, image) {
      await this.$store.dispatch('setDataInEditForm', {
        "pageNumber": this.page,
        "type": this.contentType.name,
        id, name, description, course_id, image,
        "category_id": this.category_id,
        "course_name": this.course_name
      })
      var obj = {
        "pageNumber": this.page,
        "type": this.contentType.name,
        id, name, description, course_id, image,
        "category_id": this.category_id,
        "course_name": this.course_name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));
      // this.$refs['edit-modal'].show()
      const url = `/${this.contentType.name}/${id}/edit`
      // console.log(url);
      this.$router.push({ path: url });
    },

    async showAddModal() {
      await this.$store.dispatch('setDataInEditForm', {
        "type": this.contentType.name,
        "course_id": this.selected_course,
        "course_id2": this.course_id,
        "category_id": this.category_id,
        "course_name": this.course_name
      })

      var obj = {
        "type": this.contentType.name,
        "course_id": this.selected_course,
        "course_id2": this.course_id,
        "category_id": this.category_id,
        "course_name": this.course_name
      };
      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      // this.$refs['add-modal'].show()
      const url = `/${this.contentType.name}/add`
      // console.log(url);
      this.$router.push({ path: url });
    },

    showSnotifySuccess(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.success(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },
    showSnotifyError(msg) {
      let obj = this.$store.getters['getMessage'];
      this.$snotify.error(obj.msg, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    },

    showAlert(id) {
      try {
        let endpoint2 = "/admin/delete-lesson/";
        const module_name = this.contentType.name;
        // if (module_name) {
        //   if (module_name == "hypnosis-categories") {
        //     endpoint2 = "/admin/delete-hypnosis-cat/";
        //   }
        //   else if (module_name == "meditation-categories") {
        //     endpoint2 = "/admin/delete-meditation-cat/";
        //   }
        //   else if (module_name == "affirmation-categories") {
        //     endpoint2 = "/admin/delete-affirmation-cat/";
        //   }
        // }
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then(async (res) => {
          if (res.value) {
            let result = await api.delete(endpoint2 + id);
            if (result.status == 200) {
              // this.data_table.destroy();
              // await this.$store.dispatch(`${this.contentType.name}/all`, {
              //   id: this.course_id
              // });
              this.getData();
              this.$swal(
                'Deleted!',
                'Module has been deleted.',
                'success'
              ).then(() => {
                this.keyword = "";
                this.keywordEmptyAfterDelete = true;
              })
            }
          }
        })
      } catch (error) {
        let err = error.response.data.message;
        this.$store.dispatch('setMessage', { err });
        this.showSnotifyError()
      }
    }
  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
}

tbody tr {
  cursor: pointer;
  transition: background-color 0.2s;
}

tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #efefef;
}

td,
i th {
  padding: 0.25em;
}

.effect-text-in-footer {
  margin-bottom: 30px;
  padding-bottom: 10px;
  text-align: center;
  box-shadow: -2px 8px 20px 2px #0000002b;
}

.portfolio-grid .effect-text-in img {
  z-index: 100;
  opacity: 1;
  height: 50px;
  object-fit: cover;
}

.portfolio-grid figure {
  background: #fff !important;
}

.portfolio-grid .btn-outline-secondary:hover,
.portfolio-grid .btn-outline-secondary:focus,
.portfolio-grid .btn-outline-secondary:active {
  border-color: #3bccb0 !important;
  background: #3bccb0 !important;
  color: #ffffff;
}

.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary,
.portfolio-grid .btn-outline-secondary {
  border-color: #3bccb0;
  color: #3bccb0;
}
</style>
