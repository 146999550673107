import layout from '../layout'
export default function makeTagRoutes({
  contentType,
  components: { TagListingContainer }
}) {

  return [
    {
      path: '/',
      component: layout,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: `/${contentType.name}/list/`,
          component: TagListingContainer,
          props: {
            contentType,
          }
        },
      ]
    },
  ];
}
